import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Classes from '../asset/styles/ConnectWalletModal.module.css'
import { IoMdClose } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Images } from '../data/Images';
import Usewallet from "../hooks/UseWallet";
import { notify } from '../hooks/useToast';
import { getWalletAddress } from '../hooks/useStorage';





const ConnectWalletModal = (props) => {
    const [accept,setAccept] = useState(false)
    const [add, setAdd] = useState()
    const [wallet, setWallet] = useState()
    const [waltype, setWaltype] = useState("")
    const [bal, setBal] = useState()
    const [token, setToken] = useState()
    const [tokendecimal, setTokendecimal] = useState()
    const dispatch = useDispatch()
    const walletHook = Usewallet()


    const phantomwallet = async (data) => {
      let resp = await walletHook.Walletconnect(data,dispatch)
      console.log("resp",resp)
      notify(true, `${data} connected Successfully`)
    }

    

  return (
    <>
      <Modal
      size='md'
      centered
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal connectWalletModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={props.handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Connect to a wallet</h5>

          <p className={`${Classes.connectWalletHint} m-0 mt-5`}>Accept <NavLink to='' className={`${Classes.modalNavLink} text-decoration-none`}>Terms of Services</NavLink> and <NavLink to='' className={`${Classes.modalNavLink} text-decoration-none`}>Privacy Policy</NavLink></p>

          <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mt-3 pointer`} onClick={() => setAccept(!accept)}>
              <i className={`fa-solid fa-square ${accept ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint} poppins`}>I read and accept.</p>
              </button>

              <p className={`${Classes.connectWalletHint} m-0 mt-4`}>Choose Wallet</p>

              <div className={`${Classes.walletHolder} d-flex justify-content-between align-items-center gap-3 mt-4`}>
                <button className={`${Classes.singleWalletBtn} flex-grow-1 `} disabled={!accept} onClick={() => {
                    phantomwallet("Phantom");props.handleClose();}}>
                    <img src={Images.solana} className={`${Classes.walletImg}`} alt='' />
                    <p className={`${Classes.walletName} m-0 poppins`}>Phantom</p>
                </button>
                <button className={`${Classes.singleWalletBtn} flex-grow-1`} disabled={!accept} onClick={() => {props.handleClose();setWallet(!wallet)}}>
                    <img src={Images.walletConnect} className={`${Classes.walletImg}`} alt='' />
                    <p className={`${Classes.walletName} m-0 poppins`}>Wallet Connect</p>
                </button>
              </div>

         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConnectWalletModal
