import { AUTH_TOKEN } from "../actions/types";
const initialState = {
    authToken:''
};

export default function(state = initialState, action) {
    switch (action.type) {
        case AUTH_TOKEN:
             return{...state,authToken :action.payload};
        default:
            return state;
    }
}