import axios, { setAuthorization } from "../config/axios";

export const getAllCMSData = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/get-cms`,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const getSettingsData = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/getSiteSetting`,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};
