import { PublicKey, Connection, clusterApiUrl, Transaction, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
// import { createMint, createMintToInstruction, getAccount, TOKEN_PROGRAM_ID, getMint, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { setAuthority, getAccount, TOKEN_PROGRAM_ID, getMint, ASSOCIATED_TOKEN_PROGRAM_ID, MINT_SIZE, createInitializeMintInstruction, getMinimumBalanceForRentExemptMint, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createMintToInstruction } from '@solana/spl-token';

import { TokenListProvider, TokenInfo } from '@solana/spl-token-registry';
import Config from '../config';
import axios from 'axios';
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import Usesale from './useSale';
// import { Metadata } from '@metaplex/js';
import * as Metadata from '@metaplex-foundation/mpl-token-metadata';
import * as anchor from '@project-serum/anchor';
import { useDispatch, useSelector } from 'react-redux';
import { Program, AnchorProvider, web3, BN } from "@project-serum/anchor";
import Usewallet from './UseWallet';
import tokenAbi from '../config/abi/tokenMint.json'
import { EncryptData } from '../lib/Encrypt';
import { createCreateMetadataAccountV3Instruction, createMetadataAccountV3, PROGRAM_ID } from '@metaplex-foundation/mpl-token-metadata';
import { programs } from '@metaplex/js';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters'
import { generateSigner, percentAmount, createGenericFile } from '@metaplex-foundation/umi'
import { createFungible, mintV1, TokenStandard, mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata'
import { irysUploader } from '@metaplex-foundation/umi-uploader-irys'
import {
    createTokenIfMissing,
    findAssociatedTokenPda,
    getSplAssociatedTokenProgramId,
    mintTokensTo,
  } from '@metaplex-foundation/mpl-toolbox'


// const { metadata: { Metadata } } = programs;

// import { programs } from '@metaplex-foundation/mpl-token-metadata';

// const { Metadata } = programs.metadata;

const { SystemProgram } = anchor.web3;


export default function Usetoken() {

  const saleHook = Usesale()
  const walletHook = Usewallet()

  // const Connection = useConnection()

  let network = Config.NETWORK
  const wallet = useWallet()
  const { publicKey, sendTransaction } = useWallet()
  console.log("useToken_accountInfo", wallet)

  const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_UseToken", walletdetail)

  let provider = walletHook.UseProvider()


  const isValidTokenAddress = async (tokenAddress) => {
    try {
      const publicKey = new PublicKey(tokenAddress);

      // Check if the address is a valid public key
      if (!PublicKey.isOnCurve(publicKey)) {
        return false;
      }

      // Try to fetch the token account to see if it exists
      const tokenAccount = await getAccount(network, publicKey, TOKEN_PROGRAM_ID);
      if (tokenAccount) {
        return true;
      }
    } catch (error) {
      console.error('Invalid Token Address:', error);
      return false;
    }
    return false;
  }

  const getTokenbalance = async (walletAddress, tokenAddress) => {
    console.log("getTokenbalance", walletAddress, tokenAddress)
    let tokenBalance = 0;
    let Decimal = 0;
    let owner = ""
    const response = await axios({
      url: Config.NETWORK_URL, // devnet URL or mainnet URL
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          walletAddress, // account addrss
          {
            mint: tokenAddress,
            // programId: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",  // token mint address
          },
          {
            encoding: "jsonParsed",
          },
        ],
      },
    });
    let TokenData
    if (response?.data?.result?.value?.length > 0) {
      TokenData = response?.data?.result?.value.find((val) => (
        // console.log(val.account?.data?.parsed?.info?.mint, 'TokenData', tokenAddress)
        val.account?.data?.parsed?.info?.mint == tokenAddress
      ))
    }

    console.log(TokenData, 'TokenData')
    console.log("🚀getTokenbalance__response:",TokenData?.account?.data?.parsed?.info?.tokenAmount?.uiAmount, response, "bal:",
      response?.data?.result?.value[0]?.account?.data?.parsed?.info?.tokenAmount
        ?.uiAmount)
    if (

      Array.isArray(response?.data?.result?.value) &&
      response?.data?.result?.value?.length > 0
    ) {
      tokenBalance = (Number(
        TokenData?.account?.data?.parsed?.info?.tokenAmount?.uiAmount))

      Decimal = TokenData?.account?.data?.parsed?.info?.tokenAmount?.decimals
      owner = TokenData?.account?.data?.parsed?.info?.owner
    }
    return { tokenBalance, Decimal, owner };
  }

  // Function to derive metadata PDA (program-derived address)
  const getMetadataAddress = async (mintAddress) => {
    console.log(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID, new PublicKey(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID), 'getMetadataAddress')
    const mintPubKey = new PublicKey(mintAddress);
    const [metadataAddress] = await PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        new PublicKey(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID).toBuffer(),
        mintPubKey.toBuffer(),
      ],
      new PublicKey(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID)
    );
    return metadataAddress;
  }

  function uintToString(uintArray) {
    var encodedString = String.fromCharCode.apply(null, uintArray)
    // decodedString = decodeURIComponent(escape(encodedString));
    console.log(encodedString, 'encodedString')
    // return decodedString;
  }

  // const fetchTokenMetadata = async (connection, mintAddress) => {

  //   const metadataAddress = await getMetadataAddress(mintAddress);
  //   const accountInfo = await connection.getAccountInfo(metadataAddress);
  //   if (!accountInfo) {
  //     throw new Error('Metadata account not found');
  //   }
  //   const data = accountInfo.data
  //   // const data = Metadata.deserialize(accountInfo.data);
  //   console.log("data", data)
  //   let getBalaDeci = await getTokenbalance(publicKey, mintAddress)
  //   console.log("getBalaDeci", getBalaDeci,getBalaDeci.Decimal)

  //   const buffer = Buffer.from(data, 'utf-8');

  //   // const uintArray = uintToString(data)
  //   // console.log("uintArray", uintArray)

  //   // const decoder = new TextDecoder();
  //   // const str = decoder.decode(data);
  //   // console.log("str", str)
  //   // const nameLength = buffer.readUInt8(0); // Length of name
  //   // console.log("nameLength", nameLength, buffer)

  //   /*  method */
  //   const decoder = new TextDecoder('utf-8');
  //   const decodedString = decoder.decode(buffer);
  //   console.log("decodedString", decodedString); // "Hello"
  //   console.log("decodedStringdecodedStringdecodedString", decodedString.replace(/[\x00-\x1F\x7F]/g, ''))

  //   const urlRegex = /https:\/\/[^\s]+/;

  //   // Find the URL using the regular expression
  //   const match = decodedString.match(urlRegex);
  //   let resp

  //   if (match) {
  //     const url = match[0];
  //     let urls = url.replace(/\0/g, '').replace(/�/g, '').replace(/[\x00-\x1F\x7F]/g, '')
  //     console.log("url", urls);
  //     let response = await fetch(urls);
  //     resp = await response.json();
  //     console.log("fetch_res",  resp)
  //     // fetch(urls).then(res => { resp = res.json(); console.log("fetch_res", resp) })
  //     // resp = await fetch({
  //     //   url: urls,
  //     //   method: 'GET'
  //     // })
  //     console.log("respresp", resp)
  //   } else {
  //     console.log("No URL found");
  //   }



  //   let name = resp.name;
  //   let symbol = resp.symbol;
  //   let image = resp.image;
  //   let description = resp.description;
  //   let totalSupply = getBalaDeci.tokenBalance;
  //   let decimal = getBalaDeci.Decimal
  //   let owner = getBalaDeci.owner


  //   // console.log("const cleanedString = decodedString.replace(/�/g, '');", decodedString.replace(/�/g, ''))
  //   // const namess = buffer.toString('utf8', 1, 1 + nameLength).trim();
  //   // const symbolLength = buffer.readUInt8(1 + nameLength); // Length of symbol
  //   // const symbolssss = buffer.toString('utf8', 2 + nameLength, 2 + nameLength + symbolLength).trim();
  //   // console.log("symbolssss", symbolssss)

  //   // const name = data.slice(65, 95).toString('utf-8').replace(/\0/g, '').trim();   // Name is usually stored at bytes 33 to 65
  //   // console.log("name", name)

  //   // const symbol = data.slice(105, 120).toString('utf-8').replace(/\0/g, '').replace(/�/g, '').trim();  // Symbol is usually stored at bytes 65 to 75
  //   // console.log("symbol", symbol, symbol.split(/�/)[0])

  //   // const uriStart = 2 + nameLength + symbolLength;
  //   // const uri = buffer.toString('utf8', uriStart).trim();
  //   // console.log("uri", uri)




  //   // Decode the metadata (the exact structure depends on how the metadata is stored)
  //   // const nameLength = buffer.readUInt8(0); // Length of name
  //   // console.log("nameLength", nameLength)

  //   // const name = buffer.toString('utf8', 1, 1 + nameLength).trim();
  //   // console.log("name", name)
  //   // const symbolLength = buffer.readUInt8(1 + nameLength); // Length of symbol
  //   // const symbol =    .toString('utf8', 2 + nameLength, 2 + nameLength + symbolLength).trim();
  //   // console.log("symbol", symbol)
  //   // const uriStart = 2 + nameLength + symbolLength;
  //   // const uri = buffer.toString('utf8', uriStart).trim();
  //   // console.log("uri", uri)

  //   return { name, symbol, image, description, totalSupply, decimal, owner };

  // }


  // const getTokenMetadataURI = async (connection,mintAddress) => {
  //   try {
  //     // const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
  //     const mintPubKey = new PublicKey(mintAddress);

  //     const metadataPDA = await Metadata.getPDA(mintPubKey);

  //     const metadataAccount = await Metadata.load(connection, metadataPDA);

  //     const tokenUri = metadataAccount.data.data.uri;

  //     console.log('Token URI:', tokenUri);
  //     return tokenUri;
  //   } catch (error) {
  //     console.error('Error fetching metadata:', error);
  //   }
  // };


  const fetchTokenMetadata = async (connection, mintAddress) => {

    // const metadataAddress = await getMetadataAddress(mintAddress);
    // console.log("metadataAddress",metadataAddress)
    // const accountInfo = await connection.getAccountInfo(metadataAddress);
    // if (!accountInfo) {
    //   throw new Error('Metadata account not found');
    // }
    // const data = accountInfo.data
    // const data = Metadata.deserialize(accountInfo.data);
    // console.log("data", data)
    let getBalaDeci = await getTokenbalance(publicKey, mintAddress)
    console.log("getBalaDeci", getBalaDeci, getBalaDeci.Decimal)


    let match = await saleHook?.getTokenMetadataURI(connection, mintAddress)
    console.log("match", match)
    let resp

    if (match) {
      const url = match;
      console.log("url", url);
      let response = await fetch(url);
      resp = await response.json();
      console.log("fetch_res", resp)
      console.log("respresp", resp)
    } else {
      console.log("No URL found");
    }



    let name = resp.name;
    let symbol = resp.symbol;
    let image = resp.image;
    let description = resp.description;
    let totalSupply = getBalaDeci.tokenBalance;
    let decimal = getBalaDeci.Decimal
    let owner = getBalaDeci.owner


    return { name, symbol, image, description, totalSupply, decimal, owner };

  }



  // Function to fetch off-chain metadata (from URI)
  const fetchOffChainMetadata = async (uri) => {
    const response = await fetch(uri);
    return await response.json();
  }

  const getTokenNameSymbol = async (mintAddress) => {
    try {
      // Fetch on-chain metadata
      // const connection = new Connection(Config.NETWORK_URL);
      const connection = new Connection(clusterApiUrl(Config.NETWORK), 'confirmed');

      const metadata = await fetchTokenMetadata(connection, mintAddress);
      console.log('On-chain Metadata:', metadata, metadata.name);

      // Fetch off-chain metadata from URI
      // const offChainMetadata = await fetchOffChainMetadata(metadata.data.uri);
      // console.log('Off-chain Metadata:', offChainMetadata);

      return {
        name: metadata.name,
        symbol: metadata.symbol,
        image: metadata.image,
        description: metadata.description,
        tokenBalance: metadata.totalSupply,
        decimal: metadata.decimal
        // ...offChainMetadata,
      };
    } catch (error) {
      console.error('Error fetching token metadata:', error);
    }
  }

  //   const UseTokenMint = async (accountInfo) => {

  //     //Create the spl tokens to the new account which we created recently

  //     try {

  //         const connection = new Connection(clusterApiUrl(Config.NETWORK), 'confirmed');
  //         console.log(connection, 'connection')
  //         let keyPair = new Keypair()
  //         console.log(`Initiating the Creation of SPLTOKEN to the new wallet`,wallet);

  //         //Step 1 - Creating the mint account for the new wallet address
  //         let mintA = await createMint(
  //             connection,
  //             wallet,
  //             publicKey,
  //             null,
  //             9 //If you are creating the NFT then keep decimal 0 or creating new Custom SPL token like USDC then keep more than 0 like 6 or 9 (other tokens followed)

  //         );
  //         console.log("Creating the mint account for the new wallet address", mintA.toString());


  //         //Step 2 - Get or Create the ATA (AssociatedTokenAccount) for new created wallet
  //         let myToken_acctA = await saleHook.createATA(publicKey, mintA, accountInfo)
  //         console.log("Get or Create the ATA (AssociatedTokenAccount) for new created wallet", myToken_acctA);


  //         //Step 3 - Minting the token for that new wallet
  //         const value = new anchor.BN(10000 * 10 ** 9)
  //         const transaction = new Transaction().add(
  //             createMintToInstruction(
  //                 new PublicKey(mintA),
  //                 new PublicKey(myToken_acctA),
  //                 publicKey,
  //                 value, //Number of tokens need to mint
  //                 [publicKey],
  //                 new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
  //             )
  //         );
  //         console.log('transaction-->', transaction)

  //         transaction.feePayer = publicKey;
  //         const { blockhash } = await connection.getRecentBlockhash();
  //         transaction.recentBlockhash = blockhash;
  //         console.log('blockhash-->', blockhash)
  //         const signed = await accountInfo.signTransaction(transaction);
  //         console.log('signed-->', signed)
  //         const signature = await connection.sendRawTransaction(signed.serialize());
  //         console.log('signature-->', signature)
  //         let signhash = await connection.confirmTransaction(signature);
  //         console.log('signhash-->', signhash)
  //         console.log(`Transferring SPL TOKEN to new wallet please wait....`);

  //     } catch (err) {
  //         console.log(err, "err");
  //     }
  // }


  const UseTokenMint = async () => {

    const connection = new Connection(clusterApiUrl(network), "confirmed");

    const tokenData = {
      name: 'TTTTT',
      symbol: 'TTTTT',
      uri: 'https://imagekit.io/blog/content/images/2020/06/Twitter-1024_512.png',
      decimals: 9,
      description: '13456'
    };


    let Uri = await generateTokenUri(tokenData)
    console.log("generateUri", Uri)
    const mintAmount = 1000;

    const params = {
      name: 'TTTTT',
      symbol: 'TTTTT',
      uri: `https://azure-worthwhile-scorpion-613.mypinata.cloud/ipfs/${Uri}`,
      decimals: 9,
      qty: new BN(mintAmount * 10 ** 9)
    };



    const program = new Program(tokenAbi, Config.SPLTOKEN_PROGRAM_ID, provider);
    console.log("publicKey", publicKey)
    // const params = {
    //   name: tokenData.name,
    //   symbol: tokenData.symbol,
    //   uri: `https://azure-worthwhile-scorpion-613.mypinata.cloud/ipfs/${Uri}`,
    //   decimals: tokenData.decimals,
    // };

    // const mintAccount = Keypair.generate();
    // console.log("🚀 ~ constsol_git_mintfuct= ~ mintAccount:", mintAccount);

    const MINT_SEED = "mint";

    const nameBytes = Buffer.from(params.name, 'utf-8');
    console.log("nameBytes", nameBytes, publicKey?.toBuffer())

    const tokenMetadataProgram = new PublicKey(
      "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
    );


    const [mintAccount, bump] = await PublicKey.findProgramAddressSync(
      [
        Buffer.from("mint"),
        nameBytes,
      ],
      new PublicKey(Config.SPLTOKEN_PROGRAM_ID)
    )


    const metadataAccount = await PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        tokenMetadataProgram.toBuffer(),
        mintAccount.toBuffer(),
      ],
      tokenMetadataProgram
    )[0]



    console.log("mint", mintAccount, mintAccount.toString())
    console.log("metadataAccount", metadataAccount, metadataAccount.toString())
    console.log("TOKEN_PROGRAM_ID", TOKEN_PROGRAM_ID)
    console.log("Metadata", tokenMetadataProgram, Metadata.MPL_TOKEN_METADATA_PROGRAM_ID)
    console.log("ASSOCIATED_TOKEN_PROGRAM_ID", ASSOCIATED_TOKEN_PROGRAM_ID)
    console.log("SystemProgram.programId", SystemProgram.programId)
    const destination = await saleHook.createATA(publicKey, mintAccount, publicKey)
    console.log("destination", destination);

    let transaction = await program.methods.initToken(params)
      .accounts({
        metadata: metadataAccount,
        mint: mintAccount,
        payer: publicKey,
        destination: destination,
        rent: web3.SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenProgram: new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"), //TOKEN_PROGRAM_ID,
        associatedTokenProgram: new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"), //ASSOCIATED_TOKEN_PROGRAM_ID, 
        tokenMetadataProgram: new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")   //Metadata.MPL_TOKEN_METADATA_PROGRAM_ID, //tokenMetadataProgram
      })
      // .signers([mintAccount])
      .transaction()  // Add the userAccount as a signer

    transaction.feePayer = publicKey;
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.sign(mintAccount);
    console.log("blockhash-->", blockhash, transaction, provider);
    const signed = await provider.wallet.signTransaction(transaction);
    console.log("signed-->", signed, connection);
    const signature = await connection.sendRawTransaction(signed.serialize());
    console.log("signature-->", signature);
    if (signature) {
      console.log(
        "TransactionHash",
        `https://explorer.solana.com/tx/${signature}?cluster=devnet`
      );
    }
    let signhash = await connection.confirmTransaction(signature);
    console.log("signhash-->", signhash);
    let status = await getTransactionstatus(signature);
    console.log("transaction", transaction)



    // await provider.sendAndConfirm(transaction, [mintAccount]);

    // transaction.sign(mint)
    // const { blockhash } = await connection.getLatestBlockhash();
    // transaction.recentBlockhash = blockhash;

    // let InitializeToken = await program?.rpc?.initToken(
    //   params,
    //   {
    //     accounts: {
    //       metadata: metadataAddress,
    //       mint: mint,
    //       payer: publicKey,
    //       rent: web3.SYSVAR_RENT_PUBKEY,
    //       systemProgram: SystemProgram.programId,
    //       tokenProgram: TOKEN_PROGRAM_ID,
    //       tokenMetadataProgram: Metadata.MPL_TOKEN_METADATA_PROGRAM_ID,
    //     },
    //     signers: [mint],
    //   });
    // // InitializeToken.sign(mint)
    // console.log("InitializeToken", InitializeToken)

    // console.log("destination", destination);

    // let mintToken = await program?.rpc?.mintTokens(
    //   new BN(mintAmount * 10 ** params.decimals),
    //   {
    //     accounts: {
    //       mint: mintAccount.publicKey.toString(),
    //       destination: destination,
    //       payer: publicKey.toString(),
    //       rent: web3.SYSVAR_RENT_PUBKEY,
    //       systemProgram: SystemProgram.programId,
    //       tokenProgram: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA" , //TOKEN_PROGRAM_ID,
    //       associatedTokenProgram:"ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL" , //ASSOCIATED_TOKEN_PROGRAM_ID,
    //     },
    //   });
    // console.log("mintToken", mintToken)
  }


  const UseTokenMint_new = async(tokenData) => {
    console.log("tokenData : ",tokenData)
    const umi = createUmi(Config.NETWORK_URL)
    umi.use(walletAdapterIdentity(wallet))
    umi.use(mplTokenMetadata())
    .use(irysUploader())

    
    const mintSigner = generateSigner(umi);
    
    const createFungibleIx = createFungible(umi, {
        mint: mintSigner,
        name: tokenData.name,
        symbol: tokenData.symbol,
        uri: tokenData.uri, // we use the `metedataUri` variable we created earlier that is storing our uri.
        sellerFeeBasisPoints: percentAmount(0),
        decimals: tokenData.decimal, // set the amount of decimals you want your token to have.
    });

    const createTokenIx = createTokenIfMissing(umi, {
        mint: mintSigner.publicKey,
        owner: umi.identity.publicKey,
        ataProgram: getSplAssociatedTokenProgramId(umi),
    });

    const mintTokensIx = mintTokensTo(umi, {
        mint: mintSigner.publicKey,
        token: findAssociatedTokenPda(umi, {
          mint: mintSigner.publicKey,
          owner: umi.identity.publicKey,
        }),
        amount: (tokenData.totalSupply),
      });

    console.log("Sending transaction")
    const tx = await createFungibleIx
        .add(createTokenIx)
        .add(mintTokensIx)
        .sendAndConfirm(umi);
        console.log("createToken_tx",tx)

        if (tx) {
          return {
              status: true,
              message:'Token created successfully',    
              mintAddress : mintSigner.publicKey       
          }
      }
      else {
          return {
              status: false

          }
      }
    
  }


  const getTransactionstatus = async (tx) => {
    const connection = new Connection(clusterApiUrl(network), "confirmed");
    const result = await connection.getSignatureStatus(tx, {
      searchTransactionHistory: true,
    });
    console.log("🚀 ~ getTransactionstatus ~ result:", result);
    return result?.value?.confirmationStatus === "confirmed";
  };

  const generateTokenUri = async (tokenData) => {

    let tokenDetails = {
      "name": tokenData.name,
      "symbol": tokenData.symbol,
      "description": tokenData.description,
      "image": tokenData.image
    }


    try {
      /* Setup Credentials*/
      const token = Config.IPFS_JWT_TOKEN
      const pinataEndpoint = Config.PINATA_ENDPOINT;

      /* Setup payload*/
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      /* Axios call for store the  shares*/
      let test = await axios.post(pinataEndpoint, tokenDetails, { headers })
        .then(async (response) => {

          var ipfsDATA = response.data.IpfsHash
          console.log("ipfsDATA", ipfsDATA);

          return ipfsDATA;

        })
        .catch(error => {

          console.error('Error:', error.response.data);
          return undefined;
        });

      return test
    } catch (e) {
      console.log("NewFunc err", e);
      return undefined;
    }

  }

  const tokenCreate = async (form) => {
    const lamports = LAMPORTS_PER_SOL
    const mintKeypair = Keypair.generate();
    console.log("mintKeypair", mintKeypair, mintKeypair.publicKey, publicKey, Metadata.MPL_TOKEN_METADATA_PROGRAM_ID)
    const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, publicKey);

    const createMetadataInstruction = createMetadataAccountV3(
      {
        metadata: PublicKey.findProgramAddressSync(
          [
            Buffer.from("metadata"),
            new PublicKey(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID).toBuffer(),
            (mintKeypair.publicKey).toBuffer(),
          ],
          new PublicKey(Metadata.MPL_TOKEN_METADATA_PROGRAM_ID),
        )[0],
        mint: mintKeypair.publicKey,
        mintAuthority: publicKey,
        payer: publicKey,
        updateAuthority: publicKey,
      },
      {
        createMetadataAccountArgsV3: {
          data: {
            name: "SIRI",
            symbol: "SIRI",
            uri: " https://azure-worthwhile-scorpion-613.mypinata.cloud/ipfs/QmSdKDwc9D6bzAHGsxRbmDfHmVTnQqxQgVdgDcugsr2pAB",
            creators: null,
            sellerFeeBasisPoints: 0,
            uses: null,
            collection: null,
          },
          isMutable: false,
          collectionDetails: null,
        },
      },
    );

    const createNewTokenTransaction = new Transaction().add(
      SystemProgram.createAccount({
        fromPubkey: publicKey,
        newAccountPubkey: mintKeypair.publicKey,
        space: MINT_SIZE,
        lamports: lamports,
        programId: TOKEN_PROGRAM_ID,
      }),
      createInitializeMintInstruction(
        mintKeypair.publicKey,
        9,
        publicKey,
        publicKey,
        TOKEN_PROGRAM_ID),
      createAssociatedTokenAccountInstruction(
        publicKey,
        tokenATA,
        publicKey,
        mintKeypair.publicKey,
      ),
      createMintToInstruction(
        mintKeypair.publicKey,
        tokenATA,
        publicKey,
        1000 * Math.pow(10, 9),
      ),
      createMetadataInstruction
    );
    await sendTransaction(createNewTokenTransaction, network, { signers: [mintKeypair] });
  };





  return {
    isValidTokenAddress,
    getTokenbalance,
    getTokenNameSymbol,
    UseTokenMint,
    generateTokenUri,
    tokenCreate,
    UseTokenMint_new
  }
}