import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import Classes from '../../asset/styles/tokenCreation.module.css'
import { Images } from '../../data/Images'
import BalanceDetails from '../../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select';
import { validstandardtoken, validliquiditytoken } from '../../validations/userValidations'
import { useDispatch, useSelector } from 'react-redux'
import TokenCreate from './tokenmodal'

const TokenCreation = () => {
  const options = [
    { value: 'Standard Token', label: 'Standard Token' },
    { value: 'Liquidity Generator Token', label: 'Liquidity Generator Token' },
  ];
  const [selectedToken, setSelectedToken] = useState(null)
  const [tokentype, setTokentype] = useState("Standard Token")
  const [name, setName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [decimal, setDecimal] = useState("")
  const [totalsupply, setTotalsupply] = useState("")
  const [yieldfee, setYieldfee] = useState("")
  const [liquidityfee, setLiquidityfee] = useState("")
  const [maximumtransaction, setMaximumtransaction] = useState("")
  const [exchangethresold, setExchangethresold] = useState("")
  const [errors, setErrors] = useState({})



  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_TokenCreation", walletdetail)


  useEffect(() => {
    window.scrollTo(0, 0)
    console.log("tokentype", tokentype)
  }, [])

  
  return (
    <>
      <Layout>
        <section className="custom_section position-relative">
          <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
          <BalanceDetails />
          <Row className={`mx-auto ${Classes.createToken__emptyRow}`}>
            <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
            <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
             <TokenCreate props="page"/>
             </div>
            </Col>
          </Row>

        </section>
      </Layout>

    </>
  )
}

export default TokenCreation
