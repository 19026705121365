import * as anchor from '@project-serum/anchor';
import lockIdl from '../config/abi/tokenLock.json'
import { Program, AnchorProvider, web3, BN } from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { useDispatch, useSelector } from 'react-redux';

import {
    Connection,
    LAMPORTS_PER_SOL,
    Transaction,
    clusterApiUrl,
    PublicKey,
    Keypair,
    TransactionInstruction,
} from "@solana/web3.js";

import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";

import Config from '../config';
import Usewallet from './UseWallet';
import Usesale from './useSale';
import { userTokenLock } from '../actions/userAction';

const { SystemProgram } = anchor.web3;

export default function Uselock() {
    const walletdetail = useSelector((state) => state.wallet)
    const network = Config.NETWORK;
    const walletHook = Usewallet()
    const saleHook = Usesale()

    const { wallet, publicKey } = useWallet()
    // console.log("useSale_accountInfo", network, wallet, publicKey, wallet?.adapter?.name)

    let provider = walletHook.UseProvider()


    const UseTokenLockPerUser = async (program, token, user) => {
        // here we need to use database to store presale addresses
        const [presaleMachine, _] = await web3.PublicKey.findProgramAddress([Buffer.from("locking-machine"), user.toBuffer(), token.toBuffer()], program.programId);
        return presaleMachine;
    }

    const UseLockInfo = async (lockAddress) => {
        try {
            const program = new Program(lockIdl, Config.LOCKTOKEN_PROGRAM_ID, provider);
            const lockingMachine = new PublicKey(lockAddress);
            const lockInfo = await program.account.lockingMachine.fetch(lockingMachine);
            console.log("UseLockInfo", lockInfo)
            return lockInfo;
        }
        catch (err) {
            console.log("UseLockInfo_err", err)
        }
    }

    const UseTotalLockInfo = async () => {
        try {
            const program = new Program(lockIdl, Config.LOCKTOKEN_PROGRAM_ID, provider);
            const lockInfo = await program.account.lockingMachine.all();
            console.log("UseTotalLockInfo", lockInfo)
            return lockInfo.reverse();
        }
        catch (err) {
            console.log("UseTotalLockInfo_err", err)
        }
    }


    const UseLockToken = async (lockInfo) => {

        console.log("infooooooooo",lockInfo, lockInfo?.lockamount, lockInfo?.lockDuration, lockInfo?.tokenAddress, lockInfo?.receiverAddress,lockInfo?.isVested);

        try {
            const program = new Program(lockIdl, Config.LOCKTOKEN_PROGRAM_ID, provider);
            console.log("UseLockToken_program", program)
            let amount = new BN(`${lockInfo?.lockamount}`);
            let duration = new BN(`${lockInfo?.lockDuration}`);
            const tokenMint = new PublicKey(lockInfo?.tokenAddress)
            const reciever = new PublicKey(lockInfo?.receiverAddress)
            let cycle = new BN(`${lockInfo?.cycle}`);      
            let cyclepercent = new BN(`${lockInfo?.cyclepercent}`);      

            console.log("INPUTS : ",Config.LOCKTOKEN_PROGRAM_ID)

            const lockingMachine = await UseTokenLockPerUser(program, tokenMint, publicKey)
            console.log("lockingMachine", lockingMachine, lockingMachine.toBase58())

            const authority_ata = await saleHook.UseTokenAccountPerAddress(provider.wallet.publicKey, tokenMint, provider.wallet.publicKey);
            console.log("authority_ata", authority_ata)

            const lockingMachine_ata = await saleHook.UseTokenAccountPerAddress(lockingMachine, tokenMint, provider.wallet.publicKey);
            console.log("lockingMachine_ata", lockingMachine_ata)

            const reciever_ata = await saleHook.UseTokenAccountPerAddress(reciever, tokenMint, provider.wallet.publicKey);
            console.log("reciever_ata", reciever_ata)

            let result = await program?.rpc?.lockTokens(
                amount,
                duration,
                cycle,
                cyclepercent,
                lockInfo?.isVested,
                {
                    accounts: {
                        lockingMachine: lockingMachine,
                        authoritySplAta: authority_ata,
                        lockingMachineSplAta: lockingMachine_ata,
                        recieverSplAta: reciever_ata,
                        authority: provider.wallet.publicKey,
                        splMint: tokenMint,
                        reciever: reciever,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                        systemProgram: SystemProgram.programId,

                    },
                });
            console.log("UseLockToken_result", result)

            let data = {
                userAddress: publicKey.toString(),
                lockAddress: lockingMachine.toBase58(),
                tokenAddress: lockInfo?.tokenAddress,
                logo: lockInfo?.logo,
                name : lockInfo?.name,
                symbol : lockInfo?.symbol,
                decimal: lockInfo?.decimal,
            }
            let lockData = await userTokenLock(data)
            console.log("lockData", lockData)

            if (result) {
                return {
                    status: true,
                    message: `Token locked Successfully`,
                    token: tokenMint.toBase58(),
                    lock: lockingMachine.toBase58()
                }
            }
            else {
                return {
                    status: false
                }
            }

        }
        catch (err) {
            console.log("UseCreateSale_err", err)
        }
    }

    const UseUnlockToken = async (lockData) => {

        console.log("infooooooooo", lockData);

        try {
            const program = new Program(lockIdl, Config.LOCKTOKEN_PROGRAM_ID, provider);
            console.log("UseUnlockToken_program", program)
            let amount = new BN(`${lockData?.lockamount}`);
            const tokenMint = lockData?.tokenAddress
            const reciever = lockData?.receiverAddress

            console.log("INPUTS : ", Config.LOCKTOKEN_PROGRAM_ID)

            const lockingMachine =  new PublicKey(lockData?.lockAddress);
            console.log("lockingMachine", lockingMachine, lockingMachine.toBase58())

            const lockInfo = await UseLockInfo(lockingMachine.toBase58());
            console.log("lockInfo", lockInfo)

            const authority_ata = await saleHook.UseTokenAccountPerAddress(lockInfo?.authority, tokenMint, provider?.wallet?.publicKey);
            console.log("authority_ata", authority_ata)

            const lockingMachine_ata = await saleHook.UseTokenAccountPerAddress(lockingMachine, tokenMint, provider.wallet.publicKey);
            console.log("lockingMachine_ata", lockingMachine_ata)

            const reciever_ata = await saleHook.UseTokenAccountPerAddress(reciever, tokenMint, provider.wallet.publicKey);
            console.log("reciever_ata", reciever_ata)

            let result = await program?.rpc?.unlockTokens(
                amount,
                {
                    accounts: {
                        lockingMachine: lockingMachine,
                        authoritySplAta: authority_ata,
                        lockingMachineSplAta: lockingMachine_ata,
                        recieverSplAta: reciever_ata,
                        authority: lockInfo.authority,
                        splMint: tokenMint,
                        reciever: reciever,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                        systemProgram: SystemProgram.programId,

                    },
                });
            console.log("UseUnlockToken_result", result)

            if (result) {
                return {
                    status: true,
                    message: `Token unlocked Successfully`,
                    token: tokenMint.toBase58(),
                    sale: lockingMachine.toBase58()
                }
            }
            else {
                return {
                    status: false
                }
            }

        }
        catch (err) {
            let message = err.toString()
            console.log("Useunlock_err", err, message.split(":")[3])
            return{
                error : message.split(":")[3],
                status: false
            } 
        }
    }


    const UseChangeReciever = async (lockData) => {

        console.log("infooooooooo", lockData);

        try {
            const program = new Program(lockIdl, Config.LOCKTOKEN_PROGRAM_ID, provider);
            console.log("UseChangeReciever_program", program)
            const tokenMint = lockData?.tokenAddress
            const reciever =   new PublicKey(lockData?.receiverAddress)

            console.log("INPUTS : ", Config.LOCKTOKEN_PROGRAM_ID)

            const lockingMachine =  new PublicKey(lockData?.lockAddress);
            console.log("lockingMachine", lockingMachine, lockingMachine.toBase58())

            const lockInfo = await UseLockInfo(lockingMachine.toBase58());
            console.log("lockInfo", lockInfo)

            let result = await program?.rpc?.changeReciever(
                {
                    accounts: {
                        lockingMachine: lockingMachine,
                        authority: lockInfo?.authority,
                        splMint: tokenMint,
                        reciever: reciever

                    },
                });
            console.log("UseChangeReciever_result", result)

            if (result) {
                return {
                    status: true,
                    message: `Receiver changed Successfully`,
                    token: tokenMint.toBase58(),
                    sale: lockingMachine.toBase58()
                }
            }
            else {
                return {
                    status: false
                }
            }

        }
        catch (err) {
            console.log("UseChangeReciever_err", err)
        }
    }


    return {
        UseLockToken,
        UseUnlockToken,
        UseLockInfo,
        UseTotalLockInfo,
        UseChangeReciever
    }
}