import React, { useState, useEffect } from "react";
import AdminLayout from '../../layout/AdminLayout'
import { commonData } from '../../data/CommonData'
import Pagination from '../../components/Pagination'
import Classes from '../../asset/styles/AdminCreatedLaunchpadList.module.css'
import { useNavigate } from 'react-router-dom'
import Usesale from '../../hooks/useSale'

import { getallsalehook } from '../../actions/userAction'
import { useSelector } from "react-redux";

const AdminCreateLaunchpadList = () => {
  const navigate = useNavigate()
  const saleHook = Usesale()

  const [saleData, setSaleData] = useState({})
  const [saleDBData, setSaleDBData] = useState({})
  const [interval, setInterval] = useState(1)
  const [loadInterval, setLoadInterval] = useState(3)
  const [loadData, setLoadData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const walletdetail = useSelector((state) => state.wallet)
  const [ownerAdd, setOwnerAdd] = useState("")
  const [premium, setPremium] = useState({})


  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }

  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_admin", TotalData)
    setSaleData(TotalData)

    const premiumfilter = TotalData?.filter((val) => {
      console.log("premiumfilter_val>>>>>>>>>", val?.account?.authority.toString(), ownerAdd, val?.account?.authority.toString() == ownerAdd);
      return val?.account?.authority.toString() == ownerAdd
    })
    console.log("123premiumfilter", premiumfilter)
    setPremium(premiumfilter)
    let getSale = [...TotalData]
    let limit = [...getSale].splice(0, loadInterval)
    console.log("getSale_admin2", getSale, limit, getSale.splice(interval, loadInterval))
    setLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb_admin", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getSaleInfo()
    getOwner()
  }, [ownerAdd])


  const loadMore = async (interval) => {
    console.log("Load_moreee", saleData, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...saleData];
    const limit = newly.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setLoadData([...loadData, ...limit])
  }
  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Created Launchpad List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>

            {console.log("loaddata,", loadData, premium)
            }
            {loadData && premium?.length > 0 ?

              <table className={`${Classes.tokenCmnTable} mt-5`}>
                <thead>
                  <tr>
                    <th className="pb-3">Sale Address</th>
                    <th className="pb-3">Symbol</th>
                    <th className="pb-3">Type</th>
                    <th className="pb-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("premium>>>>>>>>>>>>>>>", premium)}

                  {premium && premium?.length > 0 && premium?.map((value) => {
                    console.log("value", value)
                    let saleInfo = value.account
                    let DBData = {}
                    if (saleDBData.length > 0) {
                      DBData = saleDBData?.find((val) => (val?.saleAddress == value?.publicKey?.toString())
                      )
                      console.log("DBData", DBData)
                    }
                    return (
                      <>
                        <tr className={`${Classes.gradientBorder}`}>

                          <td><p
                            className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                          >{DBData?.saleAddress}
                            {/* {`${DBData?.saleAddress?.substring(1, 10)}...${DBData?.saleAddress?.substring(30, 40)}`} */}
                          </p>
                          </td>

                          <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                            >{DBData?.symbol}
                            </p>
                          </td>
                          <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>
                              {saleInfo?.launchType == false ? "Launchpad" : "Private Sale"}

                            </p>
                          </td>

                          <td>
                            {saleInfo?.isWhitelist == false ?
                              <>
                                <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => navigate(`/admin-launchpad-detail/${DBData?.saleAddress}`)}>View</button>
                              </> : <>
                                <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => navigate(`/admin-privatesale-detail/${DBData?.saleAddress}`)}>View</button>
                              </>}
                          </td>
                        </tr>
                      </>
                    )

                  })}
                </tbody>
              </table> :
              <p className="text-white text-center roboto mt-5">No Data Found</p>
            }
          </div>
          {/* <Pagination  /> */}

          {saleData?.length > loadData?.length && saleData?.length < 1 ?
            <div className={`text-center w-100 mt-5`}>
              <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4`} onClick={() => { loadMore(interval + 1) }}>
                <p
                  className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
                >
                  {isLoading ? 'View More Pools...' : 'View More Pools'}
                </p>
              </button>
            </div>
            : <div> </div>
          }
        </div>
      </AdminLayout>
    </>
  )
}

export default AdminCreateLaunchpadList
