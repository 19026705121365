/** npm import */

import React, { useEffect, useState } from "react";

/** local files import */
import Classes from '../../../asset/styles/AdminCreateFairLaunch.module.css'
import { commonData } from "../../../data/CommonData";
import { ImPlus } from "react-icons/im";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import ConnectWallet from "../../../components/ConnectWallet";
import { Col, Row } from "react-bootstrap";
import AdminLayout from '../../../layout/AdminLayout'
import Usetoken from '../../../hooks/useToken'
import HookFunction from '../../../hooks/useStorage'
import Usesale from '../../../hooks/useSale'
import { useWallet } from '@solana/wallet-adapter-react'
import { useDispatch, useSelector } from 'react-redux';
import { isFloat } from "../../../lib/isFloat";
import { urlvalidation } from "../../../validations/userValidations";
import { BN } from "@project-serum/anchor";
import toast from "react-hot-toast";
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { getFees } from "../../../actions/adminAction";
import { isEmpty, ObjectIsempty } from "../../../lib/isEmpty";
import Config from '../../../config';
import CalendarModal from "../../../Modals/CalendarModal";
import Createtokenmodal from "../../Launchpad/createtokenmodal"
import { useaddWhiteListSale } from "../../../actions/userAction";


const AdminCreateFairLaunch = () => {

  const navigate = useNavigate()

  const tokenHook = Usetoken()
  const storageHook = HookFunction()
  const saleHook = Usesale()

  const { wallet, publicKey } = useWallet()
  // console.log("pubSale_accountInfo", wallet, publicKey, wallet?.adapter?.name)

  const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_pubsale", walletdetail)


  // const [currency, setCurrency] = useState(['sol', 'usdt', 'usdc'])
  const [selectedCurrency, setSelectedCurrency] = useState(Config?.Currency)
  const [tokenAddress, setStokenAddress] = useState('')
  const [steps, setSteps] = useState(1)
  const [whiteList, setWhiteList] = useState(false)
  const [listOption, setListOption] = useState('manual')
  // const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())
  const [vestingCont, setVestingCont] = useState(false)
  const [approve, setApprove] = useState(false)
  const [wallets, setWallet] = useState(true)
  const [calculatedValue, setCalculatedValue] = useState(5)
  const [CheckedInd, setCheckedInd] = useState(0)
  const [currency, setCurrency] = useState("SOL")
  const [tokenFee, setTokenFee] = useState(0)
  const [currencyFee, setCurrencyFee] = useState(0)
  const [getFee, setGetFee] = useState([])
  // const [address, setAddress] = useState("6xDBCAebmfkbmjX2nhbiBjmFnsFZ729nKenRHKFgoMRT")
  const [address, setAddress] = useState()
  const [tokeninfo, setTokeninfo] = useState({})
  const [fee, setFee] = useState('')
  const [tokenSold, setTokenSold] = useState('')
  const [errors, setErrors] = useState({})
  const [presaleRate, setPresaleRate] = useState(0)
  const [softCap, setSoftCap] = useState(0)
  const [hardCap, setHardCap] = useState(0)
  const [minimumBuy, setMinimumBuy] = useState(0)
  const [maximumBuy, setMaximumBuy] = useState(0)
  const [islisting, setIslisting] = useState(true)
  const [lockingdays, setLockingDays] = useState(0)
  const [listingRate, setListingRate] = useState(0)
  const [liquidityPercent, setLiquidityPercent] = useState(0)
  const [isvested, setIsvested] = useState(false)
  const [vestingdays, setVestingDays] = useState(0)
  const [vestingPeriod, setVestingPeriod] = useState(0)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [isWhitelisted, setIsWhitelisted] = useState(false)
  const [description, setDescription] = useState('')
  const [logo, setLogo] = useState('')
  const [banner, setBanner] = useState('')
  const [website, setWebsite] = useState('')
  const [youtube, setYoutube] = useState('')
  const [twitter, setTwitter] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [github, setGithub] = useState('')
  const [instagram, setInstagram] = useState('')
  const [reddit, setReddit] = useState('')
  const [saleAdd, setSaleAdd] = useState("")
  const [deposit, setDeposit] = useState(0)
  const [ownerAdd, setOwnerAdd] = useState("")
  const [saleInfo, setSaleInfo] = useState({})
  const [iscreate, setIscreate] = useState(false)
  const [isdeposit, setIsdeposit] = useState(false)
  const [useWithtokenaddress, setUseWithtokenaddress] = useState(Config?.SOL)
  const [isWithToken, setIsWithToken] = useState(false)

  // calendar modal state

  const [showCalendar, setShowCalendar] = useState(false);

  const handleCloseCalendar = () => setShowCalendar(false);
  const handleShowCalendar = () => setShowCalendar(true);
  const [createtokens, setCreatetokens] = useState(false)
  const handleShowCreatetokens = () => setCreatetokens(true)
  const handleCloseCreatetokens = () => setCreatetokens(false)


  // form validation

  const [formData, setFormData] = useState({
    tokenAddress: '',
    presaleRate: '',
    whiteList: '',
    softCap: '',
    hardCap: '',
    minimumBuy: '',
    maximumBuy: '',
    listingOptions: listOption,
    lockingDays: '',
    listingRate: '',
    liquidity: '',
    startTime: startDate,
    endTime: endDate,
    vestCont: vestingCont,
    vestingPeriod: '',
    vestingRewards: '',
    description: '',
    logoUrl: '',
    bannerUrl: '',
    website: '',
    yVideo: '',
    twitter: '',
    telegram: '',
    discord: '',
    github: '',
    instagram: '',
    reddit: ''
  })



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
  console.log(formData, 'dfsafasfasfsa');

  const handleIncreateStep = () => {
    const err = {}

    if (steps < 7) setSteps(newStep => newStep + 1)
    else if (steps >= 7) {
      setSteps(1)
      navigate(`/admin-launchpad-detail/${saleAdd}`)
    }
  }

  useEffect(() => {
    window.scroll(0, 0)
  }, [])



  useEffect(() => {
    getFeeSettings()
    getTokenInfo()
    getOwner()
  }, [walletdetail?.walletaddress, address])

  const getFeeSettings = async () => {
    let get = await getFees();
    console.log("getFee", get);
    if (get?.data?.data?.status == true) {
      let data = get?.data?.data?.data
      console.log("DATA", data)
      setFee(data[0].currencyValue)
      setTokenSold(data[0].tokenValue)
      setGetFee(get?.data?.data?.data);
      console.log("getFee_getFee", get?.data?.data?.data)
    }
  }

  const getTokenInfo = async () => {
    if (!isEmpty(address)) {
      // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress, address)
      let tokenInfo = await tokenHook.getTokenNameSymbol(address)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
      return tokenInfo

    }
  }

  const handledisable = () => {

    var isvalid = true;
    var i = 1;
    console.log(presaleRate, softCap, hardCap, minimumBuy, maximumBuy, startDate, endDate, "handledisable")
    console.log(ObjectIsempty(errors), "ObjectIsempty", presaleRate && softCap && hardCap && minimumBuy && maximumBuy && startDate && endDate)
    if (ObjectIsempty(errors) && presaleRate
      && hardCap && minimumBuy && maximumBuy && startDate &&
      endDate && lockingdays && liquidityPercent) {
      isvalid = false

      console.log("vest1", isvalid);


      if (isvested) {
        isvalid = true;
        console.log("vest4", isvalid, vestingdays, vestingPeriod, vestingdays && vestingPeriod);
        if (vestingdays && vestingPeriod) {
          isvalid = false
          console.log("vest5", isvalid);
        } else {
          return isvalid
        }
      }

      // console.log("tokeninfo.totalSupply", tokeninfo?.tokenBalance)
      // if (!isEmpty(hardCap) && !isEmpty(presaleRate)) {
      //   isvalid = false
      //   console.log("TotalValue", hardCap, presaleRate, tokeninfo?.tokenBalance, hardCap * presaleRate, tokeninfo?.tokenBalance < (hardCap * presaleRate))
      //   if (tokeninfo?.tokenBalance < (hardCap * presaleRate)) {
      //     // toast.error("Hardcap and Presale Rate should not be greater than totalSupply!")
      //     isvalid = true
      //   } else {
      //     return isvalid
      //   }
      // }

    }
    console.log("isvalid", isvalid);
    return isvalid;
  }


  const filterPassedTime = (time) => {
    console.log(time, 'filterPassedTime')
    const currentDate = new Date();
    const selectedDate = new Date(time);
    console.log(currentDate.getTime() < selectedDate.getTime(), 'filterPassedTime')
    return currentDate.getTime() < selectedDate.getTime()
  };


  const validateTokenData = async () => {
    var errors = {}
    console.log("validateTokenData_state", address, fee, tokenSold)

    if (isEmpty(address)) {
      errors.address = "Token address field is required"
    }
    else if (isEmpty(tokeninfo)) {
      errors.address = "Invalid Token address"
    }
    let isValid = checkTokenAddress(address)
    console.log("isValid", isValid)
    if (!isValid) {
      errors.address = "Token Address already exists"
    }

    if (isEmpty(fee) || isEmpty(tokenSold)) {
      errors.fee = "Fee options field is required"
    }
    setErrors(errors)
    return errors
  }


  const validations = () => {

    var errors = {}

    var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/)
    var rd = new RegExp(/^\d+$/);
    // if (!presaleRate || isNaN(presaleRate) || presaleRate == 0) {
    //   errors.presaleRate = "Invalid Total presale"
    // }
    // else
    //   if (rx.test(presaleRate) || rd.test(presaleRate) || !isNaN(presaleRate)) {
    //     errors.presaleRate = ""
    //   }
    //   else {
    //     errors.presaleRate = "Invalid presale"

    //   }

    console.log("softCap", softCap, isEmpty(softCap), isNaN(softCap) || isEmpty(softCap))
    if (isNaN(softCap) || isEmpty(softCap)) {
      errors.softCap = "Invalid softcap !"
    }
    else if (hardCap) {
      if (softCap > (parseFloat(hardCap))) {
        console.log("Softcap must");
        errors.softCap = "Softcap must be less than Hardcap"
      }
      else {
        errors.softCap = ""
      }
    }
    else {
      errors.softCap = ""
    }

    if (!hardCap || isNaN(parseFloat(hardCap))) {
      console.log("invalid");
      errors.hardCap = "Invalid Total Selling Amount !"

    }

    // else if (hardCap < parseFloat(softCap)) {
    //   errors.hardCap = "Hardcap must be greater than softcap !"
    // }

    else {
      errors.hardCap = ""
    }

    // if (softCap > (parseFloat(hardCap))) {
    //   errors.softCap = "Softcap must be less than Hardcap"
    // }



    // if (!isEmpty(hardCap) && !isEmpty(presaleRate)) {
    //   console.log("TotalValue", hardCap, presaleRate, hardCap * presaleRate, (hardCap * presaleRate), tokeninfo?.tokenBalance, (tokeninfo?.tokenBalance) < (hardCap * presaleRate))
    //   // if ((parseFloat(tokeninfo.totalSupply) / 10 ** 18) < (hardCap * presaleRate)) {
    //   if (tokeninfo?.tokenBalance < (hardCap * presaleRate)) {
    //     // toast.error("Hardcap and Presale Rate should not be greater than totalSupply!")
    //     errors.hardCap = "Hardcap and Presale Rate should not be greater than totalSupply!"
    //     errors.presaleRate = "Hardcap and Presale Rate should not be greater than totalSupply!"
    //   }
    //   else {
    //     errors.hardcap = ""
    //     errors.presaleRate = ""
    //   }
    // }

    if (isNaN(minimumBuy)) {
      errors.minimumBuy = "Invalid Minimum Buy "
    }

    if (!minimumBuy) {
      errors.minimumBuy = "Minimum Buy field is required"
    }

    if (maximumBuy) {
      if (minimumBuy > maximumBuy) {
        errors.minimumBuy = "Minimum Buy  must be < Maximum Buy"

      }
      else {
        errors.minimumBuy = ""

      }
    }


    if (isNaN(maximumBuy)) {
      errors.maximumBuy = "Invalid Maximum Buy "

    }
    if (!maximumBuy) {
      errors.maximumBuy = "Maximum Buy field is required"

    }
    if (minimumBuy) {
      if (maximumBuy < minimumBuy) {
        errors.maximumBuy = "Maximum Buy  must be > Minimum Buy"

      }
      else {
        errors.maximumBuy = ""

      }
    }

    if (islisting) {
      var rx = new RegExp(/^\d+$/);
      if (!rx.test(lockingdays)) {
        errors.lockingdays = "Invalid Locking Days !"

      }
      else if (isEmpty(lockingdays)) {
        errors.lockingdays = "Locking Day field is required"
      }
      else if (lockingdays < 30) {
        errors.lockingdays = "Locking Days should be greater than 30 days"
      }
      else {
        errors.lockingdays = ""

      }

      var rx = new RegExp(/^\d+$/);


      if (isNaN(liquidityPercent) || liquidityPercent < 51 || liquidityPercent > 100) {
        errors.liquidityPercent = "Invalid Solmax Liquidity !"

      }
      else {
        errors.liquidityPercent = ""

      }

      // if (isNaN(listingRate)) {
      //   errors.listingRate = "Invalid Listing rate !"

      // }
      // else if (isEmpty(listingRate)) {
      //   errors.listingRate = "Listing rate field is required!"
      // }
      // else {
      //   errors.listingRate = ""
      // }
    }

    if (new Date(startDate).getTime() >= new Date().getTime()) {
      errors.startDate = ""
    }
    else if (isEmpty(startDate)) {
      errors.startDate = "Start Date field is required!"
    }
    else {
      errors.startDate = "Start Time needs to be after now"

    }
    if (endDate) {
      if (new Date(startDate).getTime() > endDate) {
        errors.startDate = "Start Time must be < End Time"

      }
      if (endDate > new Date(startDate).getTime()) {
        errors.endDate = ""

      }

    }

    if (new Date(endDate).getTime() < Date.now()) {
      console.log("endDate")
      errors.endDate = "End Time needs to be after now"

    }
    else if (isEmpty(endDate)) {
      errors.endDate = "End Date field is required!"
    }
    else {
      errors.endDate = ""
    }


    if (startDate) {
      if (new Date(endDate).getTime() <= startDate) {
        console.log("invalideee");
        errors.endDate = "End Time must be > Start Time"
      }
      if (startDate < new Date(endDate).getTime()) {
        errors.startDate = ""
        errors.endDate = ""
      }

    }

    if (isvested) {
      var rx = new RegExp(/^\d+$/);
      if (!rx.test(vestingdays)) {
        errors.vestingdays = "Invalid Vesting Period Days !"

      }
      else if (isEmpty(vestingdays)) {
        errors.vestingdays = "Vesting Period Days field is required!"

      }
      else {
        errors.vestingdays = ""

      }


      if (isNaN(vestingPeriod) || vestingPeriod < 0 || vestingPeriod > 100) {
        errors.vestingPeriod = "Invalid Rewards % per Vesting Period !"
      }
      else if (isEmpty(vestingPeriod)) {
        errors.vestingPeriod = "Vesting Period field is required!"

      }
      else {
        errors.vestingPeriod = ""

      }
    }
    console.log("validation_errors", errors)
    setErrors(errors);
    if (ObjectIsempty(errors)) {
      setSteps(3)
    }
  }

  const socialMediaValidations = () => {
    var errors = {}

    if (isEmpty(description)) {
      errors.description = "Description field is required"
    }

    if (isEmpty(banner)) {
      errors.banner = "Banner field is required"
    }

    if (isEmpty(twitter)) {
      errors.twitter = "Twitter field is required"
    }

    if (isEmpty(github)) {
      errors.github = "Github field is required"
    }

    if (isEmpty(telegram)) {
      errors.telegram = "Telegram field is required"
    }

    if (isEmpty(instagram)) {
      errors.instagram = "Instagram field is required"
    }

    if (isEmpty(discord)) {
      errors.discord = "Discord field is required"
    }

    if (isEmpty(reddit)) {
      errors.reddit = "Reddit field is required"
    }

    if (isEmpty(youtube)) {
      errors.youtube = "Youtube field is required"
    }

    if (isEmpty(website)) {
      errors.website = "Website field is required"
    }

    if (isEmpty(logo)) {
      errors.logo = "Logo field is required"
    }

    setErrors(errors)
    return errors
  }


  const calculateDepositTokens = () => {
    // let topancakeTokens = 0;
    // let pancakeTokens = 0;
    // const presalePrice = 1 / presaleRate;
    // if (islisting) {
    //   const pancakePrice = 1 / listingRate;
    //   topancakeTokens = (hardCap) * liquidityPercent / 100;
    //   pancakeTokens = topancakeTokens / pancakePrice;
    //   console.log("pancake tokens : ", pancakeTokens)
    // }
    // const netTokens = (hardCap) / presalePrice;
    var DepositToken = tokeninfo?.tokenBalance
    // var DepositToken = tokeninfo?.tokenBalance
    console.log("DepositToken", DepositToken)
    setDeposit(DepositToken);
    handleIncreateStep()
  }


  const saleCreation = async () => {
    try {
      setIscreate(true)
      let tokenData = await getTokenInfo()
      let vestingDayinSecs = parseFloat(vestingdays) * 86400
      let lockingDayinSecs = parseFloat(lockingdays) * 86400
      let info = {
        tokenAddress: new PublicKey(address),
        ppt: new BN(presaleRate * LAMPORTS_PER_SOL),
        softcapAmount: new BN(softCap * LAMPORTS_PER_SOL),
        hardcapAmount: new BN(hardCap * LAMPORTS_PER_SOL),
        minimumBuy: new BN(minimumBuy * LAMPORTS_PER_SOL),
        maximumBuy: new BN(maximumBuy * LAMPORTS_PER_SOL),
        islisting: islisting,
        lockingdays: new BN(lockingDayinSecs),
        listingRate: new BN(listingRate * LAMPORTS_PER_SOL),
        liquidityPercent: new BN(liquidityPercent),
        isvested: isvested,
        vestingdays: new BN(vestingDayinSecs),
        vestingPeriod: new BN(vestingPeriod),
        startDate: new BN(startDate),
        endDate: new BN(endDate),
        description: description,
        logo: logo,
        banner: banner,
        website: website,
        youtube: youtube,
        twitter: twitter,
        telegram: telegram,
        discord: discord,
        github: github,
        instagram: instagram,
        reddit: reddit,
        currencyRaised: new BN(0 * LAMPORTS_PER_SOL),
        fee: new BN(fee * LAMPORTS_PER_SOL),
        tokenSoldFee: new BN(tokenSold * LAMPORTS_PER_SOL),
        LaunchType: "FairLaunch",
        isWhitelist: isWhitelisted,
        name: tokenData?.name,
        symbol: tokenData?.symbol,
        useWithToken: isWithToken,
        buyMint: new PublicKey(useWithtokenaddress)
      }
      let resp = await saleHook.UseCreateSale(info)
      console.log("CreatedSale", resp, resp?.sale)
      setIscreate(false)
      setSaleAdd(resp?.sale)
      resp?.status == true ?
        toast.success('Sale created Successfully')
        :
        toast.error('Error while creating sale...!')
      if (resp?.status) {
        let data = {
          sale: resp?.sale,
          isWhiteListed: isWhitelisted
        }

        let newsale = await useaddWhiteListSale(data)
        console.log("newsale", newsale);

      }
      if (resp?.status == true) {
        setSteps(5)
      }
      else {
        setSteps(4)
      }
    }
    catch (err) {
      console.log("create_err", err)
    }
  }


  const depositToken = async () => {
    setIsdeposit(true)
    const resp = await saleHook.UseDepositFunds(address, deposit)
    console.log("depositFn", resp)
    setIsdeposit(false)
    resp?.status == true ?
      toast.success('Tokens deposited Successfully')
      :
      toast.error('Error while depositing token...!')
    if (resp?.status == true) {
      setSteps(7)
    }
    else {
      setSteps(6)
    }
  }


  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }

  useEffect(() => {
    getSaleInfo()
  }, [])

  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_checkTokenAddress", TotalData)
    setSaleInfo(TotalData)
  }


  const checkTokenAddress = (address) => {
    let getToken
    getToken = saleInfo?.filter((val) => {
      console.log("TotalData", val?.account?.splMint?.toString(), address, val?.account?.splMint?.toString() == address)
      return val?.account?.splMint?.toString() == address
    })
    console.log("getToken", getToken)
    if (!isEmpty(getToken)) {
      return false
    } else {
      return true
    }
  }

  const GetUseWithTokenAdd = (value) => {

    let UseWithtokenaddress;

    if (value === "DAI") {
      UseWithtokenaddress = Config?.DAI
    } else if (value === "USDT") {
      UseWithtokenaddress = Config?.USDT
    } else if (value === "USDC") {
      UseWithtokenaddress = Config?.USDC
    } else if (value === "SOL") {
      UseWithtokenaddress = Config?.SOL
    }

    console.log("WithTokenaddress", UseWithtokenaddress);
    setUseWithtokenaddress(UseWithtokenaddress);

  }


  return (
    <>
      <AdminLayout>
        {/* <div className={``}>
          <section className="custom_section position-relative">
            <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
            {wallets &&
              <ConnectWallet wallet={wallets} setWallet={setWallet} />
            } */}
        {/* <Loader/> */}
        {/* <BalanceDetails /> */}

        {ownerAdd !== walletdetail?.walletaddress ?
          <div className={`${Classes.noteset} text-white poppins`}>
            Note* : Only contract owner can create Launchpad sale
          </div>
          :
          <div className={`${Classes.customFluidContainer}`}>

            <div className={`${Classes.shapeSection}`}>
              <Row className={`position-relative h-100`}>
                <Col lg={5} md={5} xl={4} className={``}>
                  <div className={`${Classes.createStepsHolder} py-3 px-4 h-100`}>
                    {commonData?.createLaunchpadSteps.map((item, index) =>
                      <div className={`${Classes.singleStep} d-flex justify-content-start align-items-start gap-3 mb-1`}>
                        <div className={`${Classes.stepIndicator} d-flex flex-column justify-content-start align-items-center gap-1`}>
                          <i className={`fa-solid fa-circle ${steps === (index + 1) ? Classes.stepRound : Classes.stepRoundBlur}`}></i>
                          <div className={`${steps === (index + 1) ? Classes.stepLiner : Classes.stepLinerBlur}`}></div>
                        </div>

                        <div className={`${Classes.stepsHintHolder} flex-grow-1`}>
                          <h5 className={`${steps === (index + 1) ? Classes.stepTitle : Classes.stepsTitleBlur} ${Classes.resStepTitle} m-0 poppins`}>{item.title}</h5>
                          <p className={`${steps === (index + 1) ? Classes.stepHint : Classes.stepsHintBlur} ${Classes.stepsWidthAlign} ${Classes.resStepHint} m-0 poppins`}>{item.hint}</p>
                        </div>
                      </div>
                    )}

                    <p className={`${Classes.stepsDesc} my-5 m-0`}>Disclaimer: The information provided shall not in any way constitute a recomendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.</p>

                  </div>
                </Col>

                <Col lg={7} md={7} xl={8} className={` p-3 p-sm-4 h-100`}>
                  <Row>
                    <Col lg={11}>
                      <div className={`d-flex justify-content-between align-items-center `}>
                        <h1
                          className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>Create Fair Launchpad</h1>
                        {steps === 1 ?
                          <button className={`${Classes.gradientBtn} border-0 outline-0 py-1 px-2 py-sm-2 px-sm-3 pointer me-md-5`}
                            // onClick={() => navigate('/token-creation')}
                            disabled={isEmpty(walletdetail?.walletaddress)}
                            onClick={() => handleShowCreatetokens()}>
                            <ImPlus /></button>
                          : <></>}
                      </div>
                    </Col>
                  </Row>
                  {steps <= 3 &&
                    <p className={`${Classes.robotoHint} m-0 roboto mt-3`}>(*) is required field.</p>
                  }

                  {steps === 1 &&
                    <>
                      <label for="tokenAddress" className={`${Classes.inputLabel} roboto mt-4`}>Token Address *</label><br />
                      <input type="text" id="tokenAddress" name="tokenAddress" value={address}
                        onChange={(e) => {
                          setAddress(e.target.value)
                          if (isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["address"]: "Invalid Token address" }
                            setErrors(formvalue)
                          }
                          else if (!isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["address"]: "" }
                            setErrors(formvalue)
                          }
                          let isValid = checkTokenAddress(e.target.value)
                          if (!isValid) {
                            const formvalue = { ...errors, ["address"]: "Token Address already exists" }
                            setErrors(formvalue)
                          }
                        }}
                        className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

                      <span className={`cmnErrorHint`}>{errors?.address && errors?.address}</span>


                      <p className={`${Classes.formDescHint} m-0 roboto mt-3`}>Pool creation fee:  {walletdetail?.feeData[0]?.FeeAmount} {Config.Currency}</p>

                      {!isEmpty(tokeninfo) ?
                        <div className={`${Classes.tokenDetails} mt-4`}>
                          <div className={`d-flex justify-content-between align-items-center mb-2`}>
                            <p className={`${Classes.radioHint} m-0`}>Name :</p>
                            <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.name}</p>
                          </div>
                          <div className={`d-flex justify-content-between align-items-center mb-2`}>
                            <p className={`${Classes.radioHint} m-0`}>Symbol :</p>
                            <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.symbol}</p>
                          </div>
                          {!isEmpty(tokeninfo?.decimal) ?
                            <div className={`d-flex justify-content-between align-items-center mb-2`}>
                              <p className={`${Classes.radioHint} m-0`}>Decimals :</p>
                              <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.decimal}</p>
                            </div>
                            : ""}
                        </div>
                        : ""}

                      <label for="currency" className={`${Classes.inputLabel} roboto mt-4`}>Currency</label>

                      <div className={`${Classes.tabHolder} d-flex justify-content-start align-items-center gap-2 mt-3`}>

                      <button
                            className={`${selectedCurrency === 'SOL' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelectedCurrency('SOL')
                              GetUseWithTokenAdd('SOL')
                              setIsWithToken(false)
                            }}>
                            <p className={`${selectedCurrency === 'SOL' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>sol</p>
                          </button>
                          <button
                            className={`${selectedCurrency === 'USDT' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelectedCurrency('USDT')
                              GetUseWithTokenAdd('USDT')
                              setIsWithToken(true)
                            }}>
                            <p className={`${selectedCurrency === 'USDT' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>usdt</p>
                          </button>
                          <button
                            className={`${selectedCurrency === 'USDC' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelectedCurrency('USDC')
                              GetUseWithTokenAdd('USDC')
                              setIsWithToken(true)
                            }}>
                            <p className={`${selectedCurrency === 'USDC' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>usdc</p>
                          </button>
                          <button
                            className={`${selectedCurrency === 'DAI' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelectedCurrency('DAI')
                              GetUseWithTokenAdd('DAI')
                              setIsWithToken(true)
                            }}>
                            <p className={`${selectedCurrency === 'DAI' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>dai</p>
                          </button>

                      </div>

                      <p className={`${Classes.robotoHint} m-0 roboto mt-3`}>Users will pay with {selectedCurrency} for your token</p>

                      <label for="tokenAddress" className={`${Classes.inputLabel} roboto mt-4`}>Fee Options</label>
                      {getFee && getFee.map((data, index) => {
                        console.log("map", data, index)
                        let isCurrency = data.currencyValue > 0 ? true : false
                        let isToken = data.tokenValue > 0 ? true : false
                        let i = index
                        return (
                          <>

                            <div className={`${Classes.radioHolder} mt-3`}>

                              <div onClick={() => {
                                console.log("valsdgfsdsdgsd", data, 5, i);
                                setCheckedInd(i)
                                setFee(data?.currencyValue)
                                setTokenSold(data?.tokenValue)
                              }}>

                                <input className="form-check-input" type="checkbox" id="customRadio" name={`customRadio${i}`}
                                  checked={CheckedInd == i ? true : false}
                                />

                                {console.log("tokenFee+bnbFee", tokenFee, currencyFee)}
                                <label className="custom-control-label" for={`customRadio${i}`}>
                                  {/* <i className={`fa-solid fa-square ${fee === 1 ? Classes.radioCheckerActive : Classes.radioChecker}`} /> */}
                                  {isCurrency ? `${data.currencyValue}% ${Config.Currency} raised` : ""}
                                  {isCurrency && isToken ? "+" : ''} {isToken ? `${data.tokenValue}% token sold` : ''}
                                  <span>{isCurrency && !isToken ? "(recommended)" : ''}</span></label>
                              </div>
                            </div>
                          </>
                        )
                      }
                      )}


                      {/* 
                        <div className={`${Classes.radioHolder} mt-3`}>
                          <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`} onClick={() => setFee(1)}>
                            <i className={`fa-solid fa-square ${fee === 1 ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                            <p className={`${Classes.radioHint}`}>2% <b>SOL</b> + 2 token <b>SOL</b></p>
                          </button>

                          <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`} onClick={() => setFee(2)}>
                            <i className={`fa-solid fa-square ${fee === 2 ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                            <p className={`${Classes.radioHint}`}>5% <b>SOL</b> raised (recommended)</p>
                          </button>
                        </div> */}



                      <button className={`${Classes.gradientBtn} border-0 outline-0 w-100 py-3 mt-5`}
                        onClick={async () => {
                          let isvalid = await validateTokenData()
                          console.log("validateTokenData", isvalid)
                          if (isEmpty(isvalid)) {
                            setSteps(2)
                          }
                        }}>
                        <h5 className={`${Classes.stepTitle} roboto m-0`}>Next</h5>
                        {/* <p className={`${Classes.stepsDesc} roboto m-0`}>(For auto listing, after you finalize the pool your token will be auto listed on DEX)</p> */}
                      </button>
                    </>
                  }


                  {steps === 2 &&
                    <>
                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="presaleRate" className={`${Classes.inputLabel} roboto`}>Total Selling Amount *</label><br />
                        <input type="number" name="hardCap" id="hardCap" value={hardCap}
                          onChange={(e) => {
                            const formvalue = { ...errors, ["hardCap"]: "" }
                            let Errors = errors
                            setErrors(formvalue)
                            setHardCap(e.target.value);

                            var rd = new RegExp(/^\d+((.)|(.\d{0,4})?)$/);
                            console.log("!rd.test(parseFloat(e.target.value))", !rd.test(parseFloat(e.target.value)))
                            if (!rd.test(parseFloat(e.target.value))) {

                              Errors = { ...Errors, ["hardCap"]: "Invalid Total Selling Amount" }
                              setErrors(Errors)
                            }
                            else if (isNaN(e.target.value)) {
                              Errors = { ...Errors, ["hardCap"]: "Invalid Total Selling Amount" }
                              setErrors(Errors)
                            }
                            else if (!e.target.value || e.target.value == 0) {
                              Errors = { ...Errors, ["hardCap"]: "Total Selling Amount field is required" }
                              setErrors(Errors)
                            }
                            else if (parseFloat(e.target.value) < parseFloat(softCap)) {
                              Errors = { ...Errors, ["hardCap"]: "Total Selling Amount must be greater than the softcap" }
                              setErrors(Errors)
                            }
                            else {
                              Errors = { ...Errors, ["hardCap"]: "" }
                              setErrors(Errors)
                            }

                            if (parseFloat(softCap) > (parseFloat(e.target.value))) {
                              Errors = { ...Errors, ["softCap"]: "Softcap must be less than total selling amount" }
                              setErrors(Errors)
                            }
                            else if (parseFloat(softCap) < (parseFloat(e.target.value))) {
                              Errors = { ...Errors, ["softCap"]: "" }
                              setErrors(Errors)
                            }
                            if (maximumBuy) {
                              if (parseFloat(maximumBuy) >= parseFloat(e.target.value)) {
                                { console.log("minmax") }
                                Errors = { ...Errors, ["maximumBuy"]: "Maximum Buy should not be greater than or equal to total selling amount" }
                                setErrors(Errors);
                              }
                              else {
                                Errors = { ...Errors, ["maximumBuy"]: "" }
                                setErrors(Errors);
                              }

                            }


                            handledisable();
                            console.log("condition", e.target.value < softCap)

                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.hardCap && errors?.hardCap}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="whiteList" className={`${Classes.inputLabel} roboto`}>Whitelist</label><br />
                        <div className={`${Classes.radioHolder} mt-3`}>
                          <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`}
                            onClick={() => setIsWhitelisted(false)}>
                            <i className={`fa-solid fa-square ${!isWhitelisted ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                            <p className={`${Classes.radioHint}`}>Disable</p>
                          </button>

                          <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`}
                            onClick={() => setIsWhitelisted(true)}>
                            <i className={`fa-solid fa-square ${isWhitelisted ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                            <p className={`${Classes.radioHint}`}>Enable</p>
                          </button>
                        </div>

                      </div>


                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="softCap" className={`${Classes.inputLabel} roboto`}>Soft Cap *</label><br />
                        <input type="number" id="softCap" name="softCap" value={softCap}
                          onChange={(e) => {
                            setSoftCap(e.target.value)
                            const formvalue = { ...errors, ["softCap"]: "" }
                            let Errors = errors
                            setErrors(formvalue);
                            var rd = new RegExp(/^\d+$/);
                            var rx = new RegExp(/^\d+((.)|(.\d{0,4})?)$/)

                            if (!rx.test(parseFloat(e.target.value))) {
                              Errors = { ...Errors, ["softCap"]: "Invalid softcap !" }
                              setErrors(Errors);
                            }
                            else if (isNaN(e.target.value)) {
                              Errors = { ...Errors, ["softCap"]: "Invalid softcap" }
                              setErrors(Errors);
                            }
                            else if (!e.target.value) {
                              Errors = { ...Errors, ["softCap"]: "Softcap field is required" }
                              setErrors(Errors);
                            }
                            else if (parseFloat(e.target.value) > (parseFloat(hardCap))) {
                              Errors = { ...Errors, ["softCap"]: "Softcap must be less than total selling amount" }
                              setErrors(Errors);
                            }
                            else {
                              Errors = { ...Errors, ["softCap"]: "" }
                              setErrors(Errors);
                            }

                            if (parseFloat(hardCap) < (parseFloat(e.target.value))) {
                              Errors = { ...Errors, ["hardCap"]: "Total selling amount must be greater than the softcap" }
                              console.log("Errors", Errors, hardCap, e.target.value)
                              setErrors(Errors);
                            }
                            else if (parseFloat(hardCap) > (parseFloat(e.target.value))) {
                              Errors = { ...Errors, ["hardCap"]: "" }
                              console.log("Errors", Errors, hardCap, e.target.value)
                              setErrors(Errors);
                            }

                            handledisable();
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

                        <span className={`cmnErrorHint`}>{errors?.softCap && errors?.softCap}</span>

                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="minimumBuy" className={`${Classes.inputLabel} roboto`}>Minimum Buy *</label><br />
                        <input type="number" id="minimumBuy" name="minimumBuy"
                          value={minimumBuy}
                          onChange={(e) => {
                            setMinimumBuy(e.target.value)
                            const formvalue = { ...errors, ["minimumBuy"]: "" }
                            let Errors = errors
                            setErrors(formvalue);
                            if (isNaN(e.target.value) || e.target.value === '0') {
                              Errors = { ...Errors, ["minimumBuy"]: "Invalid Minimum Buy " }
                              setErrors(Errors);
                            }
                            else if (!e.target.value) {
                              Errors = { ...Errors, ["minimumBuy"]: "Minimum Buy field is required" }
                              setErrors(Errors);
                            }
                            else {
                              Errors = { ...Errors, ["minimumBuy"]: "" }
                              setErrors(Errors);
                            }

                            if (maximumBuy) {
                              if (e.target.value >= maximumBuy) {
                                Errors = { ...Errors, ["minimumBuy"]: "Minimum Buy must be < Maximum Buy" }
                                setErrors(Errors);
                              }
                              else if (parseFloat(maximumBuy) >= parseFloat(hardCap)) {
                                { console.log("minmax") }
                                Errors = { ...Errors, ["maximumBuy"]: "Maximum Buy should not be greater than or equal to total selling amount" }
                                setErrors(Errors);
                              }
                              else if (parseFloat(e.target.value) < parseFloat(maximumBuy)) {
                                Errors = { ...Errors, ["maximumBuy"]: "" }
                                setErrors(Errors);
                              }

                            }
                            else {
                              Errors = { ...Errors, ["minimumBuy"]: "" }
                              setErrors(Errors);
                            }

                            handledisable();
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.minimumBuy && errors?.minimumBuy}</span>

                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="maximumBuy" className={`${Classes.inputLabel} roboto`}>Maximum Buy *</label><br />
                        <input type="number" id="maximumBuy" name="maximumBuy" value={maximumBuy}
                          onChange={(e) => {
                            setMaximumBuy(e.target.value)
                            const formvalue = { ...errors, ["maximumBuy"]: "" }
                            let Errors = errors
                            setErrors(formvalue);
                            { console.log("minmax", e.target.value, hardCap) }
                            if (isNaN(e.target.value)) {
                              Errors = { ...Errors, ["maximumBuy"]: "Invalid Maximum Buy " }
                              setErrors(Errors);
                            }
                            else if (!e.target.value) {
                              Errors = { ...Errors, ["maximumBuy"]: "Maximum Buy field is required" }
                              setErrors(Errors);
                            }

                            else if (parseFloat(e.target.value) >= parseFloat(hardCap)) {
                              { console.log("minmax") }
                              Errors = { ...Errors, ["maximumBuy"]: "Maximum Buy should not be greater than or equal to total selling amount" }
                              setErrors(Errors);
                            }
                            else {
                              Errors = { ...Errors, ["maximumBuy"]: "" }
                              setErrors(Errors);
                            }
                            if (minimumBuy) {
                              if (parseFloat(e.target.value) <= parseFloat(minimumBuy)) {
                                Errors = { ...Errors, ["maximumBuy"]: "Maximum Buy must be > Minimum Buy" }
                                setErrors(Errors);
                              }
                              if (parseFloat(e.target.value) > parseFloat(minimumBuy)) {
                                Errors = { ...Errors, ["minimumBuy"]: "" }
                                setErrors(Errors);
                              }

                            }
                            else {
                              Errors = { ...Errors, ["maximumBuy"]: "" }
                              setErrors(Errors);
                            }

                            handledisable();
                          }}

                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

                        <span className={`cmnErrorHint`}>{errors?.maximumBuy && errors?.maximumBuy}</span>

                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="lockingDays" className={`${Classes.inputLabel} roboto`}>Locking Days *</label><br />
                        <input type="number" id="lockingDays" name="lockingDays" value={lockingdays}
                          onChange={(e) => {
                            setLockingDays(e.target.value)
                            var rx = new RegExp(/^\d+$/);
                            if (!rx.test(e.target.value)) {
                              const formvalue = { ...errors, ["lockingdays"]: "Invalid Locking Days !" };
                              setErrors(formvalue);
                            }
                            else if (e.target.value < 30) {
                              const formvalue = { ...errors, ["lockingdays"]: "Locking Days should be greater than 30 days" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["lockingdays"]: "" };
                              setErrors(formvalue);
                            }
                            handledisable()
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

                        <span className={`cmnErrorHint`}>{errors?.lockingdays && errors?.lockingdays}</span>

                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="liquidity" className={`${Classes.inputLabel} roboto`}>Solmax Liquidity (51% Minimum) *</label><br />
                        <input type="number" id="liquidity" name="liquidity" value={liquidityPercent}
                          onChange={(e) => {
                            if (!isFloat(e.target.value)) {
                              setLiquidityPercent(e.target.value)
                              if (isNaN(e.target.value) || e.target.value < 50 || e.target.value > 100) {
                                const formvalue = { ...errors, ["liquidityPercent"]: "Invalid Solmax Liquidity !" };
                                setErrors(formvalue);
                              }
                              else {
                                const formvalue = { ...errors, ["liquidityPercent"]: "" };
                                setErrors(formvalue);
                              }
                            }
                            handledisable();
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

                        <span className={`cmnErrorHint`}>{errors?.liquidityPercent && errors?.liquidityPercent}</span>

                      </div>

                      {/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="listingOptions" className={`${Classes.inputLabel} roboto`}>Listing Options</label><br/>
             <div className={`d-flex justify-content-start align-items-center gap-3 mt-3`}>
             <button className={`${listOption === 'auto' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setListOption('auto')}>
                  <p className={`${listOption === 'auto' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>Auto</p>
                  </button>
                  <button className={`${listOption === 'manual' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setListOption('manual')}>
                  <p className={`${listOption === 'manual' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>Manual</p>
                  </button>
                  </div>
              </div> */}


                      <p className={`${Classes.formDescHint} m-0 roboto mt-4`}>Enter the percentage of raised funds that should be allocated to Liquidity on (Min 51% Max 100%)</p>
                      <p className={`${Classes.formDescHint} m-0 roboto mt-3`}>If I spend 1 {selectedCurrency} on how many tokens will I receive? Usually the amount is lower than presale rate to allow for higher listing price on </p>


                      {/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="lockingDays" className={`${Classes.inputLabel} roboto`}>Locking Days</label><br/>
              <input type="text" id="lockingDays" name="lockingDays" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.lockingDays && errors?.lockingDays}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="listingRate" className={`${Classes.inputLabel} roboto`}>Listing Rate Per LineaETH</label><br/>
              <input type="text" id="listingRate" name="listingRate" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.listingRate && errors?.listingRate}</span>
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>1 LineaETH = 1000</p>
              </div>

 <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="liquidity" className={`${Classes.inputLabel} roboto`}>Solmax Liquidity (51% Minimum)</label><br/>
              <input type="text" id="liquidity" name="liquidity" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.liquidity && errors?.liquidity}</span>
             </div> */}


                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label className={`${Classes.inputLabel} roboto`}>Select Start time & End time (UTC)</label><br />
                        <label for="startTime" className={`${Classes.inputLabel} roboto mt-2`}>Start time (UTC)</label><br />
                        <div className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
                          <div className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
                            <DatePicker
                              autoComplete="off"
                              minDate={new Date()}
                              selected={startDate ? new Date(startDate) : ""}
                              name="startTime"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              showTimeSelect
                              onChange={(date) => {
                                setStartDate(new Date(date).getTime())
                                if (new Date(date).getTime() >= new Date().getTime()) {
                                  let formData = { ...errors, ["startDate"]: "" };
                                  setErrors(formData);
                                }
                                else {
                                  let formData = { ...errors, ["startDate"]: "Start Time needs to be after now" };
                                  setErrors(formData);
                                }
                                if (endDate) {
                                  if (new Date(date).getTime() > endDate) {
                                    let formData = { ...errors, ["startDate"]: "Start Time must be < End Time" };
                                    setErrors(formData);
                                  }
                                  if (endDate > new Date(date).getTime()) {
                                    let formData = { ...errors, ["endDate"]: "" };
                                    setErrors(formData);
                                  }

                                }
                                handledisable();
                              }

                              }
                              // onKeyDown={(e) => {
                              //   e.preventDefault();
                              // }}
                              filterTime={filterPassedTime()}
                              className={`p-2 roboto flex-grow-1`}
                            />
                            <button className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`}
                              onClick={handleShowCalendar}><i class="fa-solid fa-calendar-days" /></button>
                          </div>
                        </div>
                        <span className={`cmnErrorHint`}>{errors?.startDate && errors?.startDate}</span>

                      </div>


                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="endTime" className={`${Classes.inputLabel} roboto mt-2`}>End time (UTC)</label><br />
                        <div className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
                          <div className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
                            <DatePicker
                              autoComplete="off"
                              minDate={new Date()}
                              selected={endDate ? new Date(endDate) : ""}
                              name='endTime'
                              showTimeSelect
                              onChange={(date) => {
                                setEndDate(new Date(date).getTime());

                                if (new Date(date).getTime() > Date.now()) {
                                  let formData = { ...errors, ["endDate"]: "" };
                                  setErrors(formData);
                                }
                                else {
                                  let formData = { ...errors, ["endDate"]: "End Time needs to be after now" };
                                  setErrors(formData);
                                }
                                if (startDate) {
                                  if (new Date(date).getTime() <= startDate) {
                                    console.log("invalideee");
                                    let formData = { ...errors, ["endDate"]: "End Time must be > Start Time" };
                                    setErrors(formData);
                                    console.log("invalideee", formData);
                                  }
                                  if (startDate < new Date(date).getTime()) {
                                    let formData = { ...errors, ["startDate"]: "" };
                                    setErrors(formData);
                                    let formdata = { ...errors, ["endDate"]: "" };
                                    setErrors(formdata);
                                  }

                                }
                                handledisable();

                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className={`p-2 roboto flex-grow-1`} />
                            <button className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`}
                              onClick={handleShowCalendar}><i class="fa-solid fa-calendar-days" /></button>
                          </div>
                        </div>
                        <span className={`cmnErrorHint`}>{errors?.endDate && errors?.endDate}</span>
                      </div>

                      <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer mt-2`} name="vestCont"
                        onClick={() => { setIsvested(!isvested); setVestingCont(!vestingCont) }}>
                        <i className={`fa-solid fa-square ${vestingCont ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                        <p className={`${Classes.radioHint}`}>Using Vesting Contributor?</p>
                      </button>

                      {vestingCont &&
                        <>
                          <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="vestingPeriod" className={`${Classes.inputLabel} roboto`}>Vesting Period Days</label><br />
                            <input type="number" id="vestingPeriod" name="vestingPeriod" value={vestingdays}
                              onChange={(e) => {
                                setVestingDays(e.target.value)
                                var rx = new RegExp(/^\d+$/);
                                if (!rx.test(e.target.value)) {
                                  const formvalue = { ...errors, ["vestingdays"]: "Invalid Vesting Period Days !" };
                                  setErrors(formvalue);
                                }
                                else {
                                  const formvalue = { ...errors, ["vestingdays"]: "" };
                                  setErrors(formvalue);
                                }
                                handledisable();
                              }}
                              className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.vestingdays && errors?.vestingdays}</span>
                          </div>

                          <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="vestingRewards" className={`${Classes.inputLabel} roboto`}>Rewards % per Vesting Period</label><br />
                            <input type="number" id="vestingRewards" name="vestingRewards" value={vestingPeriod}
                              onChange={(e) => {
                                if (!isFloat(e.target.value)) {
                                  setVestingPeriod(e.target.value)
                                  if (isNaN(e.target.value) || e.target.value < 0 || e.target.value > 100) {
                                    const formvalue = { ...errors, ["vestingPeriod"]: "Invalid Rewards % per Vesting Period !" };
                                    setErrors(formvalue);
                                  }
                                  else {
                                    const formvalue = { ...errors, ["vestingPeriod"]: "" };
                                    setErrors(formvalue);
                                  }
                                  handledisable();
                                }

                              }}
                              className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.vestingPeriod && errors?.vestingPeriod}</span>
                          </div>
                        </>
                      }

                    </>
                  }


                  {/* additional info */}
                  {steps === 3 &&
                    <>
                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="description" className={`${Classes.inputLabel} roboto`}>Description</label><br />
                        {/* <input type="text" id="description" name="description" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" /> */}
                        <textarea className={`${Classes.textArea} w-100 mt-2 p-2 roboto`} id="description" name="description"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value)
                            if (!e.target.value) {
                              const formvalue = { ...errors, ["description"]: "Invalid Description!" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["description"]: "" };
                              setErrors(formvalue);
                            }
                          }}
                          rows={3} cols={50} />
                        <span className={`cmnErrorHint`}>{errors?.description && errors?.description}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="logoUrl" className={`${Classes.inputLabel} roboto`}>Logo URL*</label><br />
                        <input type="text" id="logoUrl" name="logoUrl"
                          value={logo}
                          onChange={(e) => {
                            setLogo(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["logo"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["logo"]: "Invalid Logo Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
                        <span className={`cmnErrorHint`}>{errors?.logo && errors?.logo}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="bannerUrl" className={`${Classes.inputLabel} roboto`}>Banner URL*</label><br />
                        <input type="text" id="bannerUrl" name="bannerUrl" value={banner}
                          onChange={(e) => {
                            setBanner(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["banner"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["banner"]: "Invalid Banner Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
                        <span className={`cmnErrorHint`}>{errors?.banner && errors?.banner}</span>
                      </div>


                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="website" className={`${Classes.inputLabel} roboto`}>Website*</label><br />
                        <input type="text" id="website" name="website" value={website}
                          onChange={(e) => {
                            setWebsite(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["website"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["website"]: "Invalid Website Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.website && errors?.website}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="yVideo" className={`${Classes.inputLabel} roboto`}>Youtube Video</label><br />
                        <input type="text" id="yVideo" name="yVideo" value={youtube}
                          onChange={(e) => {
                            setYoutube(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["youtube"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["youtube"]: "Invalid Youtube Video Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>Input your youtube URL</p>
                        <span className={`cmnErrorHint`}>{errors?.youtube && errors?.youtube}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="twitter" className={`${Classes.inputLabel} roboto`}>Twitter</label><br />
                        <input type="text" id="twitter" name="twitter" value={twitter}
                          onChange={(e) => {
                            setTwitter(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["twitter"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["twitter"]: "Invalid Twitter Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.twitter && errors?.twitter}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="telegram" className={`${Classes.inputLabel} roboto`}>Telegram</label><br />
                        <input type="text" id="telegram" name="telegram" value={telegram}
                          onChange={(e) => {
                            setTelegram(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["telegram"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["telegram"]: "Invalid Telegram Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.telegram && errors?.telegram}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="discord" className={`${Classes.inputLabel} roboto`}>Discord</label><br />
                        <input type="text" id="discord" name="discord" value={discord}
                          onChange={(e) => {
                            setDiscord(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["discord"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["discord"]: "Invalid Discord Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.discord && errors?.discord}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="github" className={`${Classes.inputLabel} roboto`}>Github</label><br />
                        <input type="text" id="github" name="github" value={github}
                          onChange={(e) => {
                            setGithub(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["github"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["github"]: "Invalid Github Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.github && errors?.github}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="instagram" className={`${Classes.inputLabel} roboto`}>Instagram</label><br />
                        <input type="text" id="instagram" name="instagram" value={instagram}
                          onChange={(e) => {
                            setInstagram(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["instagram"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["instagram"]: "Invalid Instagram Url!" };
                              setErrors(formvalue);
                            }
                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.instagram && errors?.instagram}</span>
                      </div>

                      <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label for="reddit" className={`${Classes.inputLabel} roboto`}>Reddit</label><br />
                        <input type="text" id="reddit" name="reddit" value={reddit}
                          onChange={(e) => {
                            setReddit(e.target.value)
                            if (urlvalidation(e.target.value)) {
                              const formvalue = { ...errors, ["reddit"]: "" };
                              setErrors(formvalue);
                            }
                            else {
                              const formvalue = { ...errors, ["reddit"]: "Invalid Reddit Url!" };
                              setErrors(formvalue);
                            }

                          }}
                          className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
                        <span className={`cmnErrorHint`}>{errors?.reddit && errors?.reddit}</span>
                      </div>
                    </>
                  }
                  {/* end of additional info */}

                  {/* finish */}
                  {(steps >= 4) &&
                    <>

                      <div className={`pt-2`}>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Token Name :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{tokeninfo?.name ? tokeninfo?.name : ""}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Token Symbol :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{tokeninfo?.symbol ? tokeninfo?.symbol : ""}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Token Decimals :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{tokeninfo?.decimal ? tokeninfo?.decimal : ""}</p>
                        </div>
                        {/* <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                            <p className={`${Classes.flexText} m-0 roboto`}>Presale Rate :</p>
                            <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
                          </div> */}
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Sale Method :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{isWhitelisted === false ? "Public" : "Private"}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Softcap :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{softCap ? softCap : " "} {selectedCurrency}</p>
                        </div>
                        {/* <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                            <p className={`${Classes.flexText} m-0 roboto`}>Hardcap :</p>
                            <p className={`${Classes.flexText} m-0 roboto`}>OLS {Config.Currency}</p>
                          </div> */}
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Minimum Buy :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{minimumBuy ? minimumBuy : " "} {selectedCurrency}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Maximum Buy :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{maximumBuy ? maximumBuy : " "} {selectedCurrency}</p>
                        </div>

                        {islisting ?
                          <>

                            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                              <p className={`${Classes.flexText} m-0 roboto`}>Liquidity Percentage:</p>
                              <p className={`${Classes.flexText} m-0 roboto`}>{liquidityPercent ? liquidityPercent : " "}%</p>
                            </div>

                            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                              <p className={`${Classes.flexText} m-0 roboto`}>Liquidity Lockup Time  :</p>
                              <p className={`${Classes.flexText} m-0 roboto`}>{lockingdays ? lockingdays : " "} </p>
                            </div>

                          </> : <></>}



                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Start Time :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{startDate ? new Date(startDate).toLocaleDateString() + "  "
                            + new Date(startDate).getHours() + ":" + new Date(startDate).getMinutes() + ":"
                            + new Date(startDate).getSeconds() + "(GMT)" : ""}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>End Time :</p>
                          <p className={`${Classes.flexText} m-0 roboto`}>{endDate ? new Date(endDate).toLocaleDateString() + "  "
                            + new Date(endDate).getHours() + ":" + new Date(endDate).getMinutes() + ":"
                            + new Date(endDate).getSeconds() + "(GMT)" : ""}</p>
                        </div>
                        <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
                          <p className={`${Classes.flexText} m-0 roboto`}>Website :</p>
                          <p className={`${Classes.flexText} ${Classes.mainflow} m-0 roboto`}>
                            <NavLink className={`cmnNavLink`} target="_blank" to={website}>{website ? website : " "}</NavLink></p>
                        </div>

                        <div className={`${Classes.creationDisclaimer} p-2 d-flex justify-content-between align-items-center gap-3 mt-4`}>
                          <i className={`fa-solid fa-circle-exclamation ${Classes.redExcla}`} />
                          <p className={`${Classes.disclaimerHint} m-0 roboto text-center flex-grow-1`}>For tokens with burns, rebase or other special transfers please ensure that you have a way to whiteList multiple addresses or turn off the special transfer events (By setting fees to 0 for example for the duration of the presale)</p>
                        </div>
                      </div>
                    </>
                  }


                  {deposit > 0 && steps === 6 ?
                    <div className='col-12'>
                      <div className='note_desc mt-1 mb-1 text-center'>
                        <p className="text-white roboto mt-3">Need {deposit} {tokeninfo.name} to create launchpad.</p>
                      </div>
                    </div> : <></>}

                  {console.log(steps, 'stepppy')}
                  {/* end of finish */}


                  {steps !== 1 &&
                    <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>

                      <button className={`${Classes.violetBtn} px-5`} onClick={() => setSteps(newStep => newStep - 1)}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Back</p></button>

                      <button className={`${Classes.violetBtn} px-5`}
                        onClick={() => {
                          if (steps === 4) {
                            saleCreation()
                          }
                          else if (steps === 2) {
                            validations()
                          }
                          else if (steps === 3) {
                            let isvalid = socialMediaValidations()
                            console.log("socialMediaValidations", isvalid)
                            if (isEmpty(isvalid)) {
                              setSteps(4)
                            }
                          }
                          else if (steps === 5) {
                            calculateDepositTokens();
                          }
                          else if (steps === 6) {
                            depositToken()
                          }

                          else {
                            handleIncreateStep()
                          }
                        }}>
                        <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                          {steps < 4 ? 'Next' : steps === 4 ? iscreate ? 'Creating...' : 'Create' : steps === 5 ? 'Calculate' : steps === 6 ? isdeposit ? 'Depositing...' : 'Deposit' : 'Proceed to sale'}</p></button>
                    </div>
                  }

                </Col>
              </Row>

            </div>
          </div>

        }

        {/* <div className={`mt-5`}>
              <LaunchingTable />
            </div>
          </section> */}
        {/* </div> */}
      </AdminLayout>



      {/* modals */}

      <CalendarModal show={showCalendar} handleClose={handleCloseCalendar} setStartDate={setStartDate} startDate={startDate} />
      <Createtokenmodal show={createtokens} handleClose={handleCloseCreatetokens}
        setStokenAddress={(tokenAddress) => {
          setAddress(tokenAddress)
        }} />

    </>
  )
}


export default AdminCreateFairLaunch
