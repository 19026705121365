import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";
import Classes from '../../asset/styles/Createtoken.module.css';
import TokenCreate from './tokenmodal';



const CreatetokenModal = ({ show, handleClose, setStokenAddress }) => {





  return (
    <>
      <Modal
        size='md'
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >

        <Modal.Body className={`${Classes.bgfrontmodal}`}>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>


          <TokenCreate props="modal" handleClose={handleClose} setStokenAddress={setStokenAddress} />

        </Modal.Body>
      </Modal>



    </>
  )
}

export default CreatetokenModal