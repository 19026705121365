import React, { useEffect, useState } from 'react'
import AdminLayout from '../../../layout/AdminLayout'
import { Col, Row } from 'react-bootstrap'
import Classes from '../../../asset/styles/AdminEditPrivateSale.module.css'
import { isEmpty } from '../../../lib/isEmpty'
import { urlvalidation } from '../../../validations/userValidations'
import { useLocation, useNavigate } from 'react-router-dom'
import { edituserlaunchpad } from '../../../actions/userAction'
import toast from "react-hot-toast";


const AdminEditPrivateSale = () => {

  const [description, setDescription] = useState('')
  const [logo, setLogo] = useState('')
  const [banner, setBanner] = useState('')
  const [website, setWebsite] = useState('')
  const [youtube, setYoutube] = useState('')
  const [twitter, setTwitter] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [github, setGithub] = useState('')
  const [instagram, setInstagram] = useState('')
  const [reddit, setReddit] = useState('')
  const [errors, setErrors] = useState({})
  const [saleData, setSaleData] = useState({})
  const location = useLocation()
  console.log("location", location, location?.state)

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    getSaleData()
  }, [])



  const getSaleData = async () => {

    const saleInfo = location?.state
    console.log("saleInfosaleInfo", saleInfo);

    setSaleData(saleInfo)
    setDescription(saleInfo?.description)
    setLogo(saleInfo?.logo)
    setBanner(saleInfo?.banner)
    setWebsite(saleInfo?.website)
    setYoutube(saleInfo?.youtube)
    setTwitter(saleInfo?.twitter)
    setTelegram(saleInfo?.telegram)
    setDiscord(saleInfo?.discord)
    setGithub(saleInfo?.github)
    setInstagram(saleInfo?.instagram)
    setReddit(saleInfo?.reddit)

  }

  const socialMediaValidations = () => {
    var errors = {}

    if (isEmpty(description)) {
      errors.description = "Description field is required"
    }

    if (isEmpty(banner)) {
      errors.banner = "Banner field is required"
    }

    if (isEmpty(twitter)) {
      errors.twitter = "Twitter field is required"
    }

    if (isEmpty(github)) {
      errors.github = "Github field is required"
    }

    if (isEmpty(telegram)) {
      errors.telegram = "Telegram field is required"
    }

    if (isEmpty(instagram)) {
      errors.instagram = "Instagram field is required"
    }

    if (isEmpty(discord)) {
      errors.discord = "Discord field is required"
    }

    if (isEmpty(reddit)) {
      errors.reddit = "Reddit field is required"
    }

    if (isEmpty(youtube)) {
      errors.youtube = "Youtube field is required"
    }

    if (isEmpty(website)) {
      errors.website = "Website field is required"
    }

    if (isEmpty(logo)) {
      errors.logo = "Logo field is required"
    }

    setErrors(errors)
    return errors
  }



  const UpdateSocialInfo = async () => {

    var data = {
      saleAddress: saleData?.saleAddress,
      description: description,
      banner: banner,
      website: website,
      twitter: twitter,
      logo: logo,
      telegram: telegram,
      githup: github,
      instagram: instagram,
      discord: discord,
      reddit: reddit,
      youtube: youtube
    }
    console.log("data")
    var update = await edituserlaunchpad(data)
    console.log("update", update)
    if (update?.data?.status == true) {
      toast.success("Updated Successfully");
      navigate(`/admin-privatesale-detail/${saleData?.saleAddress}`)

      // navigate(`/private-sale-detail/${saleData?.saleAddress}`)
    }
  }
  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <Row className={`mx-auto ${Classes.createToken__emptyRow}`}>
            <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
              <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>Edit Launchpad</h2>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="logoURL" className={`${Classes.inputLabel} orbitron`}>Logo URL</label><br />
                  <input type="text" id="logoURL" name="logoURL" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={logo}
                    onChange={(e) => {
                      setLogo(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["logo"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["logo"]: "Invalid Logo Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>URL must be end with a supported image extension .png .jpg</p>
                  <span className={`cmnErrorHint`}>{errors?.logo && errors?.logo}</span>

                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="bannerURL" className={`${Classes.inputLabel} orbitron`}>Banner URL </label><br />
                  <input type="text" id="bannerURL" name="bannerURL" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={banner}
                    onChange={(e) => {
                      setBanner(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["banner"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["banner"]: "Invalid Banner Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>URL must be end with a supported image extension .png .jpg</p>
                  <span className={`cmnErrorHint`}>{errors?.banner && errors?.banner}</span>

                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="website" className={`${Classes.inputLabel} orbitron`}>Website</label><br />
                  <input type="text" id="website" name="website" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={website}
                    onChange={(e) => {
                      setWebsite(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["website"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["website"]: "Invalid Website Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <span className={`cmnErrorHint`}>{errors?.website && errors?.website}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="twitter" className={`${Classes.inputLabel} orbitron`}>Twitter</label><br />
                  <input type="text" id="twitter" name="twitter" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={twitter}
                    onChange={(e) => {
                      setTwitter(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["twitter"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["twitter"]: "Invalid Twitter Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <span className={`cmnErrorHint`}>{errors?.twitter && errors?.twitter}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="telegram" className={`${Classes.inputLabel} orbitron`}>Telegram</label><br />
                  <input type="text" id="telegram" name="telegram" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={telegram}
                    onChange={(e) => {
                      setTelegram(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["telegram"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["telegram"]: "Invalid Telegram Url!" };
                        setErrors(formvalue);
                      }

                    }} />
                  <span className={`cmnErrorHint`}>{errors?.telegram && errors?.telegram}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="github" className={`${Classes.inputLabel} orbitron`}>Github</label><br />
                  <input type="text" id="github" name="github" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={github}
                    onChange={(e) => {
                      setGithub(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["github"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["github"]: "Invalid Github Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <span className={`cmnErrorHint`}>{errors?.github && errors?.github}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="instagram" className={`${Classes.inputLabel} orbitron`}>Instagram</label><br />
                  <input type="text" id="instagram" name="instagram" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={instagram}
                    onChange={(e) => {
                      setInstagram(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["instagram"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["instagram"]: "Invalid Instagram Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <span className={`cmnErrorHint`}>{errors?.instagram && errors?.instagram}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="discord" className={`${Classes.inputLabel} orbitron`}>Discord</label><br />
                  <input type="text" id="discord" name="discord" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={discord}
                    onChange={(e) => {
                      setDiscord(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["discord"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["discord"]: "Invalid Discord Url!" };
                        setErrors(formvalue);
                      }
                    }} />
                  <span className={`cmnErrorHint`}>{errors?.discord && errors?.discord}</span>

                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="reddit" className={`${Classes.inputLabel} orbitron`}>Reddit</label><br />
                  <input type="text" id="reddit" name="reddit" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    autoComplete="off" value={reddit}
                    onChange={(e) => {
                      setReddit(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["reddit"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["reddit"]: "Invalid Reddit Url!" };
                        setErrors(formvalue);
                      }

                    }} />
                  <span className={`cmnErrorHint`}>{errors?.reddit && errors?.reddit}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="youtube" className={`${Classes.inputLabel} orbitron`}>Youtube Video</label><br />
                  <input type="text" id="youtube" name="youtube" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    value={youtube}
                    onChange={(e) => {
                      setYoutube(e.target.value)
                      if (urlvalidation(e.target.value)) {
                        const formvalue = { ...errors, ["youtube"]: "" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["youtube"]: "Invalid Youtube Video Url!" };
                        setErrors(formvalue);
                      }
                    }}

                    autoComplete="off"
                  />
                  <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>Input your youtube URL</p>
                  <span className={`cmnErrorHint`}>{errors?.youtube && errors?.youtube}</span>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="description" className={`${Classes.inputLabel} orbitron`}>Description</label><br />
                  <textarea className={`${Classes.textArea} w-100 mt-2 p-2 roboto`} id="description" name="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                      if (!e.target.value) {
                        const formvalue = { ...errors, ["description"]: "Invalid Description!" };
                        setErrors(formvalue);
                      }
                      else {
                        const formvalue = { ...errors, ["description"]: "" };
                        setErrors(formvalue);
                      }
                    }}
                    rows={3} cols={50} />
                  <span className={`cmnErrorHint`}>{errors?.description && errors?.description}</span>

                </div>

                <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
                  <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4 px-lg-5`} onClick={() => { navigate(`/launchpad-detail/${saleData?.saleAddress}`) }}>
                    <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Back</p></button>

                  <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4 px-lg-5`} onClick={() => {
                    let isvalid = socialMediaValidations()
                    console.log("socialMediaValidations", isvalid)
                    if (isEmpty(isvalid)) {
                      UpdateSocialInfo();
                    }
                  }}>
                    <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Update</p></button>

                </div>
              </div>
            </Col>
          </Row>
        </div>
      </AdminLayout>
    </>
  )
}

export default AdminEditPrivateSale
