import React, { useContext, useState, useEffect } from 'react'
import Classes from '../asset/styles/AdminSidebar.module.css'
import { Images } from '../data/Images'
import { NavLink } from 'react-router-dom'
import { sidebarContext } from '../Context/Context'


const AdminSidebar = (props,) => {
    const { handleChange, sidebar } = useContext(sidebarContext)
    const sidebarLinks = [
        {
            name: 'Launchpad',
            img: Images.admin_launchpad,
            linkTo: '/launchpad-admin'
        },
        {
            name: 'Create Launchpad',
            img: Images.admin_createLaunchpad,
            linkTo: '/admin-create-launchpad'
        },
        {
            name: 'Create FairLaunch',
            img: Images.admin_fairlaunch,
            linkTo: '/admin-create-fairlaunch'
        },
        {
            name: 'Create Privatesale',
            img: Images.admin_privatesale,
            linkTo: '/admin-create-privatesale'
        },
        {
            name: 'KYC',
            img: Images.admin_kyc,
            linkTo: '/admin-kyc'
        },
        {
            name: 'Settings',
            img: Images.admin_settings,
            linkTo: '/admin-settings'
        },
        {
            name: 'Launchpad Settings',
            img: Images.admin_launchSettins,
            linkTo: '/admin-launchpad-settings'
        },
        {
            name: 'Audit/KYC',
            img: Images.admin_audit,
            linkTo: '/admin-audit'
        },
        {
            name: 'Profile Settings',
            img: Images.admin_profileSettings,
            linkTo: '/admin-profile-settings'
        },
        {
            name: 'Fee Settings',
            img: Images.admin_feeSettings,
            linkTo: '/admin-fee-settings'
        },
        {
            name: 'Admin Launchpad',
            img: Images.admin_launchpadList,
            linkTo: '/admin-created-launchpad-list'
        },
        {
            name: 'CMS',
            img: Images.admin_cms,
            linkTo: '/admin-CMS'
        },



    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Function to update window width
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            <div className={`${sidebar ? Classes.adminSideBar : Classes.adminSideBarShrink}`}>
                <div className='d-flex justify-content-center'>
                    <img src={Images.logo} className={`${Classes.logo} img-fluid d-flex d-sm-none`} alt='logo' />
                </div>
                <ul className={`${Classes.sidebarUl} pt-3`}>
                    {sidebarLinks?.map((item) =>
                        <li className={`${Classes.sidebarLi} mb-2 py-2 ${sidebar ? `px-3` : `px-2`} pointer`}>
                            {windowWidth > 1199 ?
                                <NavLink to={item.linkTo} className={`cmnNavLink sidebarNavLink`}  >
                                    <div className={`d-flex justify-content-start align-items-center gap-2`}>
                                        <img src={item.img} className={`${Classes.sidebarLogos} img-fluid`} alt='' />
                                        {sidebar &&
                                            <p className={`${Classes.sidebarHint} poppins`}>{item.name}</p>
                                        }
                                    </div>
                                </NavLink> :
                                <NavLink to={item.linkTo} className={`cmnNavLink sidebarNavLink`} onClick={handleChange}>
                                    <div className={`d-flex justify-content-start align-items-center gap-2`}>
                                        <img src={item.img} className={`${Classes.sidebarLogos} img-fluid`} alt='' />
                                        {sidebar &&
                                            <p className={`${Classes.sidebarHint} poppins`}>{item.name}</p>
                                        }
                                    </div>
                                </NavLink>}
                        </li>
                    )}
                </ul>
            </div>

        </>
    )
}

export default AdminSidebar
