import toast, { Toaster } from 'react-hot-toast';


export const isFloat = (x) => {
    try {
        if (!!(x % 1)) {
            toast.error(`Decimal Values are not accepted !`)
        }
        return !!(x % 1);
    }
    catch (err) {
        console.log("isFloat", err)
    }
}