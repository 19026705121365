import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

// import action
import {
  verifyPasswordEmail,
  verifyChangeAdminEmail,
  sentVerifyLink
} from "../../actions/adminAction";

const EmailVerification = () => {
  const { authToken } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [page, setPage] = useState("loading");

  // function
  const emailActivation = async () => {
    const { status, message } = await verifyPasswordEmail({
      token: authToken,
    });
    if (status == true) {
      navigate("/reset-password", { state: { _id: authToken } });
      toast.success(message);
    } else if (status == false) {
      navigate("/forgot-password");
      toast.error(message);
    } else {
      setPage("error");
    }
  };

  const verifyEmail = async () => {
    const { status, message } = await verifyChangeAdminEmail({
      token: authToken,
    });
    if (status == true) {
      navigate("/admin-profile-settings");
      toast.success(message);
    } else if (status == false) {
      navigate("/admin-profile-settings");
      toast.error(message);
    } else {
      setPage("error");
    }
  }; const verifNewEmail = async () => {
    console.log("newEmail")
    const newEmail = await sentVerifyLink(authToken);
    console.log("newEmail",newEmail)
    if (newEmail?.data?.data?.status) {
      navigate("/login");
        toast.success(newEmail?.data?.data?.message);
    } else {
        setPage('error');
        navigate("/login");
    }
}

  useEffect(() => {
    let pathname = location.pathname;

    if (pathname == "/email-verification/" + authToken) {
      emailActivation();
    }
    if (pathname == "/verify-old-email/" + authToken) {
      verifyEmail();
    }
    if(pathname == "/verify-new-email/" + authToken){
      verifNewEmail()
    }
  }, [authToken]);

  return (
    <>
      {page == "loading" && <p>{t("LOADING")}</p>}
      {page == "error" && <p>{t("INVALID_URL")}</p>}
    </>
  );
};

export default EmailVerification;
