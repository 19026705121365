import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PublicKey } from "@solana/web3.js";

let key = {};
let env = "demo";

export const API_URL = "http://localhost:8001";
// export const API_URL = "";
// export const API_URL = "";

if (env === "production") {
  console.log("Set production Config");
  const API_URL = "";
  key = {
    CRYPTO_SECRET_KEY: "1234567812345678",
    API_URL: API_URL,
    FRONT_URL: "",
    ADMIN_URL: "",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else if (env === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://backend-solmax.maticz.in";
  key = {
    CRYPTO_SECRET_KEY: "1234567812345678",
    API_URL: API_URL,
    FRONT_URL: "https://solmax.maticz.in",
    getGeoInfo: "https://ipapi.co/json/",
    NETWORK: WalletAdapterNetwork.Devnet,
    NETWORK_URL: "https://api.devnet.solana.com/",
    LAUNCH_PROGRAM_ID: "6cghja4cRBMdVkf1kicZUiuVJTBmC4XkkAbmNpLnH2uj",//"8oRAfwNP85StwQQ4BhHizzru9GwXXi3vzccFbRUyjoN3",//"Aznop3BSzGi4PEgpvju42cpqZGksyLuJxMECfLAtv1Pd", //"CJxbrFLgYmJGS7YZPrS7d135J53myVNMynVdfjKDzTgS",//"9sGUj8RNjJUEDqjajDsaw7NQ1biyoF88JdsnH9xFohUz",
    SPLTOKEN_PROGRAM_ID: "3aSK5iQCt6bocAAZStNuk4adMj3WznYwFU187fZgBbfS",
    LOCKTOKEN_PROGRAM_ID: "4vNduTkBTsyvFBY827RfoV5LR74jPvhXBhU42rsxRAqi",
    IPFS_JWT_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkODQwOTNhZC04YjcxLTQ5MDQtYjQzMi00ZWFkMjliZTFjN2YiLCJlbWFpbCI6InByaXlhZGhhcnNoaW5pbWF0aWN6QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxLCJpZCI6IkZSQTEifSx7ImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxLCJpZCI6Ik5ZQzEifV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiIwZWVlMTJlZDYyY2E5MTQ5YzA4MCIsInNjb3BlZEtleVNlY3JldCI6ImY0MjEyNWJjZmQwMzdhNWVjMDc1MWEzNjRlZDY4ZDBhNTI1ZmUxOGU3ODBiODM3NWVjNGI5OGM3ZTVmNmM5NDEiLCJleHAiOjE3NTk1NjM1NjV9.rhBAmq8hEvJWhzUQyqi24Eq_uGAhaMyssyjbP61j-jQ",
    PINATA_ENDPOINT: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    Currency: "SOL",
    USDC: "7DWDyYs2pQLTMp6rWgzUD3NG17NfJstGD4naVvfD2YR4",
    USDT: "3bXSUFfc1AcM4WcJV6cabLJ8KeQ33g8cFsCZDe6JmzoY",
    DAI: "7yjKnii1ZfeNwYzAfoc1mnacu3QfMtw76MMHbuB7okk2",
    SOL: "11111111111111111111111111111111",
    ThreeMonthsInSeconds: 7884000.000000,
    SixMonthsInSeconds: 15770000.0000000,
    TwelveMonthsInSeconds: 31540000.0000000,
    deploymentFee: 1,
    fundReciever: new PublicKey("DPdfsEU3cTyCjfy9pLXHmio5VwTxajQ9p8oXx3x2xMeJ")

  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    CRYPTO_SECRET_KEY: "1234567812345678",
    API_URL: `${API_URL}:8001`,
    FRONT_URL: "http://localhost:3000",
    getGeoInfo: "https://ipapi.co/json/",
    NETWORK: WalletAdapterNetwork.Devnet,
    NETWORK_URL: "https://api.devnet.solana.com/",
    LAUNCH_PROGRAM_ID: "6cghja4cRBMdVkf1kicZUiuVJTBmC4XkkAbmNpLnH2uj",//"8oRAfwNP85StwQQ4BhHizzru9GwXXi3vzccFbRUyjoN3",//"Aznop3BSzGi4PEgpvju42cpqZGksyLuJxMECfLAtv1Pd",//"CJxbrFLgYmJGS7YZPrS7d135J53myVNMynVdfjKDzTgS",//"9sGUj8RNjJUEDqjajDsaw7NQ1biyoF88JdsnH9xFohUz",
    SPLTOKEN_PROGRAM_ID: "AXi1onoGmHwvP4XD4hLbMoKEKm8KCD8r7FBwxjJzGpw5",
    LOCKTOKEN_PROGRAM_ID: "4vNduTkBTsyvFBY827RfoV5LR74jPvhXBhU42rsxRAqi",
    IPFS_JWT_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkODQwOTNhZC04YjcxLTQ5MDQtYjQzMi00ZWFkMjliZTFjN2YiLCJlbWFpbCI6InByaXlhZGhhcnNoaW5pbWF0aWN6QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxLCJpZCI6IkZSQTEifSx7ImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxLCJpZCI6Ik5ZQzEifV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiIwZWVlMTJlZDYyY2E5MTQ5YzA4MCIsInNjb3BlZEtleVNlY3JldCI6ImY0MjEyNWJjZmQwMzdhNWVjMDc1MWEzNjRlZDY4ZDBhNTI1ZmUxOGU3ODBiODM3NWVjNGI5OGM3ZTVmNmM5NDEiLCJleHAiOjE3NTk1NjM1NjV9.rhBAmq8hEvJWhzUQyqi24Eq_uGAhaMyssyjbP61j-jQ",
    PINATA_ENDPOINT: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    Currency: "SOL",
    USDC: "7DWDyYs2pQLTMp6rWgzUD3NG17NfJstGD4naVvfD2YR4",
    USDT: "3bXSUFfc1AcM4WcJV6cabLJ8KeQ33g8cFsCZDe6JmzoY",
    DAI: "7yjKnii1ZfeNwYzAfoc1mnacu3QfMtw76MMHbuB7okk2",
    SOL: "11111111111111111111111111111111",
    ThreeMonthsInSeconds: 7884000.000000,
    SixMonthsInSeconds: 15770000.0000000,
    TwelveMonthsInSeconds: 31540000.0000000,
    deploymentFee: 1,
    fundReciever: new PublicKey("DPdfsEU3cTyCjfy9pLXHmio5VwTxajQ9p8oXx3x2xMeJ")
  };
}

export default key;
