import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Classes from "../asset/styles/AddUserWhitelist.module.css";
import { IoMdClose } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";
import { addwhitelistvalidation } from "../validations/userValidations";
import { GetWhiteListUser, useaddWhiteListUser } from "../actions/userAction";
import toast from 'react-hot-toast';
import Papa from "papaparse";



const AddUserWhitelist = ({ show, handleClose, presaleAddress, handleShowCSV }) => {


  const init = {
    user: "",
    bnbvalue: "",
    buyAmount: 0
  };
  const [newAddress, setNewAddress] = useState("");
  const [formData, setFormData] = useState([init]);
  const [count, setcount] = useState(1)
  const [isPending, setisPending] = useState(false)
  const [errors, setErrors] = useState([])
  const [filename, setfilename] = useState("")
  const [fileuser, setfileuser] = useState([])
  const [fileamount, setfileamount] = useState([])

  // console.log("errorserrors", errors);


  const handleAddUserSubmit = async () => {
    console.log("formdata", formData);

    setisPending(true);
    setErrors([])
    let valid;
    let errors = []
    if (filename == "") {
      for (var i = 0; i < formData.length; i++) {
        var val = formData[i]
        console.log("valid", formData[i], formData.length);
        valid = await addwhitelistvalidation(val);
        if (!valid.isValid) {
          errors.push(valid?.errors)
          setErrors([...errors, errors])
        } else {
          setErrors([])

        }
      }
      console.log("errors.length ", valid?.errors, errors);

      if (valid?.isValid) {
        console.log("handleAddUserSubmit", errors);

        let data = {
          presaleAddress: presaleAddress,
          whiteListData: formData
        }
        let response = await useaddWhiteListUser(data);
        console.log("response>>>>", response);
        if (response?.data?.status) {
          setfilename("")
          toast.success(`${response?.data?.result}`)
          setFormData([{}])
          await newHandleClose()
          setErrors([])
        } else {

          toast.error(`${response?.data?.result}`)

        }


      }
    } else {
      for (var i = 0; i < formData.length; i++) {
        var val = formData[i]
        console.log("valid", formData[i], formData.length);
        valid = await addwhitelistvalidation(val);
        if (!valid.isValid) {
          errors.push(valid?.errors)
          setErrors(errors)
        }
      }
      console.log("errors.length ", valid?.errors, errors);

      if (valid?.isValid) {
        console.log("handleAddUserSubmit", errors);

        let data = {
          presaleAddress: presaleAddress,
          whiteListData: formData
        }
        let response = await useaddWhiteListUser(data);
        console.log("response>>>>", response);
        if (response?.data?.status) {
          setfilename("")

          toast.success(`${response?.data?.result}`)
          setFormData([{}])
          await newHandleClose()
          setErrors([])
          // window.location.reload()
        } else {
          toast.error(`${response?.data?.result}`)

        }


      }

    }


  }
  const handleRemoveUser = (index) => {


    const list = [...formData];
    list.splice(index, 1);
    setFormData(list);
  };

  const handleAddClick = () => {
    setFormData([...formData, { user: "", bnbvalue: "" }]);
  };

  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;
    console.log('formdatadet', formData)
    const list = [...formData];
    list[index][name] = value;
    console.log(list, "sadfasdfsafd");

  };




  const changeCSVHandler = async (event) => {
    let splitFile = event.target.files[0].name.split(".");

    if (splitFile[splitFile.length - 1] != "csv") {
      toast.error('Select your csv file !');
      return false
    } else {
      setfilename(event.target.files[0].name)
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          console.log("result", results);
          var userList = [];
          var bnbvalues = [];
          let InputList = []
          await results?.data?.map(async (m, i) => {
            console.log("m", m, m.bnbvalues);
            var keys = Object.values(m);
            console.log("keys", keys);
            let dataValid = {
              user: Object.values(m)[0],
              bnbvalue: Object.values(m)[1],

            }
            let data = {
              user: Object.values(m)[0],
              bnbvalue: Object.values(m)[1],
              buyAmount: 0

            }
            InputList.push(data)
            let valid = await addwhitelistvalidation(dataValid);
            console.log("validate", valid)
            if (!valid.isValid) {

              setErrors("Invalid CSV file")

            }
            else {
              setErrors("")
            }
            userList.push(Object.values(m)[0])
            bnbvalues.push(Object.values(m)[1])
          })

          console.log("user>>>>>>>>>>>>>>>>>>", userList, bnbvalues);

          setFormData(InputList)
          // setfilename("")


        },
      });
    }

  }

  const newHandleClose = async () => {
    setErrors([])
    await handleClose()

  }

  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={newHandleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={newHandleClose}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}
          >
            Add Whitelist Users
          </h5>

          <p className={`${Classes.robotoHint} m-0 poppins mt-4`}>
            Notes*: Enter details by manually or by uploading csv file.
          </p>

          {[...Array(count)].map((item, index) => (
            <div
              className={`${Classes.singleInpHolder} d-flex justify-content-start align-items-start mt-4 gap-2`}
            >
              {/* {console.log("qeqweqw errorserrors", index, errors, errors[index], errors[index]?.user)} */}
              <div>
                <input
                  type="text"
                  id="user"
                  name="user"
                  // value={formData?.walletAddress}
                  className={`${Classes.inputBox} inter_font w-100 p-2`}
                  placeholder="Wallet Address"
                  autoComplete="off"
                  onChange={e => handleInputChange(e, index)}

                />
                <span className="text-danger f-12 d-block text-left">{errors && errors[index]?.user}</span>
              </div>

              <div>
                <input
                  type="text"
                  id="bnbvalue"
                  name="bnbvalue"
                  // value={formData?.allocatedValue}
                  className={`${Classes.inputBox} inter_font w-100 p-2`}
                  placeholder="Allocated LineaSOL value"
                  autoComplete="off"
                  onChange={e => handleInputChange(e, index)}

                />

                {/* {console.log("errors[index]?.bnbvalue", errors[index]?.bnbvalue)} */}

                <span className="text-danger f-12 d-block text-left">{errors && errors[index]?.bnbvalue}</span>
              </div>
              <div
                className={`${Classes.dualBtnsHolder} d-flex justify-content-end align-items-center gap-1`}
              >
                {formData?.length != 1 && (
                  <button
                    className={`${Classes.optionWhiteBtn}`}
                    onClick={() => {
                      setcount(count - 1);
                      handleRemoveUser();
                    }}
                  >
                    <FaTrashCan />
                  </button>
                )}
                {count >= 1 &&
                  <button
                    className={`${Classes.optionWhiteBtn}`}
                    onClick={() => {
                      setcount(count + 1);
                      handleAddClick();
                    }}                >
                    <HiUserAdd />
                  </button>}
              </div>
            </div>
          ))}

          <div className={`${Classes.fileUploaderHolder} d-flex justify-content-between align-items-center px-2 py-1 mt-4`}>
            <p className={`${Classes.fileInpHint} poppins m-0`}>{filename && filename ? filename : ""}</p>
            <button className={`${Classes.gradientBtn} border-0 outline-0 bg-transparent poppins py-1 px-2 position-relative pointer`}>
              <input type="file" className={`${Classes.fileUploader}`}
                onChange={async (e) => {
                  var result = changeCSVHandler(e);
                  // setfilename(e?.target?.files[0]?.name)
                }} />
              {"Browse"}</button>
          </div>
          <div className={`d-flex justify-content-end align-items-center`}>
            <p className={`${Classes.robotoHint} m-0 poppins mt-3 pointer`} onClick={() => { newHandleClose(); handleShowCSV(); }} >Sample CSV file</p>
          </div>
          <div className={`text-center w-100 mt-4`}>
            <button
              className={`${Classes.whiteBtn} poppins`}
              // onClick={handleClose}
              onClick={() => handleAddUserSubmit()}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default AddUserWhitelist;
