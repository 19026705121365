import React, { useState, useEffect } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminAuditList.module.css'
import { commonData } from '../../data/CommonData'
import Pagination from '../../components/Pagination'
import AdminEditAuditModal from '../../Modals/AdminEditAuditModal'
import { useNavigate } from 'react-router-dom'
import Usesale from '../../hooks/useSale'
import { getallsalehook } from '../../actions/userAction'

const AdminAuditList = () => {
  // modal state
  const [editAudit, setEditAudit] = useState(false)
  const handleShowEditAudit = () => setEditAudit(true)
  const handleCloseEditAudit = () => setEditAudit(false)
  const navigate = useNavigate()
  const saleHook = Usesale()

  const [saleData, setSaleData] = useState({})
  const [saleDBData, setSaleDBData] = useState({})
  const [interval, setInterval] = useState(1)
  const [loadInterval, setLoadInterval] = useState(3)
  const [loadData, setLoadData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [editRecord, seteditRecord] = useState();





  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_admin", TotalData)
    setSaleData(TotalData)
    let getSale = [...TotalData]
    let limit = [...getSale].splice(0, loadInterval)
    console.log("getSale_admin2", getSale, limit, getSale.splice(interval, loadInterval))
    setLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb_admin", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)
  }

  useEffect(() => {
    getSaleInfo()
  }, [])



  const handleShowAUdit = (item) => {
    setEditAudit(true);
    seteditRecord(item);
  };

  const loadMore = async (interval) => {
    console.log("Load_moreee", saleData, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...saleData];
    const limit = newly.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setLoadData([...loadData, ...limit])
  }

  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Audit/KYC List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            {saleDBData && loadData?.length > 0 ?

              <table className={`${Classes.tokenCmnTable} mt-5`}>
                <thead>
                  <tr>
                    {/* <th className="pb-3">S.No</th> */}
                    <th className="pb-3">Sale Address</th>
                    <th className="pb-3">Status</th>
                    <th className="pb-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {saleDBData && loadData?.map((value, index) => {
                    console.log("value_value", value, value.account)
                    let saleInfo = value.account
                    let DBData = {}
                    if (saleDBData.length > 0) {
                      DBData = saleDBData?.find((val) => (val?.saleAddress == value?.publicKey?.toString()))
                      console.log("DBData>>>>>>>>>>>>", loadData)
                    }
                    return (
                      <>
                        <tr className={`${Classes.gradientBorder}`}>


                          <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                            >
                              {DBData?.saleAddress}
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                            >
                              {DBData?.status == "" ? "No status" :
                                DBData?.status == "Active" ? "Active" : "Inactive"}
                            </p>
                          </td>
                          <td className={`textend`}>
                            <button className={`${Classes.kycViewButton} border-0 outline-0 p-0 pointer position-relative`}
                              onClick={() => { handleShowAUdit(DBData) }}>Edit</button>
                          </td>
                        </tr>


                      </>
                    )
                  })}
                </tbody>
              </table> :
              <p className="text-white text-center roboto mt-5">No Data Found</p>
            }
          </div>
          {saleData?.length > loadData?.length ?
            <div className={`text-center w-100 mt-5`}>
              <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4`} onClick={() => { loadMore(interval + 1) }}>
                <p
                  className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
                >
                  {isLoading ? 'View More Pools...' : 'View More Pools'}
                </p>
              </button>
            </div>
            : <div> </div>
          }          </div>
      </AdminLayout>

      {/* modal content */}
      <AdminEditAuditModal show={editAudit}
        record={editRecord}
        handleClose={handleCloseEditAudit} />
      {/* end of modal content */}
    </>
  )
}

export default AdminAuditList
