import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Classes from '../asset/styles/RemoveWhiteListUser.module.css'
import { IoMdClose } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";
import { RemoveWhiteListUser } from '../actions/userAction';
import toast from 'react-hot-toast';
import { removewhitelistvalidation } from '../validations/userValidations';

const RemoveWhtieListUser = ({ show, handleClose, presaleAddress }) => {
  const [walletAddress, setWalletAddress] = useState("")
  const [errors, setErrors] = useState('')


  const removeUser = async () => {
    console.log("removeUserresponse");

    let data = {
      saleAddress: presaleAddress,
      walletAddress: walletAddress,

    }
    let validate = await removewhitelistvalidation(data);
    console.log("removeUserresponse", validate);

    if (validate?.isValid) {

      let removeUserresponse = await RemoveWhiteListUser(data)
      console.log("removeUserresponse", removeUserresponse);

      if (removeUserresponse?.data?.status) {
        toast.success(`${removeUserresponse?.data?.result}`)
        handleClose()
      } else {
        toast.error(`${removeUserresponse?.data?.result}`)
      }
    } else {
      setErrors(validate.errors)

    }



  }


  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}
          >
            Remove Whitelist Users
          </h5>

          <div
            className={`${Classes.singleInpHolder} d-flex justify-content-start align-items-center mt-4 gap-2`}
          >
            <input
              type="text"
              id="walletAddress"
              name="walletAddress"
              className={`${Classes.inputBox} inter_font w-100 p-2`}
              placeholder="Wallet Address"
              autoComplete="off"
              onChange={(e) => setWalletAddress(e?.target?.value)}
            />
            <span className="text-danger f-12 d-block text-left">{errors?.saleAddress}</span>

            <div className={`${Classes.dualBtnsHolder} d-flex justify-content-end align-items-center gap-1`}>
              {/* <button
                  className={`${Classes.optionWhiteBtn}`}
                >
                  <FaTrashCan />
                </button> */}
              {/* <button
                  className={`${Classes.optionWhiteBtn}`}
                >
                  <HiUserAdd />
                  </button> */}
            </div>
          </div>

          <div className={`text-center w-100 mt-4`}>
            <button
              className={`${Classes.whiteBtn} poppins`}

              onClick={() => removeUser()}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>


    </>
  )
}

export default RemoveWhtieListUser
