import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import Classes from '../../asset/styles/tokenCreation.module.css'
import { Images } from '../../data/Images'
import BalanceDetails from '../../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select';
import { validstandardtoken, validliquiditytoken } from '../../validations/userValidations'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { isEmpty } from '../../lib/isEmpty'
import toast, { Toaster } from 'react-hot-toast';
import CopyToClipboard from "react-copy-to-clipboard";
import Usetoken from '../../hooks/useToken'
import { createToken } from '../../actions/userAction'

const TokenCreate = ({ props, handleClose, setStokenAddress }) => {

    const tokenHook = Usetoken()

    const options = [
        { value: 'Standard Token', label: 'Standard Token' },
        { value: 'Liquidity Generator Token', label: 'Liquidity Generator Token' },
    ];
    const [selectedToken, setSelectedToken] = useState(null)
    const [tokentype, setTokentype] = useState("Standard Token")
    const [name, setName] = useState("")
    const [symbol, setSymbol] = useState("")
    const [decimal, setDecimal] = useState("")
    const [totalsupply, setTotalsupply] = useState("")
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("")
    const [yieldfee, setYieldfee] = useState("")
    const [liquidityfee, setLiquidityfee] = useState("")
    const [maximumtransaction, setMaximumtransaction] = useState("")
    const [exchangethresold, setExchangethresold] = useState("")
    const [errors, setErrors] = useState({})
    const [tokenData, setTokenData] = useState({})
    const [tokenAddress, setTokenAddress] = useState("cghcgh")
    const [iscreate, setIscreate] = useState(false)

    const walletdetail = useSelector((state) => state.wallet)
    console.log("walletdetail_TokenCreation", walletdetail, props)



    const handlecreatetoken = async () => {
        
        let payload = {
            name: name,
            symbol: symbol,
            decimal: decimal,
            totalSupply: totalsupply,
            image: image,
            description: description,
        }
        let validate = validstandardtoken(payload);
        console.log("validate", validate)
        setErrors(validate?.errors)
        if (validate?.isValid) {
            setIscreate(true)
            let UriData = {
                name: name,
                symbol: symbol,
                image: image,
                description: description
            }
            let Uri = await tokenHook.generateTokenUri(UriData)
            console.log("generateUri", Uri)
            if (Uri) {
                const params = {
                    name: name,
                    symbol: symbol,
                    uri: `https://azure-worthwhile-scorpion-613.mypinata.cloud/ipfs/${Uri}`,
                    decimal: Number(decimal),
                    totalSupply: Number(totalsupply) * 10 ** Number(decimal),//`${totalsupply}${Array(Number(decimal)).fill("0").join("")}`,
                    tokenOwner: walletdetail?.walletaddress

                };
                console.log("params", params)
                let creation = await tokenHook.UseTokenMint_new(params)
                // createToken(params)
                console.log("creation", creation, creation.mintAddress)
                setIscreate(false)
                if(creation?.status == true){
                    toast.success('Token created Successfully')
                }
                else{
                    toast.error('Token not created')
                }
                // toast.success(creation?.status == true ? `Token created Successfully` : `Token not created`)
                let data = {
                    name: name,
                    symbol: symbol,
                    uri: `https://azure-worthwhile-scorpion-613.mypinata.cloud/ipfs/${Uri}`,
                    decimal: Number(decimal),
                    totalSupply: Number(totalsupply) * 10 ** Number(decimal),//`${totalsupply}${Array(Number(decimal)).fill("0").join("")}`,
                    tokenOwner: walletdetail?.walletaddress
                }
                setTokenData(data)
                setTokenAddress(creation?.mintAddress)
                // sessionStorage.setItem('TokenAddress',creation?.result)
                if (props == 'modal') {
                    handleClose()
                    setStokenAddress(creation?.mintAddress.trim())
                }

            }
            else {
                toast.error("Something went wrong")
            }
        }
    }


    const copyText = () => {
        toast.success("Address Copied")
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("tokentype", tokentype)
    }, [])

    return (
        <>

            <div
            // className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}
            >

                {/* token success */}
                {props == "page" && !isEmpty(tokenData) ?
                    <div>
                        <h2 className={`${Classes.launchpad__titlec} text-uppercase aboro_font text-center mt-5 mb-4`}>
                            Your Token Was Created
                        </h2>
                        <div className={`d-flex align-items-center justify-content-between mb-3`}>
                            <p className={`${Classes.inputLabel} orbitron`}>Name :</p>
                            <p className={`${Classes.inputLabel} orbitron`}>{name}</p>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mb-3`}>
                            <p className={`${Classes.inputLabel} orbitron`}>Symbol :</p>
                            <p className={`${Classes.inputLabel} orbitron`}>{symbol}</p>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mb-3`}>
                            <p className={`${Classes.inputLabel} orbitron`}>Decimal :</p>
                            <p className={`${Classes.inputLabel} orbitron`}>{decimal}</p>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mb-3`}>
                            <p className={`${Classes.inputLabel} orbitron`}>Total Supply :</p>
                            <p className={`${Classes.inputLabel} orbitron`}>{totalsupply}</p>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mb-3`}>
                            <p className={`${Classes.inputLabel} orbitron`}>Address :</p>
                            <p className={`${Classes.inputLabel} ${Classes.wd_sets}  orbitron`}>{tokenAddress}</p>
                        </div>

                        <div className={`d-flex align-items-center flex-wrap gap-4 mt-3 mb-3`}>
                            {/* <button
                                className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-0`}
                            >View Transaction </button> */}

                            <CopyToClipboard text={`${tokenAddress}`} onCopy={() => copyText('invite link', `${tokenAddress}`)}>
                                <button className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-0`}>Copy Address </button>
                            </CopyToClipboard>

                            <Link to="/create-launchpad">
                                <button
                                    className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-0`}
                                >Create Launchpad </button></Link>
                            <Link to="/create-fair-launch">
                                <button
                                    className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-0`}
                                >Create FairLaunch </button></Link>
                            {/* <button className={`${Classes.gradientBtn} border-0 outline-0 mt-2 mb-3 px-4 px-sm-5 py-2 orbitron`} >View Transaction</button> */}

                        </div>
                    </div> : ""}
                {/* token success end*/}


                {isEmpty(tokenData) ?
                    <>
                        
                        <h2
                            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
                        >
                            Create Token
                        </h2>

                        {/* <div className={`${Classes.singleInpHolder} mt-4`}>
                    <label for="presaleRate" className={`${Classes.inputLabel} orbitron`}>Token Type *</label><br />
                    <Select className={`mt-3`}
                        placeholder={`Ex. Select Token Type`}
                        name='token'
                        classNamePrefix={`cmnSelect`}
                        // onChange={setSelectedToken}
                        // value={selectedToken ? selectedToken : null}
                        value={tokentype ? options.find((val) => (val.value == tokentype)) : ''}
                        onChange={(e) => { setTokentype(e) }}
                        options={options}
                    />

                    <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>Pool creation fee: 1 SOL</p>
                </div> */}



                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="name" className={`${Classes.inputLabel} orbitron`}>Name *</label><br />
                            <input type="text" id="name" name="name" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Ex.Ethereum' autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.name}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="symbol" className={`${Classes.inputLabel} orbitron`}>Symbol *</label><br />
                            <input type="text" id="symbol" name="symbol" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={symbol} onChange={(e) => { setSymbol(e.target.value) }} placeholder='Ex. ETH' autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.symbol}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="decimal" className={`${Classes.inputLabel} orbitron`}>Decimals *</label><br />
                            <input type="number" id="decimal" name="decimal" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={decimal} onChange={(e) => { setDecimal(e.target.value) }} placeholder='Ex. 9' autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.decimal}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="image" className={`${Classes.inputLabel} orbitron`}>Image Uri *</label><br />
                            <input type="text" id="image" name="image" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={image} onChange={(e) => { setImage(e.target.value) }} placeholder='https://sample-image/cdn-lc.png' autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.image}</span>
                            <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
                        </div>


                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="totalSupply" className={`${Classes.inputLabel} orbitron`}>Total Supply *</label><br />
                            <input type="number" id="totalSupply" name="totalSupply" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={totalsupply} onChange={(e) => { setTotalsupply(e.target.value) }} placeholder='Ex. 1000' autoComplete="off" />
                            <span className={`cmnErrorHint`}>{errors?.totalSupply}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="description" className={`${Classes.inputLabel} orbitron`}>Description *</label><br />
                            {/* <input type="text" id="description" name="description" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" /> */}
                            <textarea className={`${Classes.textArea} w-100 mt-2 p-2 roboto`} value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                    if (!e.target.value) {
                                        const formvalue = { ...errors, ["description"]: "Invalid Description!" };
                                        setErrors(formvalue);
                                    }
                                    else {
                                        const formvalue = { ...errors, ["description"]: "" };
                                        setErrors(formvalue);
                                    }
                                }}
                                id="description" name="description" rows={3} cols={50} />
                            <span className={`cmnErrorHint`}>{errors?.description && errors?.description}</span>
                        </div>

                        {/* {tokentype.value === 'Liquidity Generator Token' &&
                    <>
                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="yieldFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate yield (%) *</label><br />
                            <input type="number" id="yieldFee" name="yieldFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={yieldfee} onChange={(e) => setYieldfee(e?.target?.value)} placeholder='Ex. 50' autoComplete="off" />
                            <span className="text-danger f-12 d-block text-left">{errors.yieldfee}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="liquidityFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate liquidity (%) *</label><br />
                            <input type="number" id="liquidityFee" name="liquidityFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={liquidityfee} onChange={(e) => setLiquidityfee(e?.target?.value)} placeholder='Ex. 50' autoComplete="off" />
                            <span className="text-danger f-12 d-block text-left">{errors.liquidityfee}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="maxTransaction" className={`${Classes.inputLabel} orbitron`}>Maximum Transaction Amount*</label><br />
                            <input type="number" id="maxTransaction" name="maxTransaction" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={maximumtransaction} onChange={(e) => setMaximumtransaction(e.target.value)} placeholder='Ex. 18' autoComplete="off" />
                            <span className="text-danger f-12 d-block text-left">{errors.maximumtransaction}</span>
                        </div>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                            <label for="exchangeThereshold" className={`${Classes.inputLabel} orbitron`}>Exchange Thersold *</label><br />
                            <input type="number" id="exchangeThereshold" name="exchangeThereshold" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                value={exchangethresold} onChange={(e) => setExchangethresold(e.target.value)} placeholder='Ex. 18' autoComplete="off" />
                            <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>If you keep exchange threshold is lower than adding liquidity, will be frequent</p>
                            <span className="text-danger f-12 d-block text-left">{errors.exchangethresold}</span>
                        </div>
                    </>
                } */}

                        <div className={`text-center`}>
                            <button className={`${Classes.gradientBtn} border-0 outline-0 mt-5 px-4 px-sm-5 py-2 orbitron`} disabled={walletdetail?.walletData?.walletBal < 0.01}
                                onClick={() => { handlecreatetoken() }}>{iscreate ? 'Creating Token...' : 'Create Token'}</button>
                        </div>
                        
                    </>
                    : <></>}
            </div>


        </>
    )
}

export default TokenCreate