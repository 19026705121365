import React, { useState } from "react";
import Classes from "../../asset/styles/ResetPassword.module.css";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Images } from "../../data/Images";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetNewPassword } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom";
import { isEmpty, ObjectIsempty } from "../../lib/isEmpty";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const initialFormValue = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      console.log(formvalue, "formvalueformvalue");
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.password)) {
        error["password"] = "Newpassword is required";
      } else {
        error["password"] = "";
      }
      if (isEmpty(value.confirmPassword)) {
        error["confirmPassword"] = "Confrimpassword is required";
      } else if (value.confirmPassword != value.password) {
        error["confirmPassword"] =
          "Confrim password must match with new password";
      } else {
        error["confirmPassword"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let userData = {
        password: formvalue.password,
        token: location.state._id,
        confirmPassword: formvalue.confirmPassword,
      };
      console.log(location.state._id, "token: location.state._id,", userData);
      let isValidate = validation(userData);
      console.log("🚀 ~ handleSubmit ~ isValidate:", isValidate);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await resetNewPassword(userData);
      if (status == true) {
        toast.success("Password Changed Successfully");
        navigate("/login");
      } else {
        toast.error("Password Changed Failed");
        navigate("/forgot-password");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <div className={`${Classes.adminLogin} position-relative`}>
        <Row className="position-relative h-100 d-flex justify-content-center align-items-center">
          <Col lg={4}>
            <div className={`${Classes.loginFormHolder} p-2 p-sm-4`}>
              <div className={`text-center`}>
                <img src={Images.logo} className={`img-fluid`} alt="" />
              </div>
              <h2
                className={`${Classes.login__title} text-uppercase aboro_font mt-4`}>
                Reset Password
              </h2>
              <div className={`${Classes.titleDivider}`}></div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="newPassword"
                  className={`${Classes.inputLabel} orbitron`}>
                  New Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={newPassword ? `text` : `password`}
                    id="password"
                    name="password"
                    onChange={(e) => handleChange(e)}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setNewPassword(!newPassword)}>
                    {!newPassword ? (
                      <FaEye fontSize={18} />
                    ) : (
                      <FaEyeSlash fontSize={18} />
                    )}
                  </button>
                </div>

                <span class="text-danger">{errors.password}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="confirmPassword"
                  className={`${Classes.inputLabel} orbitron`}>
                  Confirm Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={confirmPassword ? `text` : `password`}
                    id="confirmPassword"
                    name="confirmPassword"
                    onChange={(e) => handleChange(e)}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setConfirmPassword(!confirmPassword)}>
                    {!confirmPassword ? (
                      <FaEye fontSize={18} />
                    ) : (
                      <FaEyeSlash fontSize={18} />
                    )}
                  </button>
                </div>
                <span class="text-danger">{errors.confirmPassword}</span>
              </div>

              <div className={`d-flex justify-content-end w-100`}>
                <NavLink
                  to="/login"
                  className={`${Classes.loginNavLink} text-end`}>
                  <p
                    className={`${Classes.fogotPwHint} m-0 poppins text-end mt-3 pointer`}>
                    Back to Login?
                  </p>
                </NavLink>
              </div>

              <div className={`text-center mt-4`}>
                {/* <button className={`${Classes.loginGradBtn} border-0 outline-0 roboto px-5 py-2 pointer`}>Login</button> */}
                <button
                  className={`${Classes.violetBtn} px-5`}
                  onClick={(e) => handleSubmit(e)}>
                  <p
                    className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                    Submit
                  </p>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPassword;
