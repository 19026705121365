import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Classes from "../asset/styles/AdminHeader.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUserLarge } from "react-icons/fa6";
import { Images } from "../data/Images";
import { sidebarContext } from "../Context/Context";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../lib/isEmpty';
import ConnectWalletModal from '../Modals/ConnectWalletModal';
import Walletbutton from '../components/walletbutton';

const AdminHeader = () => {
  const { handleChange } = useContext(sidebarContext);
  const [profileDrop, setProfileDrop] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [wallet, setWallet] = useState(false)

  const logOut = async () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };



  useEffect(() =>{
   if(sessionStorage.getItem("authToken")  == null ||
   sessionStorage.getItem("authToken")  == undefined ||
   sessionStorage.getItem("authToken")  == "" ) {
    navigate("/login");

   }
  })
  const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_connectWallet", walletdetail)

  return (
    <>
      <Row className={`${Classes.adminHeaderWhole} py-3 mx-auto`}>
        <Col
          xs={2}
          className={`d-flex justify-content-start align-items-center gap-1 gap-sm-2`}>
          <button
            className={`${Classes.headerBurgerBtn} pointer p-1`}
            onClick={() => handleChange()}>
            <img
              src={Images.hamburger}
              className={`${Classes.hamburgerImg} img-fluid`}
              alt="hamburger"
            />
          </button>
          <img
            src={Images.logo}
            className={`${Classes.logo} img-fluid d-none d-sm-flex`}
            alt="logo"
          />
        </Col>

        <Col
          xs={10}
          className={`d-flex justify-content-end align-items-center gap-3`}>
          <div
            className={`${Classes.profileHolder} position-relative d-flex justify-content-center`}>
            <button
              className={`${Classes.profileBtn}`}
              onClick={() => setProfileDrop(!profileDrop)}>
              <FaUserLarge fontSize={15} />
            </button>
            {profileDrop && (
              <div className={`${Classes.profileDropMenu} px-2 py-2`}>
                <div className={`${Classes.profileDrioItem} position-relative`}>
                  <div className={`${Classes.dropTriangle}`}></div>
                  <button
                    className={`${Classes.logoutBtn} w-100 text-start`}
                    onClick={(e) => logOut(e)}>
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* {isEmpty(walletdetail.walletaddress) ?
            <button
              className={`${Classes.launchpad__blueBtn} text-uppercase roboto`}
              onClick={handleShow}
            >
              <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
              <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
              Connect Wallet
            </button>
            : <button
              className={`${Classes.launchpad__blueBtn} text-uppercase roboto`} disabled
            // onClick={handleShow} 
            >
              <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
              <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
              {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
            </button>
          } */}
          <Walletbutton />

          {/* <button
            className={`${Classes.launchpad__blueBtn} text-uppercase roboto`}>
            <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
            <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
            Connect Wallet
          </button> */}
        </Col>
      </Row>
      {/* modals */}
      <ConnectWalletModal show={show} handleClose={handleClose} wallet={wallet} setWallet={setWallet} />
      {/* end of modals */}
    </>
  );
};

export default AdminHeader;
