import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/AdminLayout";
import Classes from "../../asset/styles/AdminKYCList.module.css";
import AdminKYCModal from "../../Modals/AdminKYCModal";
import { commonData } from "../../data/CommonData";
import Pagination from "../../components/Pagination";
import { getKycList } from "../../actions/adminAction";
import { isEmpty } from "../../lib/isEmpty";

const AdminKycList = () => {
  // modal state
  const [kyc, setKyc] = useState(false);
  const [editRecord, seteditRecord] = useState();
  const [walletaddress, setWalletaddress] = useState();
  const [status, setStatus] = useState(true);


  // const handleShowKyc = () => setKyc(true);

  const handleShowKyc = (item, walletaddress) => {
    setKyc(true);
    seteditRecord(item);
    setWalletaddress(walletaddress);
  };
  const handleCloseKyc = () => {
    seteditRecord({});
    setKyc(false);
  };

  // const handleCloseKyc = () => setKyc(false);
  const [kycData, setKycData] = useState();

  // const [filterArr,setFilterArr] = useState([])
  // const [lengthValue,setLengthValue] = useState(0)

  useEffect(() => {
    kycList();
  }, [status]);

  const kycList = async () => {
    try {
      let { status, message, result, count } = await getKycList();
      console.log(
        "🚀 ~ kycList ~ status, message, result, count:",
        status,
        message,
        result,
        count
      );
      if (status == true) {
        setKycData(result);
      }
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}>
            KYC List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            {kycData && kycData.length > 0 ?
              <table className={`${Classes.tokenCmnTable} mt-5`}>
                <thead>
                  <tr>
                    <th className="pb-3">User Wallet Address</th>
                    <th className="pb-3">Status</th>
                    <th className="pb-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {kycData && kycData.length > 0
                    ? kycData.map((item) => {
                      return (
                        <tr className={`${Classes.gradientBorder}`}>
                          <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>
                              {item?.walletaddress}
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>

                              {/* {isEmpty(item.proof) ? "No Kyc" : "Kyc"} */}

                              {isEmpty(item.proof) ? "No Kyc" :
                                item.proof[0]?.status == "Pending" ? "Kyc-Pending" :
                                  item.proof[0]?.status == "Approved" ? "Kyc-Approved" :
                                    "Kyc-Rejected"}
                            </p>
                          </td>
                          <td className={`text-end`}>
                            <button
                              className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`}
                              disabled={isEmpty(item.proof)}
                              onClick={() =>
                                handleShowKyc(item.proof[0], item.walletaddress)
                              }>

                              View
                            </button>
                          </td>

                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={4} className="text-center b_wdth poppins pt-5 pb-5">No Data Found</td>
                    </tr>}


                </tbody>
              </table> :
              <p className="text-white text-center roboto mt-5">No Data Found</p>}
          </div>
          {/* <Pagination /> */}
        </div>
      </AdminLayout>

      {/* modal content */}
      <AdminKYCModal
        show={kyc}
        record={editRecord}
        walletaddress={walletaddress}
        handleClose={handleCloseKyc}
      />
      {/* end of modal content */}
    </>
  );
};

export default AdminKycList;
