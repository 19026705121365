import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Classes from '../asset/styles/TransferOwnerShip.module.css'
import { IoMdClose } from "react-icons/io";
import Uselock from '../hooks/useLock'
import toast from 'react-hot-toast';



const TransferOwnerShip = ({ show, handleClose,getLockData, lockInfo,lockAdd }) => {
  const lockHook = Uselock()


  const [newAddress, setNewAddress] = useState('')
  const [islock, setIslock] = useState(false)


  const ChangeOwnership = async () => {
    setIslock(true)
    let lockData = {
      tokenAddress: lockInfo?.splMint,
      receiverAddress: newAddress,
      lockAddress:lockAdd
    }
    console.log("ChangeOwnership", lockData)
    var result = await lockHook.UseChangeReciever(lockData)
    console.log("result", result)
    setIslock(false)
    getLockData()
    setNewAddress('')
    if (result?.status == true) {
      toast.success('OwnerShip changed Successfully..!')
    }
    else {
      toast.error('Error while changing ownership...!')
    }
    handleClose()

  }



  return (
    <>
      <Modal
        size='md'
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal `}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Transfer Ownership</h5>

          <p className={`${Classes.robotoHint} m-0 roboto mt-4`}>(*) is required field.</p>


          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="newOwnerAddress" className={`${Classes.inputLabel} orbitron`}>New Owner Address *</label><br />
            <input type="text" id="newOwnerAddress" name="newOwnerAddress" value={newAddress} className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b' onChange={(e) => setNewAddress(e.target.value)} autoComplete="off" />
          </div>
          <div className={`text-center w-100 mt-4`}>
            <button className={`${Classes.whiteBtn} poppins`} onClick={()=>{ChangeOwnership()}}>{islock ? 'Transferring Ownership...': 'Transfer Ownership'}</button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default TransferOwnerShip
