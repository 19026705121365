import React, { useContext, useState } from "react";
import Classes from "../../asset/styles/AdminLogin.module.css";
import { Col, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../data/Images";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { sidebarContext } from "../../Context/Context";
import { adminLogin } from "../../actions/adminAction";
import { useDispatch } from "react-redux";
import { isEmpty, ObjectIsempty } from "../../lib/isEmpty";
import toast from "react-hot-toast";

const initialFormValue = {
  email: "",
  password: "",
};

const AdminLogin = () => {
  const [passwordType, setPasswordType] = useState(false);
  const [formvalue, setformvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else {
        error["email"] = "";
      }
      if (isEmpty(value.password)) {
        error["password"] = "Password is required";
      } else {
        error["password"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let Formvalue = { ...formvalue, ...{ [name]: value } };
    let error = { ...errors };
    error[`${name}`] = "";
    setformvalue(Formvalue);
    setErrors(error);
    // });
  };

  const onSubmit = async (e) => {
    try {
      const { email, password } = formvalue;
      const reqData = {
        email: email,
        password: password,
      };
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      const { status, loading, message, token, error } = await adminLogin(
        reqData,
        dispatch
      );

      if (status == true) {
        setformvalue(initialFormValue);
        setErrors({});
        toast.success(message);
        navigate("/launchpad-admin");
      } else {
        console.log(
          status,
          loading,
          message,
          token,
          error,
          "status, loading, message, token, error"
        );
        toast.error(message);
      }
    } catch (err) {}
  };

  return (
    <>
      <div className={`${Classes.adminLogin} position-relative`}>
        <Row className="position-relative h-100 d-flex justify-content-center align-items-center">
          <Col lg={4}>
            <div className={`${Classes.loginFormHolder} p-2 p-sm-4`}>
              <div className={`text-center`}>
                <img src={Images.logo} className={`img-fluid`} alt="" />
              </div>
              <h2
                className={`${Classes.login__title} text-uppercase aboro_font mt-4`}>
                Login
              </h2>
              <div className={`${Classes.titleDivider}`}></div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="email" className={`${Classes.inputLabel} orbitron`}>
                  Email Address
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={formvalue?.email}
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder="Ex.John@gmail.com"
                  autoComplete="off"
                />
                <span class="text-danger">{errors.email}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="password"
                  className={`${Classes.inputLabel} orbitron`}>
                  Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={passwordType ? `text` : `password`}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={formvalue?.password}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setPasswordType(!passwordType)}>
                    {!passwordType ? (
                      <FaEye fontSize={18} />
                    ) : (
                      <FaEyeSlash fontSize={18} />
                    )}
                  </button>
                </div>
                <span class="text-danger">{errors.password}</span>
              </div>
              <div className={`d-flex justify-content-end w-100`}>
                <NavLink
                  to="/forgot-password"
                  className={`${Classes.loginNavLink} text-end`}
                  draggable={false}>
                  <p
                    className={`${Classes.fogotPwHint} m-0 poppins text-end mt-3 pointer`}>
                    Forgot Password?
                  </p>
                </NavLink>
              </div>

              <div className={`text-center mt-4`}>
                {/* <button className={`${Classes.loginGradBtn} border-0 outline-0 roboto px-5 py-2 pointer`}>Login</button> */}
                <button
                  className={`${Classes.violetBtn} px-5`}
                  onClick={(e) => onSubmit(e)}>
                  <p
                    className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                    Login
                  </p>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminLogin;
