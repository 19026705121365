import {isEmpty} from '../lib/isEmpty';
var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;


export const addFeevalidation = (data) => {
    try {
        console.log(data?.currencyvalue==="","data?.currencyvalue==")
        let errors = {};
        let isValid = true;
        if (isEmpty(data?.feeName)) {
            isValid = false;
            errors.feeName = "Fee Name field is required";
        }
        if (data?.currencyValue==="") {
            isValid = false;
            errors.currencyValue = "Currency value field is required";
        }
        else if (!numbers.test(data?.currencyValue)) {
            isValid = false;
            errors.currencyValue = "Currency value only contains numbers";
        }
        if (data?.tokenValue==="") {
            isValid = false;
            errors.tokenValue = "Token value field is required";
        }
        else if (!numbers.test(data?.tokenValue)) {
            isValid = false;
            errors.tokenValue = "Token value only contains numbers";
        }
      
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("addFeevalidation", err)
    }
}

export const editFeevalidation = (data) => {
    try {
        let errors = {};
        let isValid = true;
       
        if (data?.currencyValue==="") {
            isValid = false;
            errors.currencyValue = "Currency value field is required";
        }

        else if (!numbers.test(data?.currencyValue)) {
            isValid = false;
            errors.currencyValue = "Currency value only contains numbers";
        }

        if (data?.tokenValue==="") {
            isValid = false;
            errors.tokenValue = "Token value field is required";
        }
        else if (!numbers.test(data?.tokenValue)) {
            isValid = false;
            errors.tokenValue = "Token value only contains numbers";
        }
      
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("addFeevalidation", err)
    }
}