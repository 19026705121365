import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminEditFee.module.css'
import { editFeevalidation } from '../validations/adminValidations';
import { editFee } from '../actions/adminAction';
import toast, { Toaster } from 'react-hot-toast';

const AdminEditFeeModal = ({ show, handleClose, getFee, editData }) => {

  const [currencyValue, setCurrencyValue] = useState('')
  const [tokenValue, setTokenValue] = useState('')
  const [errors, setErrors] = useState('')


  useEffect(() => {
    console.log("this.props", editData)
    // if (!currencyValue && !tokenValue) {
    setCurrencyValue(editData?.currencyValue);
    setTokenValue(editData?.tokenValue)
    // }
  }, [])


  const handleedit = async () => {
    let payload = {
      currencyValue: currencyValue,
      tokenValue: tokenValue
    }
    let validate = editFeevalidation(payload);
    if (validate?.isValid) {
      let data = {
        id: editData?._id,
        currencyValue: currencyValue,
        tokenValue: tokenValue
      }
      let result = await editFee(data);
      console.log("result", result)
      toast.success(result?.data?.data?.message)
      handleClose();
      getFee()
    }
    else {
      setErrors(validate.errors)
    }
  }



  return (
    <>
      <Modal
        size='md'
        centered
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit Fee Settings</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="feeName" className={`${Classes.inputLabel} orbitron`}>Fee Name</label><br />
            <input type="text" id="feeName" name="feeName" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter fee Name'
              autoComplete="off" value={editData?.feeName} />
          </div>

          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="currencyValue" className={`${Classes.inputLabel} orbitron`}>Currency Value</label><br />
            <input type="text" id="currencyValue" name="currencyValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter Currency Value'
              autoComplete="off" value={currencyValue}
              onChange={(e) => {
                setCurrencyValue(e?.target?.value)
              }} />
            <span className="text-danger f-12 d-block text-left">{errors?.currencyValue}</span>
          </div>

          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="tokenValue" className={`${Classes.inputLabel} orbitron`}>Token Value</label><br />
            <input type="text" id="tokenValue" name="tokenValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter Token Value'
              autoComplete="off" value={tokenValue} onChange={(e) => { setTokenValue(e?.target?.value) }} />
            <span className="text-danger f-12 d-block text-left">{errors?.tokenValue}</span>
          </div>
          <div className={`text-center w-100 mt-4`}>
            <button className={`${Classes.whiteBtn} poppins`} onClick={() => { handleedit() }}>Save</button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminEditFeeModal
