export const getAuthToken = () => {
    if (sessionStorage.getItem('authToken')) {
        return sessionStorage.getItem('authToken')
    }
    return '';
}

export const setAuthToken = (token) => {
    if(token){
        sessionStorage.setItem("authToken", token);
    }
}

export const setWalletAddress = (token) => {
    if(token){
        sessionStorage.setItem("userWalletAddress", token);
    }
}