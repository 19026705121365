import { useWallet } from "@solana/wallet-adapter-react";

export default function HookFunction() {



    const SetWalletAddress = (account) => {
        // console.log(accountInfo, 'walletAdd', accountInfo?.publicKey, accountInfo?.publicKey?.toString())
        sessionStorage.setItem('walletAddress', account)
    }


    const getWalletAddress = () => {
        if (sessionStorage.getItem('walletAddress')) {
            return sessionStorage.getItem('walletAddress')
        }
        return '';
    }


    const SetWallet = (data) => {

        sessionStorage.setItem('connectedWallet', data)
    }


    const getWallet = () => {
        if (sessionStorage.getItem('connectedWallet')) {
            return sessionStorage.getItem('connectedWallet')
        }
        return '';
    }

    const SetWalletBal = (bal) => {
        sessionStorage.setItem('walletBal', bal)

    }

    const getWalletBal = () => {
        if (sessionStorage.getItem('walletBal')) {
            return sessionStorage.getItem('walletBal')
        }
        return '';
    }

    const removeWallteAddress = () => {
        if (sessionStorage.getItem('walletAddress')) {
            sessionStorage.removeItem('walletAddress')
            sessionStorage.removeItem('connectedWallet')
            sessionStorage.removeItem('walletBal')
        }
        return '';
    }

    return (
        {
            SetWalletAddress,
            getWalletAddress,
            SetWallet,
            getWallet,
            SetWalletBal,
            getWalletBal,
            removeWallteAddress
        }
    )
}