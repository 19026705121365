import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminEditAuditModal.module.css'
import Select from 'react-select';
import { auditkycvalidate } from '../validations/userValidations';
import { addAuditsalehook } from '../actions/adminAction';
import toast from 'react-hot-toast';

const AdminEditAuditModal = ({ show, handleClose, record }) => {
  console.log("auditdata", record);

  const options = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];

  const [selected, setSelected] = useState('')
  const [saleAddress, setsaleAddress] = useState("");
  const [displayname, setdisplayname] = useState("");
  const [tyep, settype] = useState("Launchpad");
  const [position, setposition] = useState("");
  const [date, setdate] = useState("");
  const [errors, seterrors] = useState("");
  const [audit, setaudit] = useState("")
  const [kyc, setkyc] = useState("");
  const [DOXX, setDOXX] = useState("");
  const [SAFU, setSAFU] = useState("");
  const [status, setstatus] = useState("");




  useEffect(() => {
    setDOXX(record?.DOXX)
    setSAFU(record?.SAFU)
    setkyc(record?.kyc)
    setsaleAddress(record?.saleAddress)
    setaudit(record?.audit)
    setstatus(record?.status)
    console.log("setstatus", record?.status)
  }, [record])

  console.log("hhgvgdv", status);


  const handleAddAudit = async () => {
    console.log("validate");


    let payload = {
      id: record?._id,
      saleAddress: saleAddress,
      status: status,
      audit: audit,
      kyc: kyc,
      DOXX: DOXX,
      SAFU: SAFU
    }
    let validate = await auditkycvalidate(payload);
    console.log("validate", validate);

    if (validate?.isValid) {
      let submitData = await addAuditsalehook(payload);

      if (submitData?.data?.status) {
        console.log("submitDatasubmitData", submitData);
        toast.success(`${submitData?.data?.result}`)
        window.location.reload()
        handleClose()
      }
    }
    else {
      seterrors(validate?.errors)
    }
  }


  return (
    <>
      <Modal
        size='md'
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >

        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit Audit</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="saleAddress" className={`${Classes.inputLabel} orbitron`}>Sale Address</label><br />
            <input type="text" id="saleAddress"
              readOnly={true}

              value={saleAddress} disabled name="saleAddress" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.saleaddress}</span>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="audit" className={`${Classes.inputLabel} orbitron`}>Audit</label><br />
            <input type="text" id="audit" value={audit} onChange={(e) => setaudit(e.target.value)} name="audit" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.audit}</span>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="kyc" className={`${Classes.inputLabel} orbitron text-uppercase`}>KYC</label><br />
            <input type="text" id="kyc" name="kyc" value={kyc}
              onChange={(e) => setkyc(e.target.value)} className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.kyc}</span>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="kyc" className={`${Classes.inputLabel} orbitron text-uppercase`}>SAFU</label><br />
            <input type="text" id="safu" name="safu" value={SAFU} onChange={(e) => setSAFU(e.target.value)} className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.SAFU}</span>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="doxx" className={`${Classes.inputLabel} orbitron text-uppercase`}>doxx</label><br />
            <input type="text" id="doxx" value={DOXX} onChange={(e) => setDOXX(e.target.value)} name="doxx" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.DOXX}</span>


          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="presaleRate"
              value={status}
              className={`${Classes.inputLabel} orbitron`}>Status</label><br />
            <Select className={`mt-3`}
              placeholder={`Ex. Select Token Type`}
              name='token'
              value={{ value: status, label: status }}
              onChange={(e) => setstatus(e.value)}
              classNamePrefix={`cmnSelect`}
              options={options} />
          </div>
          <span className="text-danger f-12 d-block text-left">{errors?.status}</span>


          <div className={`text-center w-100 mt-4`}>
            <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleAddAudit()}>Save</button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminEditAuditModal
