import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../data/Images";
import Layout from "../../layout/Layout";
import { NavLink } from "react-router-dom";
import Classes from "../../asset/styles/kyc.module.css";
import ConnectWallet from "../../components/ConnectWallet";
import BalanceDetails from "../../components/BalanceDetails";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { isEmpty, ObjectIsempty } from "../../lib/isEmpty";
import { updateKYCDetails } from "../../actions/userAction";
import toast from "react-hot-toast";
import { getUserKycDetails } from "../../actions/userAction";
import HookFunction from "../../hooks/useStorage"
import { useSelector } from "react-redux";

const Kyc = () => {

  const storageHooks = HookFunction()


  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const [wallet, setWallet] = useState(true);
  const [statuss, setstatus] = useState(true);

  const [userData, setUserData] = useState();
  console.log("formvalue datas", formvalue)
  const options = [
    { value: "Individual", label: "Individual" },
    { value: "Company", label: "Company" },
    { value: "Nonprofit Organization", label: "Nonprofit Organization" },
  ];
  const [selectedidentity, setSelectedidentity] = useState(null);
  console.log('selectedidentityselectedidentity', selectedidentity)
  const documentoptions = [
    { value: "Voter ID", label: "Voter ID" },
    { value: "Aadhar Card", label: "Aadhar Card" },
    { value: "Driving Licence", label: "Driving Licence" },
  ];

  const [startDate, setStartDate] = useState(new Date());
  const [filename, setfilename] = useState("");

  const [showCalendar, setShowCalendar] = useState(false);
  const handleCloseCalendar = () => setShowCalendar(false);
  const handleShowCalendar = () => setShowCalendar(true);
  const [walletAddress, setwalletAddress] = useState(sessionStorage.getItem("walletAddress"))

  const walletdetail = useSelector((state) => state.wallet)

  console.log("walletdetail?.walletaddress", walletdetail?.walletaddress, walletAddress);


  useEffect(() => {
    getUser();
  }, [statuss, walletdetail?.walletaddress, walletAddress]);

  const getUser = async () => {
    try {
      console.log("wallet", storageHooks.getWalletAddress())
      let account = storageHooks.getWalletAddress()
      setwalletAddress(sessionStorage.getItem("walletAddress"))
      let { status, message, result } = await getUserKycDetails({
        walletaddress: sessionStorage.getItem("walletAddress"),
      });
      console.log(status, message, result, "status, message, result");
      if (status == true) {
        setUserData(result);
      }
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  const [selecteddocument, setSelecteddocument] = useState(null);

  const OnsetSelecteddocument = (e) => {
    setSelecteddocument(e.value);
    let Formvalue = { ...formvalue, ["idtype"]: e.value };
    setFormvalue(Formvalue);
  };

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      console.log("name, value", value)
      let Formvalue;
      if (name == "zipfile") {
        setfilename(value)
        Formvalue = { ...formvalue, [name]: e.target.files[0] };
      } else {
        Formvalue = { ...formvalue, [name]: value };
      }

      let error = { ...errors };
      error[`${name}`] = "";
      setFormvalue(Formvalue);
      //   setErrors(error);
      // setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  //validate file
  const filevalidate = (image) => {
    try {
      console.log("image", image);
      var fileName = image?.name ? image?.name : image;
      var idxDot = fileName?.lastIndexOf(".") + 1;
      var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
      console.log("extFiole", extFile);
      if (extFile == "zip") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("filevalidate", err);
    }
  };

  const validation = (value) => {
    console.log(value, startDate.toString(), "valuevalue");
    try {
      let error = { ...errors };
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
      var letters = /^[A-Za-z\s]*$/;
      var numbers = /^\d+$/;
      let regex_no = /^[ A-Za-z0-9_@./#&+-]*$/;
      let url = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

      if (isEmpty(selectedidentity)) {
        error["selectedidentity"] = "Field is required";
      } else {
        error["selectedidentity"] = "";
      }
      if (selectedidentity == "Individual") {
        if (isEmpty(value?.email)) {
          error["email"] = "email is required";
        } else if (isEmpty(value?.email?.match(emailRegex))) {
          error["email"] = "Enter Valid Address";
        } else {
          error["email"] = "";
        }

        if (isEmpty(value?.city)) {
          error["city"] = "City is required";
        } else if (isEmpty(value?.city?.match(letters))) {
          error["city"] = "Enter Valid City";
        } else {
          error["city"] = "";
        }

        if (isEmpty(value?.region)) {
          error["region"] = "region is required";
        } else if (isEmpty(value?.region?.match(letters))) {
          error["region"] = "Enter Valid region";
        } else {
          error["region"] = "";
        }

        if (isEmpty(value?.country)) {
          error["country"] = "country is required";
        } else if (isEmpty(value?.country?.match(letters))) {
          error["country"] = "Enter Valid country";
        } else {
          error["country"] = "";
        }
        if (isEmpty(value?.zipfile?.type)) {
          error["zipcode"] = "zipcode is required";
        } else if (isEmpty(value?.zipcode?.match(numbers))) {
          error["zipcode"] = "Enter Valid zipcode";
        } else {
          error["zipcode"] = "";
        }

        if (isEmpty(value?.contact)) {
          error["contact"] = "contact is required";
        } else if (isEmpty(value?.contact?.match(numbers))) {
          error["contact"] = "Enter Valid contact";
        } else {
          error["contact"] = "";
        }

        if (isEmpty(value?.idtype)) {
          error["idtype"] = "idtype is required";
        } else {
          error["idtype"] = "";
        }

        if (isEmpty(value?.idnumber)) {
          error["idnumber"] = "idnumber is required";
        } else if (isEmpty(value?.idnumber?.match(numbers))) {
          error["idnumber"] = "Enter Valid idnumber";
        } else {
          error["idnumber"] = "";
        }
        if (isEmpty(selectedidentity)) {
          error["identitytype"] = "identitytype is required";
        } else {
          error["identitytype"] = "";
        }
        if (isEmpty(value?.zipfile?.type)) {
          error["zipfile"] = "zipfile is required";
        } else if (!filevalidate(value?.zipfile)) {
          error["zipfile"] = "Invalid file format !";
        } else {
          error["zipfile"] = "";
        }
      } else if (selectedidentity == "Company") {
        if (isEmpty(selectedidentity)) {
          error["identitytype"] = "identitytype is required";
        } else {
          error["identitytype"] = "";
        }
        if (isEmpty(value?.businessOwnerName)) {
          error["businessOwnerName"] = "businessOwnerName is required";
        } else if (isEmpty(value?.businessOwnerName?.match(letters))) {
          error["businessOwnerName"] = "Enter Valid name";
        } else {
          error["businessOwnerName"] = "";
        }

        if (isEmpty(value?.businessOwnerSurname)) {
          error["businessOwnerSurname"] = "businessOwnerSurname is required";
        } else if (isEmpty(value?.businessOwnerSurname?.match(letters))) {
          error["businessOwnerSurname"] = "Enter Valid name";
        } else {
          error["businessOwnerSurname"] = "";
        }

        if (isEmpty(value?.businessname)) {
          error["businessname"] = "businessname is required";
        } else if (isEmpty(value?.businessname?.match(letters))) {
          error["businessname"] = "Enter Valid name";
        } else {
          error["businessname"] = "";
        }

        if (isEmpty(value?.companynumber)) {
          error["companynumber"] = "companynumber is required";
        } else if (isEmpty(value?.companynumber?.match(numbers))) {
          error["companynumber"] = "Enter Valid Number";
        } else {
          error["companynumber"] = "";
        }

        if (isEmpty(startDate.toString())) {
          error["registrationdate"] = "registrationdate is required";
        } else {
          error["registrationdate"] = "";
        }

        if (isEmpty(value?.description)) {
          error["description"] = "description is required";
        } else if (isEmpty(value?.description?.match(letters))) {
          error["description"] = "Enter Valid Number";
        } else {
          error["description"] = "";
        }

        if (isEmpty(value?.websiteurl)) {
          error["websiteurl"] = "websiteurl is required";
        } else if (isEmpty(value?.websiteurl?.match(url))) {
          error["websiteurl"] = "Enter Valid url";
        } else {
          error["websiteurl"] = "";
        }

        if (isEmpty(value?.businessemail)) {
          error["businessemail"] = "businessemail is required";
        } else if (isEmpty(value?.businessemail?.match(emailRegex))) {
          error["businessemail"] = "Enter Valid Number";
        } else {
          error["businessemail"] = "";
        }

        if (isEmpty(value?.businessaddress)) {
          error["businessaddress"] = "businessaddress is required";
        } else if (isEmpty(value?.businessaddress?.match(regex_no))) {
          error["businessaddress"] = "Enter Valid Number";
        } else {
          error["businessaddress"] = "";
        }

        if (isEmpty(value?.businesscontactnumber)) {
          error["businesscontactnumber"] = "businesscontactnumber is required";
        } else if (isEmpty(value?.businesscontactnumber?.match(numbers))) {
          error["businesscontactnumber"] = "Enter Valid Number";
        } else {
          error["businesscontactnumber"] = "";
        }

        if (isEmpty(value?.businesscity)) {
          error["businesscity"] = "businesscity is required";
        } else if (isEmpty(value?.businesscity?.match(letters))) {
          error["businesscity"] = "Enter Valid Number";
        } else {
          error["businesscity"] = "";
        }

        if (isEmpty(value?.businessregion)) {
          error["businessregion"] = "businessregion is required";
        } else if (isEmpty(value?.businessregion?.match(letters))) {
          error["businessregion"] = "Enter Valid Number";
        } else {
          error["businessregion"] = "";
        }

        if (isEmpty(value?.businesscountry)) {
          error["businesscountry"] = "businesscountry is required";
        } else if (isEmpty(value?.businesscountry?.match(letters))) {
          error["businesscountry"] = "Enter Valid Number";
        } else {
          error["businesscountry"] = "";
        }

        if (isEmpty(value?.businesszipcode)) {
          error["businesszipcode"] = "businesszipcode is required";
        } else if (isEmpty(value?.businesszipcode?.match(numbers))) {
          error["businesszipcode"] = "Enter Valid Number";
        } else {
          error["businesszipcode"] = "";
        }

        if (isEmpty(selecteddocument)) {
          error["idtype"] = "idtype is required";
        } else {
          error["idtype"] = "";
        }

        if (isEmpty(value?.idnumber)) {
          error["idnumber"] = "idnumber is required";
        } else if (isEmpty(value?.idnumber?.match(numbers))) {
          error["idnumber"] = "Enter Valid Number";
        } else {
          error["idnumber"] = "";
        }

        // if (isEmpty(value?.front)) {
        //   error["front"] = "front is required";
        // } else if (!filevalidate(value?.front)) {
        //   error["front"] = "Invalid file format !";
        // } else {
        //   error["front"] = "";
        // }
      } else {
        if (isEmpty(value?.ceoname)) {
          error["ceoname"] = "ceoname is required";
        } else if (isEmpty(value?.ceoname?.match(letters))) {
          error["ceoname"] = "Enter Valid Address";
        } else {
          error["ceoname"] = "";
        }

        if (isEmpty(value?.organizationname)) {
          error["organizationname"] = "organizationname is required";
        } else if (isEmpty(value?.organizationname?.match(letters))) {
          error["organizationname"] = "Enter Valid Address";
        } else {
          error["organizationname"] = "";
        }

        // if (isEmpty(value?.nonprofitregistrationdate)) {
        //   error["nonprofitregistrationdate"] =
        //     "nonprofitregistrationdate is required";
        // } else {
        //   error["nonprofitregistrationdate"] = "";
        // }

        if (isEmpty(value?.nonprofitorganizationaddress)) {
          error["nonprofitorganizationaddress"] =
            "nonprofitorganizationaddress is required";
        } else if (
          isEmpty(value?.nonprofitorganizationaddress?.match(regex_no))
        ) {
          error["nonprofitorganizationaddress"] = "Enter Valid Address";
        } else {
          error["nonprofitorganizationaddress"] = "";
        }

        if (isEmpty(value?.nonprofitcity)) {
          error["nonprofitcity"] = "nonprofitcity is required";
        } else if (isEmpty(value?.nonprofitcity?.match(letters))) {
          error["nonprofitcity"] = "Enter Valid Address";
        } else {
          error["nonprofitcity"] = "";
        }

        if (isEmpty(value?.nonprofitregion)) {
          error["nonprofitregion"] = "nonprofitregion is required";
        } else if (isEmpty(value?.nonprofitregion?.match(letters))) {
          error["nonprofitregion"] = "Enter Valid Address";
        } else {
          error["nonprofitregion"] = "";
        }

        if (isEmpty(value?.nonprofitcountry)) {
          error["nonprofitcountry"] = "nonprofitcountry is required";
        } else if (isEmpty(value?.nonprofitcountry?.match(letters))) {
          error["nonprofitcountry"] = "Enter Valid Address";
        } else {
          error["nonprofitcountry"] = "";
        }

        if (isEmpty(value?.nonprofitzipcode)) {
          error["nonprofitzipcode"] = "nonprofitzipcode is required";
        } else if (isEmpty(value?.nonprofitzipcode?.match(numbers))) {
          error["nonprofitzipcode"] = "Enter Valid Address";
        } else {
          error["nonprofitzipcode"] = "";
        }

        if (isEmpty(value?.nonprofitemailid)) {
          error["nonprofitemailid"] = "nonprofitemailid is required";
        } else if (isEmpty(value?.nonprofitemailid?.match(emailRegex))) {
          error["nonprofitemailid"] = "Enter Valid Address";
        } else {
          error["nonprofitemailid"] = "";
        }

        if (isEmpty(value?.nonprofitcontactnumber)) {
          error["nonprofitcontactnumber"] =
            "nonprofitcontactnumber is required";
        } else if (isEmpty(value?.nonprofitcontactnumber?.match(numbers))) {
          error["nonprofitcontactnumber"] = "Enter Valid Address";
        } else {
          error["nonprofitcontactnumber"] = "";
        }

        if (isEmpty(value?.websiteurl)) {
          error["websiteurl"] = "websiteurl is required";
        } else if (isEmpty(value?.websiteurl?.match(url))) {
          error["websiteurl"] = "Enter Valid url";
        } else {
          error["websiteurl"] = "";
        }

        if (isEmpty(selecteddocument)) {
          error["idtype"] = "idtype is required";
        } else {
          error["idtype"] = "";
        }

        if (isEmpty(value?.idnumber)) {
          error["idnumber"] = "idnumber is required";
        } else if (isEmpty(value?.idnumber?.match(numbers))) {
          error["idnumber"] = "Enter Valid Address";
        } else {
          error["idnumber"] = "";
        }

        if (isEmpty(selectedidentity)) {
          error["identitytype"] = "identitytype is required";
        } else {
          error["identitytype"] = "";
        }

        // if (isEmpty(value?.front)) {
        //   error["front"] = "front is required";
        // } else if (!filevalidate(value?.front)) {
        //   error["front"] = "Invalid file format !";
        // } else {
        //   error["front"] = "";
        // }
      }

      return error;
    } catch (err) {
      console.log("kycvalid", err);
      return {};
    }
  };

  const onSubmit = async (e) => {
    try {
      let isValidate = validation(formvalue);
      console.log("isValidate:", isValidate);
      const formData = new FormData();
      formData.append("identitytype", selectedidentity);
      formData.append("email", formvalue?.email);
      formData.append("city", formvalue?.city);
      formData.append("region", formvalue?.region);
      formData.append("country", formvalue?.country);
      formData.append("zipcode", formvalue?.zipcode);
      formData.append("contact", formvalue?.contact);
      formData.append("idtype", selecteddocument);
      formData.append("idnumber", formvalue?.idnumber);
      formData.append("zipfile", formvalue?.zipfile);
      formData.append("businessOwnerName", formvalue?.businessOwnerName);
      formData.append("businessOwnerSurname", formvalue?.businessOwnerSurname);
      formData.append("businessaddress", formvalue?.businessaddress);

      formData.append("businessname", formvalue?.businessname);
      formData.append("companynumber", formvalue?.companynumber);
      formData.append("registrationdate", startDate);
      formData.append("description", formvalue?.description);
      formData.append("url", formvalue?.websiteurl);
      formData.append("businessemail", formvalue?.businessemail);
      formData.append(
        "businesscontactnumber",
        formvalue?.businesscontactnumber
      );
      formData.append("businesscity", formvalue?.businesscity);
      formData.append("businessregion", formvalue?.businessregion);
      formData.append("businesscountry", formvalue?.businesscountry);
      formData.append("businesszipcode", formvalue?.businesszipcode);
      formData.append("ceoname", formvalue?.ceoname);
      formData.append("organizationname", formvalue?.organizationname);

      formData.append(
        "nonprofitorganizationaddress",
        formvalue?.nonprofitorganizationaddress
      );
      formData.append("nonprofitcity", formvalue?.nonprofitcity);
      formData.append("nonprofitregion", formvalue?.nonprofitregion);
      formData.append("nonprofitcountry", formvalue?.nonprofitcountry);
      formData.append("nonprofitemailid", formvalue?.nonprofitemailid);
      formData.append(
        "nonprofitcontactnumber",
        formvalue?.nonprofitcontactnumber
      );
      formData.append("nonprofitzipcode", formvalue?.nonprofitzipcode);

      // formData.append("walletaddress", "JK6jD5dtp6ui3wnVx8erieAV3QFRPSswZBj8Nt7wZGk");
      formData.append("walletaddress", walletdetail?.walletaddress);


      console.log("formData", formData?.walletaddress, walletdetail?.walletaddress,);

      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await updateKYCDetails(formData);
      console.log(
        "resultttttt",
        status,
        message,
        result
      );
      if (status == true) {
        // setFormvalue({});
        setFormvalue({});
        setSelectedidentity("")
        setSelecteddocument("")
        setErrors({});
        setSelecteddocument("")
        setfilename("")
        setstatus(!statuss)

        toast.success("KYC Submited Successfully");
      } else {
        setSelectedidentity("")
        setSelecteddocument("")
        toast.error("KYC Submited Failed");
        setFormvalue({});
        setErrors({});
        setfilename("")
        setstatus(!statuss)


      }
    } catch (err) {
      console.log(err, "ERR");
    }
  };
  console.log(formvalue, "formvalue");
  return (
    <>
      <Layout>
        <div className={``}>
          <section className="custom_section position-relative">
            {/* <img
              src={Images.lighting}
              className={`${Classes.absLighting} img-fluid`}
              alt=""
            /> */}
            {wallet && <ConnectWallet wallet={wallet} setWallet={setWallet} />}
            {/* <Loader/> */}
            <BalanceDetails />

            <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5`}>
              <Col
                lg={{ span: 8, offset: 2 }}
                xs={12}
                className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
                <div
                  className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                  <h2
                    className={`${Classes.launchpad__title} text-uppercase aboro_font`}>
                    KYC
                  </h2>
                  {console.log("userData && userData?.proof[0]?.status", userData && userData?.proof[0]?.status)}

                  {userData && userData?.proof[0]?.status == "Pending" ? (
                    <div className="col-12">
                      <p className={`${Classes.inputLabel} orbitron`}>
                        Thank you for the information provided!
                      </p>
                      <p className={`${Classes.inputLabel} orbitron`}>
                        The Solmax IDO team is already verifying the
                        information in order to validate your information.
                      </p>
                    </div>
                  ) : userData && userData?.proof[0]?.status == "Approved" ? (
                    <div className="col-12">
                      <p className={`${Classes.inputLabel} orbitron`}>
                        Thank you for the information provided!
                      </p>
                      <p className={`${Classes.inputLabel} orbitron`}>
                        Your KYC information successfully Approved!
                      </p>
                    </div>
                  ) :
                    userData && userData?.proof[0]?.status == "Rejected" ? (
                      <div className="col-12">
                        <p className={`${Classes.inputLabel} orbitron`}>
                          Thank you for the information provided!
                        </p>
                        <p className={`${Classes.inputLabel} orbitron`}>
                          Your KYC information was rejected!
                        </p>
                      </div>
                    ) : (
                      <>
                        {console.log("sreswfdsfsdfsfd", selectedidentity)}
                        <div className={`${Classes.singleInpHolder} mt-4`}>
                          <label
                            for="presaleRate"
                            className={`${Classes.inputLabel} orbitron`}>
                            Select Identity Type{" "}
                          </label>
                          <br />
                          <Select
                            className={`mt-3`}
                            placeholder={!(isEmpty(selectedidentity)) ? selectedidentity : `Select Identity Type`}
                            name="identity"
                            classNamePrefix={`cmnSelect`}
                            onChange={(e) => setSelectedidentity(e.value)}
                            value={
                              !(isEmpty(selectedidentity)) ? selectedidentity : null
                            }
                            options={options}
                          />
                        </div>
                        <span class="text-danger">{errors.identitytype}</span>

                        {selectedidentity === "Company" && (
                          <>
                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Owner Name*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businessOwnerName"
                                name="businessOwnerName"
                                onChange={handleChange}
                                value={formvalue?.businessOwnerName || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businessOwnerName}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Owner Surname*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businessOwnerSurname"
                                name="businessOwnerSurname"
                                value={formvalue?.businessOwnerSurname || ""}
                                onChange={handleChange}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businessOwnerSurname}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Name*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businessname"
                                onChange={handleChange}
                                name="businessname"
                                value={formvalue?.businessname || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.businessname}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Company Number*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="companynumber"
                                onChange={handleChange}
                                name="companynumber"
                                value={formvalue?.companynumber || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.companynumber}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="description"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Type
                              </label>
                              <br />
                              <textarea
                                className={`${Classes.textArea} w-100 mt-2 p-2 roboto`}
                                id="description"
                                name="description"
                                onChange={handleChange}
                                value={formvalue?.description || ""}
                                rows={3}
                                cols={50}
                              />
                            </div>
                            <span class="text-danger">{errors.description}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Email
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businessemail"
                                name="businessemail"
                                onChange={handleChange}
                                value={formvalue?.businessemail || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businessemail}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                City
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businesscity"
                                name="businesscity"
                                onChange={handleChange}
                                value={formvalue?.businesscity || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.businesscity}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Region
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businessregion"
                                name="businessregion"
                                onChange={handleChange}
                                value={formvalue?.businessregion || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businessregion}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Country
                              </label>
                              <br />
                              <input
                                type="text"
                                id="businesscountry"
                                name="businesscountry"
                                onChange={handleChange}
                                value={formvalue?.businesscountry || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businesscountry}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Zip code
                              </label>
                              <br />
                              {console.log("formvalue?.businesszipcode", formvalue?.businesszipcode)}
                              <input
                                type="text"
                                id="businesszipcode"
                                name="businesszipcode"
                                onChange={handleChange}
                                value={formvalue?.businesszipcode || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businesszipcode}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Contact Number
                              </label>
                              <br />
                              <input
                                type="text"
                                onChange={handleChange}
                                id="businesscontactnumber"
                                name="businesscontactnumber"
                                value={formvalue?.businesscontactnumber || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businesscontactnumber}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="description"
                                className={`${Classes.inputLabel} orbitron`}>
                                Business Address
                              </label>
                              <br />
                              <textarea
                                className={`${Classes.textArea} w-100 mt-2 p-2 roboto`}
                                id="businessaddress"
                                onChange={handleChange}
                                name="businessaddress"
                                value={formvalue?.businessaddress || ""}
                                rows={3}
                                cols={50}
                              />
                            </div>
                            <span class="text-danger">
                              {errors.businessaddress}
                            </span>
                          </>
                        )}

                        {selectedidentity === "Nonprofit Organization" && (
                          <>
                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Name of CEO*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="ceoname"
                                name="ceoname"
                                onChange={handleChange}
                                value={formvalue?.ceoname}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.ceoname}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Organization Name*
                              </label>
                              <br />
                              <input
                                type="text"
                                id="organizationname"
                                name="organizationname"
                                onChange={handleChange}
                                value={formvalue?.organizationname || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.organizationname}
                            </span>

                            {/* <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label
                          for="name"
                          className={`${Classes.inputLabel} orbitron`}>
                          Registration Date*
                        </label>
                        <br />
                        <div
                          className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
                          <div
                            className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
                            <DatePicker
                              selected={startDate}
                              name="nonprofitregistrationdate"
                              dateFormat="Pp"
                              showTimeSelect
                              onChange={(date) => setStartDate(date)}
                              className={`p-2 roboto flex-grow-1`}
                            />
                            <button
                              className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`}
                              onClick={handleShowCalendar}>
                              <i class="fa-solid fa-calendar-days" />
                            </button>
                          </div>
                        </div>
                      </div> */}

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="description"
                                className={`${Classes.inputLabel} orbitron`}>
                                Organization Address*
                              </label>
                              <br />
                              <textarea
                                className={`${Classes.textArea} w-100 mt-2 p-2 roboto`}
                                id="nonprofitorganizationaddress"
                                name="nonprofitorganizationaddress"
                                value={formvalue?.nonprofitorganizationaddress || ""}
                                onChange={handleChange}
                                rows={3}
                                cols={50}
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitorganizationaddress}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                City
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitcity"
                                name="nonprofitcity"
                                onChange={handleChange}
                                value={formvalue?.nonprofitcity || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitcity}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Region
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitregion"
                                name="nonprofitregion"
                                value={formvalue?.nonprofitregion || ""}
                                onChange={handleChange}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitregion}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Country
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitcountry"
                                name="nonprofitcountry"
                                value={formvalue?.nonprofitcountry || ""}
                                onChange={handleChange}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitcountry}
                            </span>

                            {/* <div className={`${Classes.singleInpHolder} mt-4`}>
                        <label
                          for="name"
                          className={`${Classes.inputLabel} orbitron`}>
                          Region
                        </label>
                        <br />
                        <input
                          type="text"
                          id="nonprofitregion"
                          name="nonprofitregion"
                          value={formvalue?.nonprofitregion}
                          onChange={handleChange}
                          className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                          autoComplete="off"
                        />
                      </div> */}

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Zip code
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitzipcode"
                                name="nonprofitzipcode"
                                onChange={handleChange}
                                value={formvalue?.nonprofitzipcode || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitzipcode}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Email Id
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitemailid"
                                name="nonprofitemailid"
                                onChange={handleChange}
                                value={formvalue?.nonprofitemailid || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitemailid}
                            </span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Contact Number
                              </label>
                              <br />
                              <input
                                type="text"
                                id="nonprofitcontactnumber"
                                name="nonprofitcontactnumber"
                                onChange={handleChange}
                                value={formvalue?.nonprofitcontactnumber || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">
                              {errors.nonprofitcontactnumber}
                            </span>
                          </>
                        )}

                        {(selectedidentity === "Company" ||
                          selectedidentity === "Nonprofit Organization") && (
                            <>
                              <div className={`${Classes.singleInpHolder} mt-4`}>
                                <label
                                  for="name"
                                  className={`${Classes.inputLabel} orbitron`}>
                                  Website URL
                                </label>
                                <br />
                                <input
                                  type="text"
                                  id="websiteurl"
                                  name="websiteurl"
                                  onChange={handleChange}
                                  value={formvalue?.websiteurl || ""}
                                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                  autoComplete="off"
                                />
                              </div>
                              <span class="text-danger">{errors.websiteurl}</span>

                              <div className={`${Classes.singleInpHolder} mt-4`}>
                                <label
                                  for="name"
                                  className={`${Classes.inputLabel} orbitron`}>
                                  Registration Date*
                                </label>
                                <br />
                                <div
                                  className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
                                  <div
                                    className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
                                    <DatePicker
                                      selected={startDate}
                                      name="registrationdate"
                                      dateFormat="Pp"
                                      showTimeSelect
                                      onChange={(date) => setStartDate(date)}
                                      className={`p-2 roboto flex-grow-1`}
                                    />
                                    <button
                                      className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`}
                                      onClick={handleShowCalendar}>
                                      <i class="fa-solid fa-calendar-days" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span class="text-danger">
                                {errors.registrationdate}
                              </span>
                            </>
                          )}

                        {selectedidentity === "Individual" && (
                          // || selectedidentity === "Company" ||
                          // selectedidentity === "Nonprofit Organization"
                          <>
                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              {/* <label
                          for="name"
                          className={`${Classes.inputLabel} orbitron`}>
                          {selectedidentity === "Company"
                            ? "Business Email ID"
                            : "Email ID"}
                        </label> */}

                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Email Id
                              </label>

                              <br />
                              <input
                                type="text"
                                id="email"
                                name="email"
                                onChange={handleChange}
                                value={formvalue?.email || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.email}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                City
                              </label>
                              <br />
                              <input
                                type="text"
                                id="city"
                                name="city"
                                onChange={handleChange}
                                value={formvalue?.city || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.city}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Region
                              </label>
                              <br />
                              <input
                                type="text"
                                id="region"
                                name="region"
                                value={formvalue?.region || ""}
                                onChange={handleChange}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.region}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Country
                              </label>
                              <br />
                              <input
                                type="text"
                                id="country"
                                name="country"
                                value={formvalue?.country || ""}
                                onChange={handleChange}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>

                            <span class="text-danger">{errors.country}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Zip code
                              </label>
                              <br />
                              <input
                                type="text"
                                id="zipcode"
                                name="zipcode"
                                onChange={handleChange}
                                value={formvalue.zipcode || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.zipcode}</span>

                            <div className={`${Classes.singleInpHolder} mt-4`}>
                              <label
                                for="name"
                                className={`${Classes.inputLabel} orbitron`}>
                                Contact Number
                              </label>
                              <br />
                              <input
                                type="text"
                                id="contact"
                                name="contact"
                                onChange={handleChange}
                                value={formvalue?.contact || ""}
                                className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                                autoComplete="off"
                              />
                            </div>
                            <span class="text-danger">{errors.contact}</span>
                          </>
                        )}

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                          <label
                            for="presaleRate"
                            className={`${Classes.inputLabel} orbitron`}>
                            Select Document Type{" "}
                          </label>
                          <br />
                          <Select
                            className={`mt-3`}
                            placeholder={!(isEmpty(selecteddocument)) ? selecteddocument : `Select Document Type`}
                            name="idtype"
                            classNamePrefix={`cmnSelect`}
                            onChange={(e) => OnsetSelecteddocument(e)}
                            value={
                              !(isEmpty(selecteddocument)) ? selecteddocument : ""
                            }
                            options={documentoptions}
                          />
                        </div>
                        <span class="text-danger">{errors.idtype}</span>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                          <label
                            for="name"
                            className={`${Classes.inputLabel} orbitron`}>
                            ID Number
                          </label>
                          <br />
                          <input
                            type="text"
                            id="idnumber"
                            name="idnumber"
                            onChange={(e) => { handleChange(e) }}
                            value={formvalue?.idnumber || ""}
                            className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                            autoComplete="off"
                          />
                        </div>
                        <span class="text-danger">{errors.idnumber}</span>

                        <div className={`${Classes.singleInpHolder} mt-4`}>
                          <label
                            for="name"
                            className={`${Classes.inputLabel} orbitron`}>
                            Upload Document in zip file
                          </label>
                          <br />
                          <div className="browseinput mt-2">
                            <input
                              type="file"
                              name="zipfile"
                              onChange={(e) => { handleChange(e) }}
                              className={`${Classes.inputBox} ${Classes.opacity} inter_font mt-0 w-100 p-2`}
                              autoComplete="off"
                            />
                            <button className={`${Classes.gradientBtn} ${Classes.choosebtn} border-0 outline-0  px-4 px-sm-5 py-2 orbitron`} type="button">Choose file</button>
                          </div>
                          <p className="text-white">{filename == "" ? "File name" : filename}</p>
                        </div>
                        <span class="text-danger">{errors.zipfile}</span>

                        <div className={`text-center`}>
                          <button
                            className={`${Classes.gradientBtn} border-0 outline-0 mt-5 px-4 px-sm-5 py-2 orbitron`}
                            onClick={(e) => onSubmit(e)}>
                            Apply
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Kyc;
