/** npm import */

import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

/** local files import */

// import "../asset/styles/HeaderMarquee.css";
import classes from "../asset/styles/HeaderMarquee.module.css";
import Usesale from "../hooks/useSale";
import { getallsalehook } from "../actions/userAction";
import { isEmpty } from "../lib/isEmpty";

/** code start */

const HeaderMarquee = () => {

  const saleHook = Usesale()

  const [trendingSale, setTrendingSale] = useState("")
  const [myDBData, setMyDBData] = useState({})

  const getSaleData = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_Trending", TotalData, TotalData?.length)
    if (isEmpty(TotalData)) {
      return false
    }
    const Totalfilter = TotalData?.filter((val) => {
      return val?.account?.currencyRaised > 0
    })
    console.log("Totalfilter", Totalfilter)
    let filter = [...Totalfilter]
    setTrendingSale(Totalfilter)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb_Trending", getSalefromDb)
    let DBPush = []
    for (let i = 0; i < filter.length; i++) {
      const filterDb = getSalefromDb?.data?.data?.find((val) =>
        (val?.saleAddress == Totalfilter[i]?.publicKey?.toString() && val?.name !== ""))
      console.log("filterDb", filterDb)
      DBPush.push(filterDb)
    }
    setMyDBData(DBPush)
  }


  useEffect(() => {
    getSaleData()
  }, [])

  return (
    <div className={`${classes.marquee} d-flex align-items-center row mx-auto`}>
      {myDBData?.length > 0 ? 
      <div
        className={`col-4 col-lg-2  d-flex align-items-center justify-content-center`}
      >
        <p className={`violet_sans m-0`}> Trending :</p>
      </div>
      :""}
      <div className="col-8 col-lg-10 ps-0">
        {" "}
        <div className={`${classes.content}`}>
          <Marquee
            className={`${classes.marquee_body} h-100`}
            autoFill={true}
            pauseOnClick={true}
          >
            {myDBData && myDBData?.length > 0 && myDBData.map((val, i) => {
              // { console.log("myDBData", val) }

              return (

                <p className={`violet_sans`}>#{i + 1} {val?.name}</p>


              )
            })

            }
          </Marquee>

        </div>
      </div>
    </div>
  );
};

export default HeaderMarquee;
