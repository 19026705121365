import React, { useEffect, useRef, useState } from 'react'
import Classes from '../asset/styles/ConnectWallet.module.css'
import ConnectWalletModal from '../Modals/ConnectWalletModal';
import Usewallet from "../hooks/UseWallet";
import { notify } from '../hooks/useToast';
import { getWalletAddress } from '../hooks/useStorage';
import { isEmpty } from '../lib/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import HookFunction from "../hooks/useStorage"



const ConnectWallet = () => {
  const scrollRef = useRef(null);
  const [walletAddress, setWalletAddress] = useState(null)
  // modal states
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [add, setAdd] = useState()
  const [wallet, setWallet] = useState()
  const [waltype, setWaltype] = useState("")
  const [bal, setBal] = useState()
  const [token, setToken] = useState()
  const [tokendecimal, setTokendecimal] = useState()
  const walletHook = Usewallet()
  const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_connectWallet", walletdetail)

  const storageHooks = HookFunction()

  useEffect(() => {
    // if ((localStorage.getItem('wallet'))) {
    //   walletconnect(localStorage.getItem('wallet'))
    // }
    const preventDefault = (e) => {
      e.preventDefault();
    }

    const div = scrollRef.current;
    if (div) {
      div.addEventListener('wheel', preventDefault, { passive: false });
      div.addEventListener('scroll', preventDefault, { passive: false });
    }

    return () => {
      if (div) {
        div.removeEventListener('wheel', preventDefault, { passive: false });
        div.removeEventListener('scroll', preventDefault, { passive: false });
      }
    };
  }, [walletdetail]);


// console.log("getWalletAddress",isEmpty(getWalletAddress()) === true)

  return (
    <>
      {/* { isEmpty(getWalletAddress()) === true ?
        <div ref={scrollRef} className={`${Classes.connectWalletHolder} d-flex justify-content-center align-items-center`}>
          <h6 className={`${Classes.connectWalletHint} m-0 text-center pointer`} onClick={handleShow}>Connect Wallet to Continue</h6>
        </div>
        : ""} */}


      {/* modal content */}

      <ConnectWalletModal show={show} handleClose={handleClose} wallet={wallet} setWallet={setWallet} />
    </>
  )
}

export default ConnectWallet
