import React, { useEffect, useState } from 'react'
import Classes from '../asset/styles/LaunchingTable.module.css'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Marquee from "react-fast-marquee";
import { Col, Row } from 'react-bootstrap';
import { getAllCMSData } from '../actions/cmsActions';
import qu from "../asset/images/qu.png"
import { getSettingsData } from "../actions/cmsActions";

const LaunchingTable = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const [CMSdata, setCMSdata] = useState({})
  const [sitedata, setsitedata] = useState({})



  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let { status, message, result } = await getAllCMSData();
      if (status) {
        setCMSdata(result)
      }

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    getSiteSetting()
  }, [])


  const getSiteSetting = async () => {
    let getsitdata = await getSettingsData()
    if (getsitdata?.status) {
      setsitedata(getsitdata?.result);

    }

  }

  return (
    <>

      <div className={`${Classes.customFluidContainer}`}>
        <div className={``}>

          <div className={`${Classes.shapeSection} py-3 py-sm-4 py-md-5`}>
            <Row className={`mx-auto mt-3 mt-sm-4 mt-md-5 justify-content-between`}>
              <Col md={6} className={`d-flex justify-content-center align-items-center align-items-md-start flex-column mb-4 mb-md-0 ps-0 ps-sm-1 ps-md-3 ps-lg-5 ps-xl-5`}>
                <div className={`${Classes.launchpad__nameHolder}`}>

                  <h1
                    className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>launching</h1>
                </div>

                <button className={`${Classes.premiumBtn} mt-3 roboto`}>Premium Option = Maximum Support</button>
              </Col>

              <Col md={6} xl={6} xxl={5} className={`d-flex justify-content-center align-items-center`}>
                <div
                  className={`${Classes.launchpad__singleTicks}  justify-content-center align-items-center`}
                >
                  <img src={qu} className='img-fluid' alt='images' />
                  <div className={`${Classes.whiteborder}`}>
                    <p
                      className={`${Classes.dualBox_hint}  roboto text-uppercase m-0  mt-0`}
                    >
                      {CMSdata && CMSdata.length > 0 ?

                        CMSdata[2]?.content


                        : "only for premium projects with manually approval from solmax team. contact our team for further consultants."}
                    </p>
                  </div>

                </div>
              </Col>

            </Row>
            <div className={`${Classes.marqueeHolder} mt-5 d-flex justify-content-start align-items-center`}>
              <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                <p className={`${Classes.tableCellHint} text-uppercase roboto`}><b>premium supporting :</b></p>
              </div>
              <Marquee
                className={`${Classes.marquee_body} h-100`}
                autoFill={true}
                pauseOnClick={true}
              >
                <div className={`${Classes.tableHolder} d-flex justify-content-start align-items-center `}>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>01</p>
                  </div>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>ai- tech support</p>
                  </div>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>02</p>
                  </div>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>marketing support</p>
                  </div>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>03</p>
                  </div>

                  <div className={`${Classes.tableSingleCell} d-flex justify-content-center align-items-center`}>
                    <p className={`${Classes.tableCellHint} text-uppercase roboto`}>multi-page feature</p>
                  </div>

                </div>
              </Marquee>
            </div>


            <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
              <button className={`${Classes.violetBtn} px-2 px-sm-3`} onClick={() => { window.open(`${sitedata[4]?.value}`) }}>
                <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Apply for premium</p></button>
              {/* {location.pathname == '/' ? <></> : */}
              <button className={`${Classes.violetBtn} px-2 px-sm-3`} onClick={() => navigate('/create-launchpad')}>
                <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>create launchpad</p></button>
              {/* } */}
            </div>


          </div>
        </div>


      </div>

    </>
  )
}

export default LaunchingTable
