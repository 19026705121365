export const Images = {
  dashboard: require("../asset/images/dashboard.png"),
  dashboardActive: require("../asset/images/dashboardActive1.png"),
  logo: require("../asset/images/logo.png"),
  salesImg: require("../asset/images/cardImg.svg").default,
  globe: require("../asset/images/global.svg").default,
  twitter: require("../asset/images/twitter.svg").default,
  telegram: require("../asset/images/telegram.svg").default,
  cat: require("../asset/images/cat.png"),
  triangle: require("../asset/images/triangle.png"),
  rightmark: require("../asset/images/rightmark.png"),
  lighting: require("../asset/images/lighting.png"),
  clip: require("../asset/images/clip.png"),
  solmax: require("../asset/images/solmax.png"),
  solmax1: require("../asset/images/solmax2.png"),
  bannerclif: require("../asset/images/bannerclif.png"),
  buttonNext: require("../asset/images/buttonNext.png"),
  itachiBanner : require('../asset/images/itachiBanner.jpg'),
  itachiLogo : require('../asset/images/itachiLogo.jpg'),
  goldIndi: require("../asset/images/goldIndi.svg").default,
  Flash : require('../asset/images/Flash.png'),
  redCat: require("../asset/images/redCat.svg").default,
  coinCat : require('../asset/images/coinCat.png'),
  endFlash : require('../asset/images/endFlash.png'),
  solana : require('../asset/images/solana.png'),
  noData : require('../asset/images/nodata.png'),
  walletConnect: require("../asset/images/walletconnect.svg").default,
  hamburger: require("../asset/images/hamburger.svg").default,
  admin_launchpad : require("../asset/images/admin_launchpad.svg").default,
  admin_createLaunchpad : require("../asset/images/admin_createLaunchpad.svg").default,
  admin_fairlaunch : require("../asset/images/admin_fairlaunch.svg").default,
  admin_privatesale : require("../asset/images/admin_privatesale.svg").default,
  admin_kyc : require("../asset/images/admin_kyc.svg").default,
  admin_settings : require("../asset/images/admin_settings.svg").default,
  admin_launchSettins : require("../asset/images/admin_launchSettins.svg").default,
  admin_audit : require("../asset/images/admin_audit.svg").default,
  admin_profileSettings : require("../asset/images/admin_profileSettings.svg").default,
  admin_feeSettings : require("../asset/images/admin_feeSettings.svg").default,
  admin_launchpadList : require("../asset/images/admin_launchpadList.svg").default,
  admin_cms : require("../asset/images/admin_cms.svg").default,
  document : require("../asset/images/document.svg").default,

};
