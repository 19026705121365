import React,{useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminDeleteFeeModal.module.css'

const AdminDeleteFeeModal = ({show,handleClose,OnDelete,deleteId}) => {


  useEffect(()=>{
    console.log("OnDelete",OnDelete, deleteId)
},[show])

  return (
    <>
       <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Delete Fee</h5>


        <p className={`${Classes.deleteFeeHint} roboto text-center mt-4`}>Are you sure want to delete this Fee?</p>
        <div className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`}onClick={()=>{OnDelete(deleteId)}}>Done</button>
              {/* <button className={`${Classes.whiteBorderBtn} poppins`} onClick={() => handleClose()}>Reject</button> */}
              </div>
         
        </Modal.Body>


      </Modal>
    </>
  )
}

export default AdminDeleteFeeModal
