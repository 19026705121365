import { isEmpty } from '../src/lib/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WALLET_DATA, WALLET_ADDRESS, DEPLOYMENT_FEE_DATA } from "../src/constants"
// import { getWalletAddress, removeWallteAddress } from "../src/hooks/useStorage"
import { useWallet } from "@solana/wallet-adapter-react";
import { useLocation } from 'react-router-dom';

import HookFunction from "./hooks/useStorage"

//hooks

import Usewallet from "./hooks/UseWallet";
import WalletpopModal from './Modals/WalletPopUp';
import { getLaunchpadFee } from './actions/adminAction';



const HelperRoute = () => {
    let dispatch = useDispatch()
    const location = useLocation()

    const { connected, publicKey, wallet, disconnect } = useWallet()


    const { walletaddress } = useSelector((state) => state.wallet)
    const storageHooks = HookFunction()
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {

        setwalletStatus()

    }, [])

    useEffect(() => {
        let walletAddress = storageHooks.getWalletAddress()
        // userLogin()
        console.log("showModal", walletaddress)
        if (isEmpty(walletAddress) && !location.pathname.includes('/login')) {
            setshowModal(true)
        }
        else if (walletAddress || location.pathname.includes('/login')) {
            setshowModal(false)
        }


    }, [walletaddress, location])


    useEffect(() => {

        if (connected == false) {
            storageHooks.removeWallteAddress()
            dispatch({
                type: WALLET_ADDRESS,
                payload: ""
            })
        }

    }, [connected])



    const setwalletStatus = () => {
        try {
            if (storageHooks.getWalletAddress()) {
                dispatch({
                    type: WALLET_ADDRESS,
                    payload: storageHooks.getWalletAddress()
                })
            }
            // if (!getWalletAddress()) {
            //     sessionStorage.setItem('walletStatus', 'disconnect')
            // }
            // dispatch({
            //     type: WALLET_STATUS,
            //     payload: isEmpty(getWalletAddress()) ? 'disconnect' : sessionStorage.getItem('walletStatus')
            // })
        } catch (err) {
            console.log(err, 'setwalletStatus__err')
        }
    }

    const getDeploymentFee = async () => {
        let feeData = await getLaunchpadFee();
        console.log("feeData", feeData)
        dispatch({
            type: DEPLOYMENT_FEE_DATA,
            payload: feeData
        })
    }

    useEffect(() => {
        getDeploymentFee()
    }, [])


    // const userLogin = async () => {
    //     try {
    //         let user = await createuserhook({ walletaddress: getWalletAddress().toLocaleLowerCase() })
    //         console.log("user_wallet", user)
    //     } catch (err) {
    //         console.log('getUser__err', err)
    //     }
    // }


    // const walletconnect = () => {
    //     dispatch(walletControl("connect"));
    //     sessionStorage.setItem("walletStatus", "connect");
    // };

    // const walletdis = () => {
    //     dispatch(walletControl("disconnect"));
    //     dispatch({
    //         type: WALLET_ADDRESS,
    //         payload: ''
    //     })
    //     removeWallteAddress()
    //     sessionStorage.setItem("walletStatus", "disconnect");
    // };

    // useEffect(() => {
    //     if (window.solana) {
    //         window.solana?.on("accountChanged", walletHook.Walletconnect());
    //         return () => {
    //             window.solana?.removeListener("accountChanged",  walletHook.Walletconnect());
    //         };
    //     }
    // }, [])


    // window.addEventListener("load", async (event) => {
    //     console.log("loaddd");
    //     if (sessionStorage.getItem("wallet") == "metamask") {
    //         console.log('session_');
    //         if (window.ethereum) {
    //             window.ethereum.on("accountsChanged", async function (accounts) {
    //                 try {
    //                     console.log('hiiiiiiiii')
    //                     const connection = await MetamaskWallet(dispatch);
    //                     console.log("connection", connection);
    //                     if (connection) {
    //                         walletconnect();
    //                     }
    //                     else {
    //                         walletdis();
    //                     }
    //                 } catch (e) {
    //                     console.log("MetamaskConnectWallet", e);
    //                 }
    //             });
    //         }
    //     }
    // });
    return (
        <>
            {showModal && <WalletpopModal show={showModal} handleClose={() => { setshowModal(false) }} />}
        </>
    )
}



export default HelperRoute;