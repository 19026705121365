import React, { useContext } from 'react'
import { Container } from 'react-bootstrap'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import Footer from './Footer'
import Classes from '../asset/styles/AdminLayout.module.css'
import { sidebarContext } from '../Context/Context'

const AdminLayout = ({children}) => {
  const {sidebar,handleChange} = useContext(sidebarContext)

  return (
    <>
    <Container fluid className='p-0 custom__containerFluid'>
        <AdminHeader/>
        <div className={`${Classes.adminBodyHolder} d-flex justify-content-between position-relative`}>
        <AdminSidebar/>
        <div className={`${sidebar ? Classes.adminChildrenHolder : Classes.adminChildrenHolderShrink} p-2 p-sm-0`}>
            {children}
{/* <Footer/> */}
        </div>
        </div>
    </Container>
      
    </>
  )
}

export default AdminLayout
