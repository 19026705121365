import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { Toaster } from 'react-hot-toast';
import "bootstrap/dist/css/bootstrap.min.css";


const root = ReactDOM.createRoot(document.getElementById("root"));





root.render(
 
  <Provider store={store}>
    <App />
    <Toaster />

  </Provider>
  
);
