import React ,{useState} from 'react'
import AdminLayout from '../../../layout/AdminLayout'
import Classes from '../../../asset/styles/AdminTokenCreation.module.css'
import Select from 'react-select';
import { validstandardtoken,validliquiditytoken } from '../../../validations/userValidations'
import { useDispatch, useSelector } from 'react-redux'

const AdminTokenCreation = () => {
  const options = [
    { value: 'Standard Token', label: 'Standard Token' },
    { value: 'Liquidity Generator Token', label: 'Liquidity Generator Token' },
  ];

  const [tokentype, setTokentype] = useState("Standard Token")
  const [name, setName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [decimal, setDecimal] = useState("")
  const [totalsupply, setTotalsupply] = useState("")
  const [yieldfee, setYieldfee] = useState("")
  const [liquidityfee, setLiquidityfee] = useState("")
  const [maximumtransaction, setMaximumtransaction] = useState("")
  const [exchangethresold, setExchangethresold] = useState("")
  const [errors, setErrors] = useState({})


  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_TokenCreation", walletdetail)


  const handlecreatetoken = async () => {
    // console.log("userbalance", this.state.userbalance)

    if (tokentype == "Standard Token") {
      let payload = {
        name: name,
        symbol: symbol,
        decimal: decimal,
        totalsupply: totalsupply
      }
      let validate = await validstandardtoken(payload);
      console.log("validate",validate)
      setErrors(validate?.errors)
      if (validate?.isValid) {
        let arg = [];
        arg[0] = name;
        arg[1] = symbol;
        arg[2] = decimal;
        arg[3] = (totalsupply * 10 ** decimal).toString()
        // let tokendata = await CreateStandardToken(arg).then(async (data) => {
        //   console.log("data>>>>", data);
        //   await sleep(1000)
        //   if (data) {
        //     const tokenList = {
        //       useraddress: data?.from,
        //       tokenaddress: data?.events[0].address,
        //       name: this.state.name,
        //       symbol: this.state.symbol,
        //       decimal: this.state.decimal,
        //       type: "StandardToken"
        //     }
        //     await addTokenCreationhook(tokenList)
        //   }


        //   console.log("tokendata", data);
        //   if (data) {
        //     sessionStorage.setItem("HASH", JSON.stringify(data))
        //     this.props.history.push("/tokensuccess", { state: "token" })
        //   }
        // })
      }
      else {
        setErrors(validate?.errors)
      }
    }

    if (tokentype == "Liquidity Generator Token") {
      let payload = {
        name: name,
        symbol: symbol,
        decimal: decimal,
        totalsupply: totalsupply,
        yieldfee: yieldfee,
        liquidityfee: liquidityfee,
        maximumtransaction: maximumtransaction,
        exchangethresold:exchangethresold
      }
      console.log("payload", payload);
      let validate = await validliquiditytoken(payload);
      if (validate?.isValid) {
        let arg = [];
        arg[0] = name;
        arg[1] = symbol;
        arg[2] = decimal;
        arg[3] = totalsupply;
        arg[4] = yieldfee;
        arg[5] = liquidityfee;
        arg[6] = maximumtransaction;
        arg[7] = exchangethresold;
        // let tokendata = await CreateLiquidityToken(arg).then(async (data) => {
        //   console.log("data>>>>>>", data);
        //   await sleep(1000)
        //   if (data) {
        //     const tokenList = {
        //       useraddress: data?.from,
        //       tokenaddress: data?.events[0].address,
        //       name: this.state.name,
        //       symbol: this.state.symbol,
        //       decimal: this.state.decimal,
        //       type: "LiquidityToken"
        //     }
        //     await addTokenCreationhook(tokenList)
        //   }
        //   if (data) {
        //     sessionStorage.setItem("HASH", JSON.stringify(data))
        //     this.props.history.push("/tokensuccess", { state: "token" })
        //   }
        // })
      }
      else {
        setErrors(validate?.errors)
      }
    }
  }


  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Create Token
          </h2>
          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="presaleRate" className={`${Classes.inputLabel} orbitron`}>Token Type *</label><br />
            <Select className={`mt-3`}
              placeholder={`Ex. Select Token Type`}
              name='token'
              classNamePrefix={`cmnSelect`}
              value={tokentype ? options.find((val) => (val.value == tokentype)) : ''}
              onChange={(e) => setTokentype(e)}
              options={options}
            />

            <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>Pool creation fee: 1 SOL</p>
            {/* <span className={`cmnErrorHint`}>{errors.token && errors.token}</span> */}
          </div>


          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="name" className={`${Classes.inputLabel} orbitron`}>Name *</label><br />
            <input type="text" id="name" name="name" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} 
              value={name} onChange={(e) => setName(e.target.value)} placeholder='Ex.Ethereum' autoComplete="off" />
            <span className="text-danger f-12 d-block text-left">{errors.name}</span>
          </div>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="symbol" className={`${Classes.inputLabel} orbitron`}>Symbol *</label><br />
            <input type="text" id="symbol" name="symbol" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              value={symbol} onChange={(e) => setSymbol(e.target.value)}  placeholder='Ex. ETH' autoComplete="off" />
               <span className="text-danger f-12 d-block text-left">{errors.symbol}</span>
          </div>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="decimal" className={`${Classes.inputLabel} orbitron`}>Decimals *</label><br />
            <input type="number" id="decimal" name="decimal" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} 
             value={decimal} onChange={(e) => setDecimal(e.target.value)} placeholder='Ex. 18' autoComplete="off" />
           <span className="text-danger f-12 d-block text-left">{errors.decimal}</span>
          </div>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="totalSupply" className={`${Classes.inputLabel} orbitron`}>Total Supply *</label><br />
            <input type="number" id="totalSupply" name="totalSupply" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} 
            value={totalsupply} onChange={(e) => setTotalsupply(e.target.value)} placeholder='Ex. 1000' autoComplete="off" />
          <span className="text-danger f-12 d-block text-left">{errors.totalsupply}</span>
          </div>
          {tokentype.value === 'Liquidity Generator Token' &&
            <>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="yieldFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate yield (%) *</label><br />
                <input type="number" id="yieldFee" name="yieldFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} 
               value={yieldfee} onChange={(e) => setYieldfee(e?.target?.value)} placeholder='Ex. 50' autoComplete="off" />
              <span className="text-danger f-12 d-block text-left">{errors.yieldfee}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="liquidityFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate liquidity (%) *</label><br />
                <input type="number" id="liquidityFee" name="liquidityFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} 
                 value={liquidityfee} onChange={(e) => setLiquidityfee(e?.target?.value)} placeholder='Ex. 50' autoComplete="off" />
                <span className="text-danger f-12 d-block text-left">{errors.liquidityfee}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="maxTransaction" className={`${Classes.inputLabel} orbitron`}>Maximum Transaction Amount*</label><br />
                <input type="number" id="maxTransaction" name="maxTransaction" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                value={maximumtransaction} onChange={(e) => setMaximumtransaction(e.target.value)}  placeholder='Ex. 18' autoComplete="off" />
              <span className="text-danger f-12 d-block text-left">{errors.maximumtransaction}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="exchangeThereshold" className={`${Classes.inputLabel} orbitron`}>Exchange Thersold *</label><br />
                <input type="number" id="exchangeThereshold" name="exchangeThereshold" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                 value={exchangethresold} onChange={(e) => setExchangethresold(e.target.value)} placeholder='Ex. 18' autoComplete="off" />
                <span className={`cmnErrorHint`}>{errors.exchangeThereshold }</span>
                <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>If you keep exchange threshold is lower than adding liquidity, will be frequent</p>
              </div>
            </>
          }

          <div className={`text-center`}>
            <button className={`${Classes.gradientBtn} border-0 outline-0 mt-5 px-4 px-sm-5 py-2 orbitron`} onClick={()=>{handlecreatetoken()}}>Create Token</button>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default AdminTokenCreation
