import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/AdminLayout";
import { Pagination } from "react-bootstrap";
import { commonData } from "../../data/CommonData";
import Classes from "../../asset/styles/AdminCMSSettings.module.css";
import AdminEditCMSModal from "../../Modals/AdminEditCMSModal";
import { getAllCMSList } from "../../actions/adminAction";

const AdminCMSSettings = () => {
  const [editCMS, setEditCMS] = useState(false);
  const handleShowEditCMS = (item) => {
    setEditCMS(true);
    seteditRecord(item);
  };
  const handleCloseEditCMS = () => {
    seteditRecord({});
    setEditCMS(false);
  };
  const [cmsData, setCMSData] = useState();
  const [editRecord, seteditRecord] = useState();

  useEffect(() => {
    getCMS();
  }, []);

  const getCMS = async (e) => {
    try {
      const { status, loading, message, result } = await getAllCMSList();
      if (status == true) {
        setCMSData(result);
      }
    } catch (err) {
      console.log("🚀 ~ getCMS ~ err:", err);
    }
  };

  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}>
            CMS Settings
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Identifier</th>
                  {/* <th className="pb-3">Title</th> */}
                  <th className="pb-3">Content</th>
                  <th className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
                {cmsData && cmsData.length > 0
                  ?
                  cmsData.map((item) => {
                    return (
                      <tr className={`${Classes.gradientBorder}`}>
                        <td>
                          <p
                            className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>
                            {item?.identifier}
                          </p>
                        </td>
                        {/* <td>
                            <p
                              className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>
                              {item?.title}
                            </p>
                          </td> */}
                        <td>
                          <p
                            className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}>
                            {item?.content}
                          </p>
                        </td>

                        <td className={`text-end`}>
                          <button
                            className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`}
                            onClick={() => {
                              handleShowEditCMS(item);
                            }}>
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td colSpan={4} className="text-center b_wdth poppins pt-5">No Data Found</td>
                  </tr>
                  // "No data found"              
                }
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </AdminLayout>

      {/* modal content */}
      <AdminEditCMSModal
        show={editCMS}
        record={editRecord}
        getcms={getCMS}
        handleClose={handleCloseEditCMS}
      />
      {/* end of modal content */}
    </>
  );
};

export default AdminCMSSettings;
