import React, { useState, useEffect } from "react";
import AdminLayout from "../../layout/AdminLayout";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Classes from "../../asset/styles/AdminProfileSettings.module.css";
import { Col, Row } from "react-bootstrap";
import {
  changeAdminPassword,
  changeAdminEmail,
  getAdminInfo,
} from "../../actions/adminAction";
import { useNavigate } from "react-router-dom";
import { isEmpty, ObjectIsempty } from "../../lib/isEmpty";
import toast from "react-hot-toast";

const initialFormValue = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const AdminProfileSettings = () => {
  const [oldPasswordType, setOldPasswordType] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [newEmail, setNewEmail] = useState();


  const navigate = useNavigate();
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      if (name == "email") {
        setNewEmail(value);
      } else {
        let Formvalue = { ...formvalue, [name]: value };
        setFormvalue(Formvalue);
      }
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  useEffect(() => {
    getAdmin()
  }, [])

  const getAdmin = async () => {
    let data = await getAdminInfo();
    setNewEmail(data[0] && data[0]?.email || "")
    console.log("getAdmin", data);

  }

  const validation = (value) => {

    console.log("valuevalue", value, isEmpty(value.oldPassword?.oldPassword));

    try {
      let error = { ...errors };

      var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
      if (isEmpty(value.oldPassword)) {
        error["oldPassword"] = "Old password is required";
      }
      else {
        error["oldPassword"] = "";
      }
      if (isEmpty(value.newPassword)) {
        error["newPassword"] = "New password is required";
      }

      else if (!passwordpattern.test(value.newPassword)) {
        error["newPassword"] = " Use 8 or more characters with a mix of letters, numbers & symbols";
      } else {
        error["newPassword"] = "";
      }
      if (isEmpty(value.confirmPassword)) {
        error["confirmPassword"] = "Confirm password is required";
      }
      else if (!passwordpattern.test(value.confirmPassword)) {
        error["confirmPassword"] = " Use 8 or more characters with a mix of letters, numbers & symbols";
      } else if (value.confirmPassword != value.newPassword) {
        error["confirmPassword"] =
          "Confrim password must match with new password";
      } else {
        error["confirmPassword"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async (e) => {
    console.log("🚀 ~ handleSubmit ~ e:", e);
    try {
      let userData = {
        oldPassword: formvalue.oldPassword,
        newPassword: formvalue.newPassword,
        confirmPassword: formvalue.confirmPassword,
      };
      let isValidate = validation(userData);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await changeAdminPassword(userData);
      if (status == true) {
        setFormvalue(initialFormValue);
        setErrors({});
        toast.success("Password Changed Successfully");
        navigate("/login")
      } else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const handleEmailSubmit = async (e) => {
    try {

      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

      if (newEmail === "") {
        toast.error("Email address is requird")
        return false;

      }

      if (!emailRegex.test(newEmail)) {
        toast.error('Please provide a valid email address');

        return false;
      }



      else {
        let { status, message, result } = await changeAdminEmail({
          newEmail: newEmail,
        });

        console.log("statusstatusstatus", status, message);

        if (status == true) {
          setFormvalue(initialFormValue);
          setErrors({});
          toast.success(`${message}`);
        } else {
          toast.error(message);
        }
      }


    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}>
            Profile Settings
          </h2>
          <Row className="align-items-end justify-content-start">
            <Col sm={9} lg={8} xl={6} className="mb-3 mb-sm-0">
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="email" className={`${Classes.inputLabel} orbitron`}>
                  Email Address
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={newEmail}
                  onChange={handleChange}
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder="Ex.John@gmail.com"
                  autoComplete="off" role="presentation"
                />
              </div>
            </Col>
            <Col xl={10} className="mt-3">
              <button
                className={`${Classes.gradientBtn} border-0 outline-0`}
                onClick={(e) => handleEmailSubmit(e)}>
                Submit
              </button>
            </Col>
          </Row>

          <Row className="align-items-end justify-content-start mt-2">
            <Col sm={9} lg={8} xl={6} className="mb-3 mb-sm-0">
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="oldPassword"
                  className={`${Classes.inputLabel} orbitron`}>
                  Old Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={oldPasswordType ? `text` : `password`}
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Enter your Old password"
                    onChange={handleChange}
                    value={formvalue.oldPassword}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off" role="presentation"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setOldPasswordType(!oldPasswordType)}>
                    {!oldPasswordType ? (
                      <FaEyeSlash fontSize={18} />
                    ) : (
                      <FaEye fontSize={18} />
                    )}
                  </button>
                </div>
              </div>
              <span class="text-danger">{errors.oldPassword}</span>
            </Col>
            {/* <Col sm={3} lg={2}>
              <button className={`${Classes.gradientBtn} border-0 outline-0`}>Submit</button>
              </Col> */}
          </Row>

          <Row className="align-items-end justify-content-start">
            <Col sm={9} lg={8} xl={6} className="mb-3 mb-sm-0">
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="newPassword"
                  className={`${Classes.inputLabel} orbitron`}>
                  New Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={newPasswordType ? `text` : `password`}
                    id="newPassword"
                    name="newPassword"
                    placeholder="Enter your New password"
                    onChange={handleChange}
                    value={formvalue.newPassword}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setNewPasswordType(!newPasswordType)}>
                    {!newPasswordType ? (
                      <FaEyeSlash fontSize={18} />
                    ) : (
                      <FaEye fontSize={18} />
                    )}
                  </button>
                </div>
              </div>
              <span class="text-danger">{errors.newPassword}</span>
            </Col>
            {/* <Col sm={3} lg={2}>
              <button className={`${Classes.gradientBtn} border-0 outline-0`}>Submit</button>
              </Col> */}
          </Row>

          <Row className="align-items-end justify-content-start">
            <Col sm={9} lg={8} xl={6} className="mb-3 mb-sm-0">
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="newPassword"
                  className={`${Classes.inputLabel} orbitron`}>
                  Confrim Password
                </label>
                <br />
                <div
                  className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
                  <input
                    type={confirmPassword ? `text` : `password`}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter your Confirm password"
                    onChange={handleChange}
                    value={formvalue.confirmPassword}
                    className={`${Classes.passwordInputBox} inter_font p-2 w-100`}
                    autoComplete="off"
                  />
                  <button
                    className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`}
                    onClick={() => setConfirmPassword(!confirmPassword)}>
                    {!confirmPassword ? (
                      <FaEyeSlash fontSize={18} />
                    ) : (
                      <FaEye fontSize={18} />
                    )}
                  </button>
                </div>
              </div>
              <span class="text-danger">{errors.confirmPassword}</span>
            </Col>
            <Col xl={12} className="mt-3">
              <button
                className={`${Classes.gradientBtn} border-0 outline-0`}
                onClick={(e) => handleSubmit(e)}>
                Submit
              </button>
            </Col>
          </Row>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminProfileSettings;
