/** npm import */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

/** local files import */
import LaunchpadList from "./pages/Launchpad/LaunchpadList";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateLaunchpad from "./pages/Launchpad/CreateLaunchpad";
import CreateLock from "./pages/Tokens/CreateLock";
import LaunchpadDetail from "./pages/Launchpad/LaunchpadDetail";
import TokenCreation from "./pages/Launchpad/TokenCreation";
import TokenLock from "./pages/Tokens/TokenLock";
import LockDetail from "./pages/Tokens/LockDetail";
import CreateFairLaunch from "./pages/Launchpad/CreateFairLaunch";
import PrivateSale from "./pages/PrivateSalePages/PrivateSale";
import MyLockDetail from "./pages/Tokens/MyLockDetail";
import PrivateSaleList from "./pages/PrivateSalePages/PrivateSaleList";
import EditPrivateSale from "./pages/PrivateSalePages/EditPrivateSale";
import EditLaunchpad from "./pages/Launchpad/EditLaunchpad";
import PrivateSaleDetail from "./pages/PrivateSalePages/PrivateSaleDetail";
import MyWallet from "./pages/MyWallet";
import PremiumLaunchDetail from "./pages/PremiumLauch/PremiumLaunchDetail";
import { scroller } from "react-scroll";
import AdminLogin from "./pages/AdminPanel/AdminLogin";
import ForgotPassword from "./pages/AdminPanel/ForgotPassword";
import ResetPassword from "./pages/AdminPanel/ResetPassword";
import GlobalState from "./Context/Context";
import AdminLaunchpad from "./pages/AdminPanel/Launchpad/AdminLaunchpad";
import AdminTokenCreation from "./pages/AdminPanel/Launchpad/AdminTokenCreation";
import AdminCreateLaunchpad from "./pages/AdminPanel/Launchpad/AdminCreateLaunchpad";
import AdminLaunchpadDetail from "./pages/AdminPanel/Launchpad/AdminLaunchpadDetail";
import AdminLaunchpadList from "./pages/AdminPanel/Launchpad/AdminLaunchpadList";
import AdminEditLaunchpad from "./pages/AdminPanel/Launchpad/AdminEditLaunchpad";
import AdminCreateFairLaunch from "./pages/AdminPanel/Launchpad/AdminCreateFairLaunch";
import AdminCreatePrivateSale from "./pages/AdminPanel/AdminPrivateSale/AdminCreatePrivateSale";
import AdminEditPrivateSale from "./pages/AdminPanel/AdminPrivateSale/AdminEditPrivateSale";
import AdminPrivateSaleList from "./pages/AdminPanel/AdminPrivateSale/AdminPrivateSaleList";
import AdminKycList from "./pages/AdminPanel/AdminKycList";
import AdminSettings from "./pages/AdminPanel/AdminSettings";
import AdminLaunchpadSettings from "./pages/AdminPanel/AdminLaunchpadSettings";
import AdminProfileSettings from "./pages/AdminPanel/AdminProfileSettings";
import AdminFeeSettings from "./pages/AdminPanel/AdminFeeSettings";
import AdminCMSSettings from "./pages/AdminPanel/AdminCMSSettings";
import AdminAuditList from "./pages/AdminPanel/AdminAuditList";
import AdminCreateLaunchpadList from "./pages/AdminPanel/AdminCreateLaunchpadList";
import AdminPrivateSaleDetail from "./pages/AdminPanel/AdminPrivateSale/AdminPrivateSaleDetail";
import EmailVerification from "./pages/AdminPanel/EmailVerification";
import Kyc from "./pages/Kyc/kyc";
import HelperRoute from "./HelperRoutes";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { GlowWalletAdapter, PhantomWalletAdapter, SolflareWalletAdapter, MathWalletAdapter } from "@solana/wallet-adapter-wallets";
import "@solana/wallet-adapter-react-ui/styles.css";
import { useMemo } from "react";
import { clusterApiUrl, LAMPORTS_PER_SOL, Connection } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton, WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import HookFunction from "./hooks/useStorage";
import { isEmpty } from './lib/isEmpty';
/** code start */
import Usewallet from "./hooks/UseWallet";
import Config from './config';


const App = () => {
  const storageHooks = HookFunction()
  const { connected, publicKey, wallet } = useWallet()

  const walletHook = Usewallet()


  console.log("accountInfo", publicKey, wallet, wallet?.adapter?.name)
  const network = Config.NETWORK;


  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(() => [

    // if desired, manually define specific/custom wallets here (normally not required)
    // otherwise, the wallet-adapter will auto detect the wallets a user's browser has available

    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),

    // new GlowWalletAdapter(),
    // new MathWalletAdapter(),
  ],
    [network],
  );
  useEffect(() => {
    console.log("accountInfo1111111", publicKey)
    const connection = new Connection(clusterApiUrl(network), "confirmed");
    console.log("connection", connection)
    walletData()
  }, [connected, publicKey])


  const walletData = async () => {
    if (!isEmpty(publicKey)) {
      storageHooks.SetWallet(wallet?.adapter?.name)
      storageHooks.SetWalletAddress(publicKey)
      let bal = await walletHook.getSolBalance(publicKey)
      console.log("walletData_bal", bal, bal / LAMPORTS_PER_SOL)
      storageHooks.SetWalletBal(bal / LAMPORTS_PER_SOL)
    }
  }




  const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const section = location.hash.substring(1);
        scroller.scrollTo(section, {
          smooth: true,
          duration: 500,
          offset: -100,
        });
        setTimeout(() => {
          window.history.replaceState(null, null, window.location.pathname);
        }, 1000);
      }
    }, [location]);

    useEffect(() => {
      const handleRepeatedClicks = (e) => {
        if (
          e.target.hash &&
          e.target.hash.substring(1) === location.hash.substring(1)
        ) {
          e.preventDefault();
          const section = e.target.hash.substring(1);
          scroller.scrollTo(section, {
            smooth: true,
            duration: 500,
            offset: -100,
          });
        }
      };

      window.addEventListener("click", handleRepeatedClicks);

      return () => {
        window.removeEventListener("click", handleRepeatedClicks);
      };
    }, [location.hash]);

    return null;
  };


  return (


    <ConnectionProvider endpoint={endpoint}>

      <WalletProvider wallets={wallets} autoConnect onError={(error, adapter) => {
        console.log(adapter, 'adapter', error)
      }}>
        <WalletModalProvider>

          <BrowserRouter>
            <HelperRoute />
            <ScrollToSection />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/home/premiumSale" element={<Dashboard />} />
              <Route path="/create-launchpad" element={<CreateLaunchpad />} />
              <Route path="/create-fair-launch" element={<CreateFairLaunch />} />
              <Route path="/launchpad-list" element={<LaunchpadList />} />
              <Route path="/create-lock" element={<CreateLock />} />
              <Route path="/lp-token-lock" element={<CreateLock />} />
              <Route path="/token-lock" element={<TokenLock />} />
              <Route path="/lock-detail" element={<LockDetail />} />
              <Route path="/lock-detail/:lockAddress" element={<LockDetail />} />
              <Route path="/launchpad-detail" element={<LaunchpadDetail />} />
              <Route path="/launchpad-detail/:saleAddress" element={<LaunchpadDetail />} />
              <Route path="/token-creation" element={<TokenCreation />} />
              <Route path="/create-private-sale" element={<PrivateSale />} />
              <Route path="/mylock-detail" element={<MyLockDetail />} />
              <Route path="/mylock-detail/:lockAddress" element={<MyLockDetail />} />
              <Route path="/private-sale-list" element={<PrivateSaleList />} />
              <Route path="/edit-launchpad/:saleAddress" element={<EditLaunchpad />} />
              <Route path="/edit-private-sale/:saleAddress" element={<EditPrivateSale />} />
              <Route path="/private-sale-detail/:saleAddress" element={<PrivateSaleDetail />} />
              <Route path="/premium-sale-detail/:saleAddress" element={<PremiumLaunchDetail />} />
              <Route path="/my-wallet" element={<MyWallet />} />
              <Route path="/kyc" element={<Kyc />} />
            </Routes>

            <GlobalState>
              <Routes>
                {/* admin routes */}

                <Route path="/login" element={<AdminLogin />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/email-verification/:authToken"
                  element={<EmailVerification />}
                />
                <Route
                  path="/verify-old-email/:authToken"
                  element={<EmailVerification />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/launchpad-admin" element={<AdminLaunchpad />} />
                <Route
                  path="/admin-token-creation"
                  element={<AdminTokenCreation />}
                />
                <Route
                  path="/admin-create-launchpad"
                  element={<AdminCreateLaunchpad />}
                />
                <Route
                  path="/admin-launchpad-detail"
                  element={<AdminLaunchpadDetail />}
                />
                <Route
                  path="/admin-launchpad-detail/:saleAddress"
                  element={<AdminLaunchpadDetail />}
                />

                <Route
                  path="/admin-launchpad-list"
                  element={<AdminLaunchpadList />}
                />
                <Route
                  path="/admin-edit-launchpad"
                  element={<AdminEditLaunchpad />}
                />
                <Route
                  path="/admin-edit-launchpad/:saleAddress"
                  element={<AdminEditLaunchpad />}
                />
                <Route
                  path="/admin-create-fairlaunch"
                  element={<AdminCreateFairLaunch />}
                />
                <Route
                  path="/admin-create-privatesale"
                  element={<AdminCreatePrivateSale />}
                />
                <Route
                  path="/admin-edit-privatesale/:saleAddress"
                  element={<AdminEditPrivateSale />}
                />
                <Route
                  path="/admin-privatesale-detail/:saleAddress"
                  element={<AdminPrivateSaleDetail />}
                />
                <Route
                  path="/admin-privatesale-list"
                  element={<AdminPrivateSaleList />}
                />
                <Route path="/admin-kyc" element={<AdminKycList />} />
                <Route path="/admin-settings" element={<AdminSettings />} />
                <Route
                  path="/admin-launchpad-settings"
                  element={<AdminLaunchpadSettings />}
                />
                <Route
                  path="/admin-profile-settings"
                  element={<AdminProfileSettings />}
                />
                <Route path="/admin-fee-settings" element={<AdminFeeSettings />} />
                <Route path="/admin-CMS" element={<AdminCMSSettings />} />
                <Route path="/admin-audit" element={<AdminAuditList />} />
                <Route
                  path="/admin-created-launchpad-list"
                  element={<AdminCreateLaunchpadList />}
                />
                {/* end of admin routes */}
              </Routes>
            </GlobalState>
          </BrowserRouter>

        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
