// import constant
import {
    WALLET_ADDRESS,
    WALLET_DATA,
    DEPLOYMENT_FEE_DATA
} from '../constants';


const initialValue = {
    walletaddress: '',
    walletData: {},
    feeData : {}
}

//  console.log("initialValue----->>>",initialValue);
const wallet = (state = initialValue, action) => {

    switch (action.type) {
        case WALLET_ADDRESS:
            return {
                ...state,
                walletaddress: action.payload
            };
        case WALLET_DATA:
            return {
                ...state,
                walletData: action.payload
            };
        case DEPLOYMENT_FEE_DATA:
            return {
                ...state,
                feeData: action.payload
            };
        default:
            return state;
    }
}

export default wallet;