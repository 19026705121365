/* for Get Program Instance NPM */
import * as anchor from '@project-serum/anchor';
import { useWallet } from "@solana/wallet-adapter-react";
import { Program, AnchorProvider, web3, BN } from "@project-serum/anchor";
import { useDispatch, useSelector } from 'react-redux';
/* Core Solana NPM funtions */
import {
    Connection,
    LAMPORTS_PER_SOL,
    Transaction,
    clusterApiUrl,
    PublicKey,
    Keypair,
    TransactionInstruction,
} from "@solana/web3.js";


/* Core Solana SPL token function NPM */
import {
    createTransferInstruction,
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction,
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    createApproveInstruction,
    approveChecked,
    getAccount,
    getOrCreateAssociatedTokenAccount
} from "@solana/spl-token";

import Config from '../config';
import launchpad from "../config/abi/TestLaunch.json"
import Usewallet from './UseWallet';
import idl from '../config/abi/launchpad.json'
import { useaddWhiteListSale, userlaunchpadhook } from '../actions/userAction';
import { programs } from '@metaplex/js';
import { isEmpty } from '../lib/isEmpty';
import { getLaunchpadFee } from '../actions/adminAction';

const { metadata: { Metadata } } = programs;

const { SystemProgram } = anchor.web3;



export default function Usesale() {
    // const walletdetail = useSelector((state) => state.wallet)
    const network = Config.NETWORK;
    const { wallet, publicKey } = useWallet()
    // console.log("useSale_accountInfo", network, wallet, publicKey, wallet?.adapter?.name)
    
    const walletHook = Usewallet()
    
    let provider = walletHook.UseProvider()

    /**
 * @function CreateAssociatedTokenAccount
 * @param {ADD ,tokenadd, signer}  
 * @returns {AssociatedTokenAccount}
 */

    const createATA = async (ADD, tokenadd, signer) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("createATAsssssssssss", ADD, tokenadd, signer)
                const connection = new Connection(clusterApiUrl(network), 'confirmed');
                const publicKey = ADD;
                const mintAddress = tokenadd; // TOKEN ADDRESS
                // Calculate the associated token account address
                const ata = await getAssociatedTokenAddress(
                    mintAddress,
                    publicKey,
                    true, // Allow owner off curve
                    TOKEN_PROGRAM_ID, //new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                    ASSOCIATED_TOKEN_PROGRAM_ID
                );
                console.log('ata-->', ata.toBase58())
                // Check if the associated token account already exists
                const ataInfo = await connection.getAccountInfo(ata);
                console.log('ataInfo-->', ataInfo,)
                //  resolve(ata.toBase58())
                //  return;
                if (ataInfo) {
                    console.log(`Associated token account already exists: ${ata.toBase58()}`);
                    // return ata.toBase58();
                    return resolve(ata.toBase58())
                }

                // Create the associated token account
                const transaction = new Transaction().add(
                    createAssociatedTokenAccountInstruction(
                        signer, // payer (connected wallet address)
                        ata, // associated token account
                        publicKey, // token account owner
                        mintAddress, // token mint
                        TOKEN_PROGRAM_ID,//new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                        ASSOCIATED_TOKEN_PROGRAM_ID
                    )
                );
                console.log('transaction-->', transaction)
                // Sign and send the transaction (assuming you have the public key's private key)
                // Here, replace `YOUR_PRIVATE_KEY` with the actual private key of the public key
                transaction.feePayer = new PublicKey(signer);
                const { blockhash } = await connection.getLatestBlockhash();
                transaction.recentBlockhash = blockhash;
                console.log('blockhash-->', blockhash, provider.wallet)
                const signed = await provider?.wallet?.signTransaction(transaction);
                console.log('signed-->', signed, signed.serialize())
                const signature = await connection.sendRawTransaction(signed.serialize());
                console.log('signature-->', signature)
                let signhash = await connection.confirmTransaction(signature);
                console.log('signhash-->', signhash)
                // return ataInfo
                resolve(ata.toBase58())
            } catch (error) {
                console.error('Error creating associated token account:', error);
                reject(error)
                return null
            }
        })
    };





    /**
    * @function createPresale
    * @param {tokenMintAddress,token, values, setters, details, account}  
    * @returns  { status : true or false}
    */

    const createSale = async (tokenMintAddress, token, values, setters, details, account) => {

        try {

            console.log("accountInfo", token, values, setters, details, account)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            console.log("connection", connection)
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ createSale ~ anchorProgram:", anchorProgram)

            const presaleAccountSeed = [Buffer.from("PRESALE_SEED"), tokenMintAddress.toBuffer(), publicKey.toBuffer()];
            const [presaleAccount, _] = await PublicKey.findProgramAddress(
                presaleAccountSeed,
                programId
            );

            let transaction = await anchorProgram.methods.createPresale(
                token, values, setters, details, account
            ).accounts({
                presaleAccount: presaleAccount,
                authority: new PublicKey(account),
                tokenMintAddress: tokenMintAddress,
                systemProgram: SystemProgram.programId
            }).transaction()
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash, transaction)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed, connection)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }
        }

        catch (err) {
            console.log("🚀 ~ createSale ~ error:", err)
            return { status: false }
        }
    }

    /**
* @function updatePresale
* @param {updateData}  
* @returns  { status : true or false}
*/

    const updateSale = async (tokenMintAddress, updateData) => {

        try {

            console.log("accountInfo", publicKey)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ updateSale ~ anchorProgram:", anchorProgram)

            const presaleAccountSeed = [Buffer.from("PRESALE_SEED"), tokenMintAddress.toBuffer(), publicKey.toBuffer()];
            const [presaleAccount, _] = await PublicKey.findProgramAddress(
                presaleAccountSeed,
                programId
            );


            let transaction = await anchorProgram.methods.updatePresale(updateData)
                .accounts(
                    {
                        presaleAccount: presaleAccount,
                        authority: publicKey,
                        tokenMintAddress: tokenMintAddress,
                        systemProgram: SystemProgram.programId
                    }
                ).transaction()

            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ updateSale ~ error:", err)
            return { status: false }
        }
    }

    /**
* @function depositToken
* @param {amount}  
* @returns  { status : true or false}
*/

    const depositToken = async (tokenMintAddress, amount) => {

        try {

            console.log("accountInfo", publicKey, tokenMintAddress, amount)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, { preflightCommitment: 'confirmed' });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ depositToken ~ anchorProgram:", anchorProgram)

            let FetchAccount = (await anchorProgram.account.presaleAccount.all())[0]
            console.log("FetchAccount", FetchAccount)

            const presaleAccountSeed = [Buffer.from("PRESALE_SEED"), tokenMintAddress.toBuffer(), publicKey.toBuffer()];
            const [presaleAccount, bump] = await PublicKey.findProgramAddress(
                presaleAccountSeed,
                programId
            );
            console.log("presaleAccount", presaleAccount)

            let fromAssociatedTokenAccount_ATA = await createATA(publicKey, tokenMintAddress, publicKey)
            console.log("fromAssociatedTokenAccount_acctA", fromAssociatedTokenAccount_ATA)

            let toAssociatedTokenAccount_ATA = await createATA(FetchAccount.publicKey, tokenMintAddress, publicKey)
            console.log("toAssociatedTokenAccount_acctA", toAssociatedTokenAccount_ATA)

            const SYSVAR_RENT_PUBKEY = new PublicKey('SysvarRent111111111111111111111111111111111');

            let transaction = await anchorProgram.methods.depositToken(amount)
                .accounts(
                    {
                        tokenMintAddress: tokenMintAddress,
                        fromAssociatedTokenAccount: fromAssociatedTokenAccount_ATA,
                        authority: publicKey,
                        toAssociatedTokenAccount: toAssociatedTokenAccount_ATA,
                        presaleAccount: presaleAccount,
                        rent: SYSVAR_RENT_PUBKEY,
                        systemProgram: SystemProgram.programId,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        associatedtokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID
                    }
                ).transaction()


            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ depositToken ~ error:", err)
            return { status: false }
        }
    }

    /**
    * @function startPresale
    * @param {startTime, endTime}  
    * @returns  { status : true or false}
    */

    const startSale = async (tokenMintAddress, startTime, endTime) => {

        try {

            console.log("accountInfo", publicKey)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ startSale ~ anchorProgram:", anchorProgram)


            const presaleAccountSeed = [Buffer.from("PRESALE_SEED"), tokenMintAddress.toBuffer(), publicKey.toBuffer()];
            const [presaleAccount, bump] = await PublicKey.findProgramAddress(
                presaleAccountSeed,
                programId
            );
            console.log("presaleAccount", presaleAccount)


            let transaction = await anchorProgram.methods.startPresale(
                startTime,
                endTime
            )
                .accounts
                (
                    {
                        presaleAccount: presaleAccount,
                        tokenMintAddress: tokenMintAddress,
                        authority: publicKey,
                        systemProgram: SystemProgram.programId
                    }
                )
                .transaction()


            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ startSale ~ error:", err)
            return { status: false }
        }
    }


    /**
* @function buyToken
* @param {tokenMintAddress, useramount, isMax, maxNumber, account, decimal, rewardAmount, tokensymbol}  
* @returns  { status : true or false}
*/

    const Buy = async (tokenMintAddress, useramount, isMax, maxNumber, account, decimal, rewardAmount, tokensymbol) => {
        // have to add buytype for launch & private

        try {
            console.log("accountInfo", publicKey, tokenMintAddress, useramount, isMax, maxNumber, account, decimal, rewardAmount, tokensymbol)

            const amount = !isMax ? new anchor.BN(useramount * 10 ** decimal) : new anchor.BN(maxNumber * 10 ** decimal);
            console.log("MaxVC saleInfo.buytype", amount, maxNumber)


            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ Buy ~ anchorProgram:", anchorProgram)

            const SYSVAR_RENT_PUBKEY = new PublicKey('SysvarRent111111111111111111111111111111111');

            let FetchAccount = (await anchorProgram.account.presaleAccount.all())[0]
            console.log("FetchAccount", FetchAccount, FetchAccount.account.authority)

            let auth = FetchAccount.account.authority
            const presaleAccountSeed = [Buffer.from("PRESALE_SEED"), tokenMintAddress.toBuffer(), auth.toBuffer()];
            const [presaleAccount, bump] = await PublicKey.findProgramAddress(
                presaleAccountSeed,
                programId
            );
            console.log("presaleAccount", presaleAccount)

            const purchaseCount = 123;  // Example purchase count, must be between 0 and 255
            // const purchaseCountBytes = numberToLeBytes(purchaseCount,1);
            const purchaseCountBytes = new Uint8Array([purchaseCount]);  // purchaseCount must be between 0-255


            // Convert purchaseCount to a Buffer
            // const purchaseCountBuffer = Buffer.alloc(1); // 1 byte for u8
            // purchaseCountBuffer.writeUInt8(purchaseCount); // Write the value as an unsigned 8-bit integer
            // for (let bumps= 255; bumps >= 0; bumps--) {

            const name = "e";  // Replace with your actual name
            const nameBytes = Buffer.from(name, 'utf-8');


            const userAccountSeed = [Buffer.from('USER_SEED'), tokenMintAddress.toBuffer(), publicKey.toBuffer(), nameBytes]; // Seed used in the program
            console.log("USER_SEED", userAccountSeed)
            const [userAccount, userBump] = await PublicKey.findProgramAddress(
                userAccountSeed,
                programId
            );

            console.log("userAccount", userAccount)

            let transaction = await anchorProgram.methods.buyToken(tokenMintAddress, amount, presaleAccount, publicKey, name, userBump)
                .accounts({
                    presaleAccount: presaleAccount,
                    buyer: publicKey,
                    userAccount: userAccount,
                    tokenMintAddress: tokenMintAddress,
                    authority: auth,
                    rent: SYSVAR_RENT_PUBKEY,
                    systemProgram: SystemProgram.programId,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    associatedtokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID
                }).transaction()  // Add the userAccount as a signer

            console.log("transaction", transaction)
            transaction.feePayer = publicKey
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ Buy ~ error:", err)
            return { status: false }
        }
    }


    /**
    * @function claimToken
    * @param {bump, presaleTokenMintAccount,buyerPresaleTokenAssociatedTokenAccount,presalePresaleTokenAssociatedTokenAccount, presaleAuthority, buyer}  
    * @returns  { status : true or false}
    */

    const claimToken = async (tokenMintAddress, bump, presaleTokenMintAccount, buyerPresaleTokenAssociatedTokenAccount, presalePresaleTokenAssociatedTokenAccount, presaleAuthority, buyer) => {

        try {

            console.log("accountInfo", publicKey)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ claimToken ~ anchorProgram:", anchorProgram)

            let buyerPresaleTokenAssociatedTokenAccount_acctA = await createATA(buyerPresaleTokenAssociatedTokenAccount, tokenMintAddress, presaleAuthority)
            console.log("buyerPresaleTokenAssociatedTokenAccount_acctA", buyerPresaleTokenAssociatedTokenAccount_acctA)

            let presalePresaleTokenAssociatedTokenAccount_acctA = await createATA(presalePresaleTokenAssociatedTokenAccount, tokenMintAddress, presaleAuthority)
            console.log("presalePresaleTokenAssociatedTokenAccount_acctA", presalePresaleTokenAssociatedTokenAccount_acctA)

            const SYSVAR_RENT_PUBKEY = new PublicKey('SysvarRent111111111111111111111111111111111');

            const PRESALE_SEED = Buffer.from('PRESALE_SEED'); // Seed used in the program
            console.log("PRESALE_SEED", PRESALE_SEED)
            const [presaleInfoPublicKey, bump] = await PublicKey.findProgramAddress(
                [PRESALE_SEED],
                programId
            );
            console.log("🚀 ~ createSale ~ presaleInfoPublicKey:", presaleInfoPublicKey, bump, presaleInfoPublicKey.toString())

            const USER_SEED = Buffer.from('USER_SEED'); // Seed used in the program
            console.log("USER_SEED", USER_SEED)
            const [userInfoPublicKey] = await PublicKey.findProgramAddress(
                [USER_SEED],
                programId
            );
            console.log("🚀 ~ createSale ~ userInfoPublicKey:", userInfoPublicKey, userInfoPublicKey.toString())

            let transaction = await anchorProgram.methods.claimToken(bump)
                .accounts(
                    {
                        presaleTokenMintAccount: presaleTokenMintAccount,
                        buyerPresaleTokenAssociatedTokenAccount: buyerPresaleTokenAssociatedTokenAccount_acctA,
                        presalePresaleTokenAssociatedTokenAccount: presalePresaleTokenAssociatedTokenAccount_acctA,
                        userInfo: userInfoPublicKey,
                        presaleInfo: presaleInfoPublicKey,
                        presaleAuthority: presaleAuthority,
                        buyer: buyer,
                        rent: SYSVAR_RENT_PUBKEY
                    }
                )
                .transaction()


            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getRecentBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ claimToken ~ error:", err)
            return { status: false }
        }
    }

    /**
* @function withdrawSol
* @param {amount, bump, tokenMintAddress}  
* @returns  { status : true or false}
*/

    const withdrawSol = async (tokenMintAddress, amount, bump) => {

        try {

            console.log("accountInfo", publicKey)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ withdrawSol ~ anchorProgram:", anchorProgram)

            const PRESALE_VAULT = Buffer.from('PRESALE_VAULT'); // Seed used in the program
            console.log("PRESALE_VAULT", PRESALE_VAULT)
            const [presaleVaultPublicKey] = await PublicKey.findProgramAddress(
                [PRESALE_VAULT],
                programId
            );
            console.log("🚀 ~ createSale ~ presaleVaultPublicKey:", presaleVaultPublicKey, presaleVaultPublicKey.toString())

            let transaction = await anchorProgram.methods.withdrawSol(tokenMintAddress, amount, bump)
                .accounts({
                    presaleAccount: new PublicKey('6ih59meu8RrJ9smoXmaDGSDGodRkbBxuqoobynt8eXdC'),
                    presaleVault: presaleVaultPublicKey,
                    admin: wallet,
                    systemProgram: SystemProgram.programId
                }).signers([wallet]).transaction()

            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ withdrawSol ~ error:", err)
            return { status: false }
        }
    }


    /**
    * @function withdrawToken
    * @param {amount, bump, presaleInfo, presaleVault, admin}  
    * @returns  { status : true or false}
    */

    const withdrawToken = async (tokenMintAddress, amount, mintAccount, adminAssociatedTokenAccount, presaleAssociatedTokenAccount, presaleTokenMintAccount, presaleInfo, adminAuthority, rent) => {

        try {

            console.log("accountInfo", publicKey)

            const connection = new Connection(clusterApiUrl(network), "confirmed");
            const programId = new PublicKey(launchpad.metadata.address);

            const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
                preflightCommitment: 'confirmed',
            });
            console.log("anchorProvider", anchorProvider)

            const anchorProgram = new anchor.Program(launchpad, programId, anchorProvider);
            console.log("🚀 ~ withdrawToken ~ anchorProgram:", anchorProgram)

            let adminAssociatedTokenAccount_acctA = await createATA(adminAssociatedTokenAccount, tokenMintAddress, adminAuthority)
            console.log("adminAssociatedTokenAccount_acctA", adminAssociatedTokenAccount_acctA)

            let presaleAssociatedTokenAccount_acctA = await createATA(presaleAssociatedTokenAccount, tokenMintAddress, adminAuthority)
            console.log("presaleAssociatedTokenAccount_acctA", presaleAssociatedTokenAccount_acctA)

            const SYSVAR_RENT_PUBKEY = new PublicKey('SysvarRent111111111111111111111111111111111');

            const PRESALE_SEED = Buffer.from('PRESALE_SEED'); // Seed used in the program
            console.log("PRESALE_SEED", PRESALE_SEED)
            const [presaleInfoPublicKey, bump] = await PublicKey.findProgramAddress(
                [PRESALE_SEED],
                programId
            );
            console.log("🚀 ~ createSale ~ presaleInfoPublicKey:", presaleInfoPublicKey, bump, presaleInfoPublicKey.toString())


            let transaction = await anchorProgram.methods.withdrawToken(amount, bump)
                .accounts(
                    {
                        mintAccount: mintAccount,
                        adminAssociatedTokenAccount: adminAssociatedTokenAccount_acctA,
                        presaleAssociatedTokenAccount: presaleAssociatedTokenAccount_acctA,
                        presaleTokenMintAccount: presaleTokenMintAccount,
                        presaleInfo: presaleInfoPublicKey,
                        adminAuthority: adminAuthority,
                        rent: SYSVAR_RENT_PUBKEY,
                    }
                )
                .transaction()


            console.log("transaction", transaction)
            transaction.feePayer = publicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('blockhash-->', blockhash)
            const signed = await wallet?.adapter?.signTransaction(transaction);
            console.log('signed-->', signed)
            const signature = await connection.sendRawTransaction(signed.serialize());
            console.log('signature-->', signature)
            if (signature) {
                console.log("TransactionHash", `https://explorer.solana.com/tx/${signature}?cluster=devnet`)
            }
            let signhash = await connection.confirmTransaction(signature);
            console.log('signhash-->', signhash)
            let status = await walletHook?.getTransactionstatus(signature)
            return { status }

        }
        catch (err) {
            console.log("🚀 ~ withdrawToken ~ error:", err)
            return { status: false }
        }
    }


    const UseTokenAccountPerAddress = async (UserAddress, TokenAddress, signer) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("createATAsssssssssss", UserAddress, TokenAddress, signer)
                const connection = new Connection(clusterApiUrl(network), 'confirmed');
                const publicKey = UserAddress;
                const mintAddress = TokenAddress; // TOKEN ADDRESS

                /* 
                  To create an ATA for a PDA you can set the allowOwnerOffCurve to true for getAssociatedTokenAddress function once you have the address you can call createAssociatedTokenAccountInstruction you also have the option to create the ATA from the program itself
                */

                // Calculate the associated token account address
                const ata = await getAssociatedTokenAddress(
                    mintAddress,
                    publicKey,
                    true, // Allow owner off curve set to false by default
                    TOKEN_PROGRAM_ID, //new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                    ASSOCIATED_TOKEN_PROGRAM_ID
                );
                console.log('ata-->', ata.toBase58())
                // Check if the associated token account already exists
                const ataInfo = await connection.getAccountInfo(ata);
                console.log('ataInfo-->', ataInfo,)
                if (ataInfo) {
                    console.log(`Associated token account already exists: ${ata.toBase58()}`);
                    // return ata.toBase58();
                    return resolve(ata)
                }

                // Create the associated token account
                const transaction = new Transaction().add(
                    createAssociatedTokenAccountInstruction(
                        signer, // payer (connected walllet address)
                        ata, // associated token account
                        publicKey, // token account owner
                        mintAddress, // token mint
                        TOKEN_PROGRAM_ID,//new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                        ASSOCIATED_TOKEN_PROGRAM_ID
                    )
                );
                console.log('transaction-->', transaction)
                // Sign and send the transaction (assuming you have the public key's private key)
                // Here, replace `YOUR_PRIVATE_KEY` with the actual private key of the public key
                transaction.feePayer = new PublicKey(signer);
                const { blockhash } = await connection.getLatestBlockhash();
                transaction.recentBlockhash = blockhash;
                console.log('blockhash-->', blockhash)
                const signed = await window.solana.signTransaction(transaction);
                console.log('signed-->', signed, signed.serialize())
                const signature = await connection.sendRawTransaction(signed.serialize());
                console.log('signature-->', signature)
                let signhash = await connection.confirmTransaction(signature);
                console.log('signhash-->', signhash)
                // return ataInfo

                const newata = await getAssociatedTokenAddress(
                    mintAddress,
                    publicKey,
                    true, // Allow owner off curve set to false by default 
                    TOKEN_PROGRAM_ID, //new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                    ASSOCIATED_TOKEN_PROGRAM_ID
                );

                resolve(newata)
            } catch (error) {
                console.error('Error creating associated token account:', error);
                reject(error)
                return null
            }
        })
    };

    const UsePresalePerUsersToken = async (program, token, user) => {
        // here we need to use database to store presale addresses
        const [presaleMachine, _] = await web3.PublicKey.findProgramAddress([Buffer.from("vending-machine"), user.toBuffer(), token.toBuffer()], program.programId);
        return presaleMachine;
    }

    const UseUserAddressPerPresale = async (program, sale, user) => {
        const [userAddress, _] = await web3.PublicKey.findProgramAddress([Buffer.from("booking"), user.toBuffer(), sale.toBuffer()], program.programId);
        return userAddress;
    }

    const getDeploymentFee = async () =>{
        let feeData = await getLaunchpadFee();
        console.log("feeData",feeData)
        return feeData
    }

    const UseCreateSale = async (info) => {

        console.log("infooooooooo", info, info?.LaunchType);

        try {
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(info.tokenAddress)
            // const deploymentFee = new BN(`${Config?.deploymentFee * LAMPORTS_PER_SOL}`)
            const getdeploymentFee = await getDeploymentFee()
            const deploymentFee = new BN(`${getdeploymentFee[0]?.FeeAmount * LAMPORTS_PER_SOL}`)
            console.log("deploymentFee ", deploymentFee,getdeploymentFee[0]?.FeeAmount, "Config.fundReciever", Config.fundReciever)
            const presaleMachine = await UsePresalePerUsersToken(program, tokenMint, publicKey)
            console.log("presaleMachine : ", presaleMachine, presaleMachine.toBase58())
            console.log("INPUTS : ", info, Config.LAUNCH_PROGRAM_ID)
            let result = await program?.rpc?.createMachine(
                info.ppt,
                info.softcapAmount,
                info.hardcapAmount,
                info.minimumBuy,
                info.maximumBuy,
                info.islisting,
                info.lockingdays,
                info.listingRate,
                info.liquidityPercent,
                info.isvested,
                info.vestingdays,
                info.vestingPeriod,
                info.startDate,
                info.endDate,
                info.currencyRaised,
                info.fee,
                info.tokenSoldFee,
                info.LaunchType,
                info.isWhitelist,
                deploymentFee,
                info.useWithToken,
                info.buyMint,

                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        authority: provider.wallet.publicKey,
                        splMint: tokenMint,
                        fundReciever: Config.fundReciever,
                        systemProgram: SystemProgram.programId,

                    },
                });
            console.log("result", result)
     

            let data = {
                walletAddress: publicKey,
                saleAddress: presaleMachine.toBase58(),
                tokenAddress: info.tokenAddress,
                launchType: info.LaunchType,
                description: info.description,
                logo: info.logo,
                banner: info.banner,
                website: info.website,
                youtube: info.youtube,
                twitter: info.twitter,
                telegram: info.telegram,
                discord: info.discord,
                github: info.github,
                instagram: info.instagram,
                reddit: info.reddit,
                name : info?.name,
                symbol : info?.symbol
            }
            let launchData = await userlaunchpadhook(data)
            console.log("launchData", launchData,info, info.LaunchType)


            if (result && info.LaunchType == "Private Sale") {
                let data = {
                    sale: presaleMachine.toBase58(),
                    isWhiteListed: true
                }

                let newsale = await useaddWhiteListSale(data)


            }

            if (result) {
                return {
                    status: true,
                    message: `Sale Created Successfully`,
                    token: tokenMint.toBase58(),
                    sale: presaleMachine.toBase58()
                }
            }
            else {
                return {
                    status: false
                }
            }

        }
        catch (err) {
            console.log("UseCreateSale_err", err)
        }
    }

    const UseSaleInfo = async (saleAddress) => {
        try {
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const presaleMachine = new PublicKey(saleAddress);
            const saleInfo = await program.account.vendingMachine.fetch(presaleMachine);
            console.log("UseSaleInfo", saleInfo)
            return saleInfo;
        }
        catch (err) {
            console.log("UseSaleInfo_err", err)
        }
    }

    const UseTotalSaleInfo = async () => {
        try {
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const saleInfos = await program.account.vendingMachine.all();
            console.log("UseSaleInfo_vendingMachine", saleInfos)
            return saleInfos.reverse();
        }
        catch (err) {
            console.log("UseTotalSaleInfo_err", err)
        }
    }

    const UseUserBookings = async (saleAddress,walletaddress) => {
        try {
            console.log("saleAddress", saleAddress,walletaddress)
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const presaleMachine = new PublicKey(saleAddress);
            const userAddress = new PublicKey(walletaddress);
            const userBooking = await UseUserAddressPerPresale(program, presaleMachine, userAddress);
            console.log("userBooking", userBooking)
            const userBookingInfo = await program.account.booking.fetch(userBooking);
            console.log("userBookingInfo", userBookingInfo)
            return userBookingInfo;
        }
        catch (err) {
            console.log("UseUserBookings_err", err)
        }
    }

    const UseDepositFunds = async (address, depositAmount) => {
        try {
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(address)
            const presaleMachine = await UsePresalePerUsersToken(program, tokenMint, provider.wallet.publicKey)
            const vending_machine_ata = await UseTokenAccountPerAddress(presaleMachine, tokenMint, provider.wallet.publicKey);
            const authority_ata = await UseTokenAccountPerAddress(provider.wallet.publicKey, tokenMint, provider.wallet.publicKey);
            const fundReciever_ata = await UseTokenAccountPerAddress(Config.fundReciever, tokenMint, provider.wallet.publicKey);
            console.log("vending_machine_ata ,authority_ata ", vending_machine_ata, authority_ata, fundReciever_ata)
            let amount = new BN(`${depositAmount * LAMPORTS_PER_SOL}`);
            const tx = await program.rpc.fundMachine(
                amount,
                {
                    accounts: {
                        authority: provider.wallet.publicKey,
                        vendingMachine: presaleMachine,
                        splMint: tokenMint,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        authoritySplAta: authority_ata,
                        vendingMachineSplAta: vending_machine_ata,
                        fundRecieverSplAta: fundReciever_ata,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                    },
                });

            console.log("Deposited", `${depositAmount / LAMPORTS_PER_SOL} Deposited successfully !!`, tx, presaleMachine.toBase58())

            if (tx) {
                return {
                    status: true,
                    message: `${depositAmount / LAMPORTS_PER_SOL} Deposited successfully !!`,
                    token: tokenMint.toBase58(),
                    sale: presaleMachine.toBase58()
                }
            }
            else {
                return {
                    status: false

                }
            }
        }
        catch (err) {
            console.log("UseDepositFunds_err", err)
        }
    }

    const UseBookToken = async (quantity, tokenProgram, saleAddress) => {
        try {
            console.log("UseBookToken", quantity, tokenProgram, saleAddress, new BN(`${quantity}`).toString())
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram);
            const presaleMachine = new PublicKey(saleAddress);
            const saleInfo = await program.account.vendingMachine.fetch(presaleMachine.toBase58());
            console.log("UseBookToken_saleInfo", saleInfo)
            const userBooking = await UseUserAddressPerPresale(program, presaleMachine, provider.wallet.publicKey);
            let amount = new BN(`${quantity}`);
            console.log("UseBookToken_amount", amount.toString())
            const tx = await program.rpc.bookTokens(
                amount,
                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        authority: saleInfo.authority, // owner of the presale generally
                        user: provider.wallet.publicKey, // actual buyer
                        fundReciever: Config.fundReciever,
                        systemProgram: SystemProgram.programId,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                        splMint: tokenMint,
                        booking: userBooking
                    },
                });

            console.log("quantity : ", quantity, tx)

            if (tx) {
                return {
                    status: true,
                    message: `${quantity / LAMPORTS_PER_SOL} Booked successfully !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }
            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseBookToken_err", err)
        }
    }

    const UseBookTokenFairLaunch = async (quantity, tokenProgram, saleAddress) => {
        try {
            console.log("UseBookToken", quantity, tokenProgram, saleAddress, new BN(`${quantity}`).toString())
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram);
            const presaleMachine = new PublicKey(saleAddress);
            const saleInfo = await program.account.vendingMachine.fetch(presaleMachine.toBase58());
            console.log("UseBookToken_saleInfo", saleInfo)
            const userBooking = await UseUserAddressPerPresale(program, presaleMachine, provider.wallet.publicKey);
            let amount = new BN(`${quantity}`);
            console.log("UseBookToken_amount", amount)
            const tx = await program.rpc.bookTokensFairLaunch(
                amount,
                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        authority: saleInfo.authority, // owner of the presale generally
                        user: provider.wallet.publicKey, // actual buyer
                        fundReciever: Config.fundReciever,
                        systemProgram: SystemProgram.programId,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                        splMint: tokenMint,
                        booking: userBooking
                    },
                });

            console.log("quantity : ", quantity, tx)

            if (tx) {
                return {
                    status: true,
                    message: `${quantity / LAMPORTS_PER_SOL} Booked successfully !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }
            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseBookTokenFairLaunch_err", err)
        }
    }


    const UseBookTokenUseWithToken = async (quantity, tokenProgram, buyMintAdd, saleAddress) => {
        try {
            console.log("UseBookTokenUseWithToken", quantity, tokenProgram, saleAddress, new BN(`${quantity}`).toString())
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram);
            const buyMint =  new PublicKey(buyMintAdd)
            const presaleMachine = new PublicKey(saleAddress);
            const saleInfo = await program.account.vendingMachine.fetch(presaleMachine.toBase58());
            console.log("UseBookToken_saleInfo", saleInfo)
            const userBooking = await UseUserAddressPerPresale(program, presaleMachine, provider.wallet.publicKey);
            // const vending_machine_ata = await UseTokenAccountPerAddress(presaleMachine, tokenMint, provider.wallet.publicKey);
            const authority_ata = await UseTokenAccountPerAddress(saleInfo.authority, buyMint, provider.wallet.publicKey);
            const fundReciever_ata = await UseTokenAccountPerAddress(Config.fundReciever, tokenMint, provider.wallet.publicKey);
            const buyer_spl_ata = await UseTokenAccountPerAddress(provider.wallet.publicKey, buyMint, provider.wallet.publicKey);
            console.log("vending_machine_ata ,authority_ata ", userBooking, authority_ata, fundReciever_ata,buyer_spl_ata)
            let amount = new BN(`${quantity}`);
            console.log("UseBookToken_amount", amount)
            const tx = await program.rpc.bookTokensUseTokens(
                amount,
                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        booking: userBooking,
                        buyerSplAta: buyer_spl_ata,
                        authoritySplAta: authority_ata,
                        fundRecieverSplAta: fundReciever_ata,
                        signer: provider.wallet.publicKey, // actual buyer
                        authority: saleInfo.authority, // owner of the presale generally
                        fundReciever: Config.fundReciever,
                        splMint: buyMint,
                        saleMint : tokenMint,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        rent: web3.SYSVAR_RENT_PUBKEY,   
                    },
                });

            console.log("quantity : ", quantity, tx)

            if (tx) {
                return {
                    status: true,
                    message: `${quantity / LAMPORTS_PER_SOL} Booked successfully !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }
            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseBookTokenUseWithToken_err", err)
        }
    }

    const UseClaimToken = async (tokenProgram, saleAddress) => {
        try {
            console.log("UseClaimToken_argu", tokenProgram.toBase58(), saleAddress)
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram)
            console.log("tokenMint", tokenMint)
            const presaleMachine = new PublicKey(saleAddress);
            const saleInfo = await program.account.vendingMachine.fetch(presaleMachine.toBase58());
            console.log("UseClaimToken_saleInfo", saleInfo)
            const userBooking = await UseUserAddressPerPresale(program, presaleMachine, provider.wallet.publicKey);
            console.log("UseClaimToken_userBooking", userBooking.toBase58())
            const vending_machine_ata = await UseTokenAccountPerAddress(presaleMachine, tokenMint, provider.wallet.publicKey);
            console.log("UseClaimToken_vending_machine_ata", vending_machine_ata)
            const buyer_spl_ata = await UseTokenAccountPerAddress(provider.wallet.publicKey, tokenMint, provider.wallet.publicKey);
            console.log("UseClaimToken_buyer_spl_ata", buyer_spl_ata, provider.wallet.publicKey)
            const tx = await program.rpc.claimTokens(
                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        booking: userBooking,
                        authority: saleInfo.authority, // owner of the presale generally
                        signer: provider.wallet.publicKey, // actual buyer
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                        splMint: tokenMint,
                        buyerSplAta: buyer_spl_ata,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        vendingMachineSplAta: vending_machine_ata,
                    },
                });

            console.log("UseClaimToken_tx", tx)


            if (tx) {
                return {
                    status: true,
                    message: `Tokens Claimed successfully !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }
            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            let message = err.toString()
            console.log("UseClaimToken_err",err, message.split(":")[3])
            return{
                error : message.split(":")[3],
                status: false
            } 
        }
    }

    const UseClosePresale = async (tokenProgram) => {
        try {
            console.log("UseClosePresale !")
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram)
            const presaleMachine = await UsePresalePerUsersToken(program, tokenMint, provider.wallet.publicKey)
            const vending_machine_ata = await UseTokenAccountPerAddress(presaleMachine, tokenMint, provider.wallet.publicKey);
            const authority_ata = await UseTokenAccountPerAddress(provider.wallet.publicKey, tokenMint, provider.wallet.publicKey);
            const fundReciever_ata = await UseTokenAccountPerAddress(Config.fundReciever, tokenMint, provider.wallet.publicKey);
            const tx = await program.rpc.closeMachine(
                {
                    accounts: {
                        authority: provider.wallet.publicKey,
                        vendingMachine: presaleMachine,
                        splMint: tokenMint,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        authoritySplAta: authority_ata,
                        vendingMachineSplAta: vending_machine_ata,
                        fundRecieverSplAta: fundReciever_ata,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                    },
                });
            if (tx) {
                return {
                    status: true,
                    message: `Presale Closed !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }

            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseClosePresale_err", err)
        }

    }

    const UseClosePresaleFairLaunch = async (tokenProgram) => {
        try {
            console.log("UseClosePresale !")
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram)
            const presaleMachine = await UsePresalePerUsersToken(program, tokenMint, provider.wallet.publicKey)
            const vending_machine_ata = await UseTokenAccountPerAddress(presaleMachine, tokenMint, provider.wallet.publicKey);
            const authority_ata = await UseTokenAccountPerAddress(provider.wallet.publicKey, tokenMint, provider.wallet.publicKey);
            const fundReciever_ata = await UseTokenAccountPerAddress(Config.fundReciever, tokenMint, provider.wallet.publicKey);
            const tx = await program.rpc.closeMachineFairLaunch(
                {
                    accounts: {
                        authority: provider.wallet.publicKey,
                        vendingMachine: presaleMachine,
                        splMint: tokenMint,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                        authoritySplAta: authority_ata,
                        vendingMachineSplAta: vending_machine_ata,
                        fundRecieverSplAta: fundReciever_ata,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        rent: web3.SYSVAR_RENT_PUBKEY,
                    },
                });
            if (tx) {
                return {
                    status: true,
                    message: `Presale Closed !!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }

            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseClosePresaleFairLaunch_err", err)
        }
    }

    const UseChangeSaleAccess = async (isWhitelist,launchType,tokenProgram) => {
        try {
            console.log("UseClosePresale !")
            const program = new Program(idl, Config.LAUNCH_PROGRAM_ID, provider);
            const tokenMint = new PublicKey(tokenProgram)
            const presaleMachine = await UsePresalePerUsersToken(program, tokenMint, provider.wallet.publicKey)
            console.log("UseChangeSaleAccess_presaleMachine", presaleMachine)


            const tx = await program.rpc.changeAccess(
                isWhitelist,
                launchType,
                {
                    accounts: {
                        vendingMachine: presaleMachine,
                        authority: provider.wallet.publicKey,
                        splMint: tokenMint,
                    },
                });
            console.log("UseChangeSaleAccess_tx",tx)
            if (tx) {
                return {
                    status: true,
                    message: `Sale Access Changed!!`,
                    tx: tx,
                    sale: presaleMachine.toBase58()
                }

            }

            else {
                return {
                    status: false
                }
            }
        }
        catch (err) {
            console.log("UseChangeSaleAccess", err)
        }
    }


    const isSaleLive = (start, end) => {
        try {
            return (Date.now() >= (start) && Date.now() <= (end));
        }
        catch (err) {
            console.log(err, "isSaleLive")
        }
    }

    const isUpcoming = (start, end) => {
        try {
            return (Date.now() < (start));
        }
        catch (err) {
            console.log(err, "isUpcoming")
        }
    }

    const isSaleEnded = (start, end) => {
        try {
            return (Date.now() >= (end))
        }
        catch (err) {
            console.log(err, "isSaleEnded")
        }
    }

    const UpcomingDiffernce = (start) => {
        try {
            return ((start) - Date.now());
        }
        catch (err) {
            console.log(err, "UpcomingDiffernce")
        }
    }

    const Salediffernce = (end) => {
        try {
            return ((end) - Date.now());
        }
        catch (err) {
            console.log(err, "Salediffernce")
        }
    }

    const getSaleOwner = async () => {
        try {
            const connection = new Connection(clusterApiUrl(network), 'confirmed');
            const programId = new PublicKey(Config.LAUNCH_PROGRAM_ID);
            const accountInfo = await connection.getAccountInfo(programId);
            console.log('Owner Address:', accountInfo, accountInfo?.owner?.toBase58());
            const programDataAddress = new PublicKey(accountInfo?.data?.slice(4, 36)); // slice to extract ProgramData Address
            const programDataAccount = await connection.getAccountInfo(programDataAddress);
            const authorityPublicKey = new PublicKey(programDataAccount?.data?.slice(13, 45)); // slice to extract Authority
            console.log('Upgrade Authority:', authorityPublicKey.toBase58());
            return authorityPublicKey.toBase58()
        }
        catch (err) {
            console.log(err, "getSaleOwner")
        }
    }

    const getTokenMetadataURI = async (connection, mintAddress) => {
        try {
            // const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
            const mintPubKey = new PublicKey(mintAddress);

            const metadataPDA = await Metadata.getPDA(mintPubKey);

            const metadataAccount = await Metadata.load(connection, metadataPDA);

            const tokenUri = metadataAccount.data.data.uri;

            console.log('Token URI:', tokenUri);
            return tokenUri;
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };


    return {
        createATA,
        createSale,
        updateSale,
        depositToken,
        startSale,
        Buy,
        claimToken,
        withdrawSol,
        withdrawToken,

        UseTokenAccountPerAddress,
        UseCreateSale,
        UseSaleInfo,
        UseTotalSaleInfo,
        UseUserBookings,
        UseDepositFunds,
        UseBookToken,
        UseBookTokenFairLaunch,
        UseBookTokenUseWithToken,
        UseClaimToken,
        UseClosePresale,
        UseClosePresaleFairLaunch,
        getSaleOwner,
        getTokenMetadataURI,
        UseChangeSaleAccess,

        isSaleLive,
        isUpcoming,
        isSaleEnded,
        UpcomingDiffernce,
        Salediffernce
    }

}


export const isSaleLive = (start, end, isOpen) => {
    return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
}

export const isUpcoming = (start, end) => {
    return (Date.now() < (start * 1000));
}

export const isSaleEnded = (start, end, isOpen) => {
    return (Date.now() >= (end * 1000)) || !isOpen
}

export const UpcomingDiffernce = (start) => {
    return ((start * 1000) - Date.now());
}

export const Salediffernce = (end) => {
    return ((end * 1000) - Date.now());
}
