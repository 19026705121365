import React, { useState } from "react";
import Classes from "../../asset/styles/AdminLogin.module.css";
import { Col, Row } from "react-bootstrap";
import { Images } from "../../data/Images";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { adminForgotPassword } from "../../actions/adminAction";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { isEmpty, ObjectIsempty } from "../../lib/isEmpty";

const initialFormValue = {
  email: "",
};

const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [formvalue, setFormvalue] = useState(initialFormValue);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      let error = { ...errors };
      error[`${name}`] = "";
      setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else {
        error["email"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let email = formvalue.email;
      let isValidate = validation({ email: email });
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await adminForgotPassword({
        email: email,
      });
      if (status == true) {
        toast.success("Verification Send Your Email,Please Check");
      } else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <div className={`${Classes.adminLogin} position-relative`}>
        <Row className="position-relative h-100 d-flex justify-content-center align-items-center">
          <Col lg={4}>
            <div className={`${Classes.loginFormHolder} p-2 p-sm-4`}>
              <div className={`text-center`}>
                <img src={Images.logo} className={`img-fluid`} alt="" />
              </div>
              <h2
                className={`${Classes.login__title} text-uppercase aboro_font mt-4`}>
                Forgot Password
              </h2>
              <div className={`${Classes.titleDivider}`}></div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="email" className={`${Classes.inputLabel} orbitron`}>
                  Email Address
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formvalue.email}
                  onChange={handleChange}
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder="Ex.John@gmail.com"
                  autoComplete="off"
                />
              </div>
              <span class="text-danger">{errors.email}</span>

              <div className={`d-flex justify-content-end w-100`}>
                <NavLink
                  to="/login"
                  className={`${Classes.loginNavLink} text-end`}>
                  <p
                    className={`${Classes.fogotPwHint} m-0 poppins text-end mt-3 pointer`}>
                    Back to Login?
                  </p>
                </NavLink>
              </div>

              <div className={`text-center mt-4`}>
                {/* <button className={`${Classes.loginGradBtn} border-0 outline-0 roboto px-5 py-2 pointer`}>Login</button> */}
                <button
                  className={`${Classes.violetBtn} px-5`}
                  onClick={(e) => handleSubmit(e)}>
                  <p
                    className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                    Submit
                  </p>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ForgotPassword;
