import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminAddFee.module.css'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { addFee } from '../actions/adminAction';
import { addFeevalidation } from '../validations/adminValidations';

const AdminAddFeeModal = ({ show, handleClose, getSettings }) => {

  const [feeName, setFeeName] = useState('')
  const [currencyValue, setCurrencyValue] = useState('')
  const [tokenValue, setTokenValue] = useState('')
  const [errors, setErrors] = useState('')

  const navigate = useNavigate()

  const handleadd = async () => {
    console.log("handleadd");
    let payload = {
      feeName: feeName,
      currencyValue: currencyValue,
      tokenValue: tokenValue
    };
    console.log("payload", payload);
    let validate = addFeevalidation(payload);
    if (validate?.isValid) {
      let feeresult = await addFee(payload);
      console.log("feeresult", feeresult);
      toast.success(feeresult?.data?.data?.message)
      getSettings()
      handleClose()
      navigate('/admin-fee-settings')
    }
    else {
      setErrors(validate.errors)
    }

  }




  const newHandleClose = async () => {
    setErrors('')
    await handleClose()

  }


  return (
    <>
      <Modal
        size='md'
        centered
        show={show}
        onHide={newHandleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >

        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={newHandleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Add Fee</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="feeName" className={`${Classes.inputLabel} orbitron`}>Fee Name</label><br />
            <input type="text" id="feeName" name="feeName" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter fee name' autoComplete="off"
              value={feeName} onChange={(e) => { setFeeName(e?.target?.value) }} />
            <span className="text-danger f-12 d-block text-left">{errors?.feeName}</span>

          </div>

          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="currencyValue" className={`${Classes.inputLabel} orbitron`}>Currency Value</label><br />
            <input type="text" id="currencyValue" name="currencyValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter Currency Value' autoComplete="off"
              value={currencyValue} onChange={(e) => { setCurrencyValue(e?.target?.value) }} />
            <span className="text-danger f-12 d-block text-left">{errors?.currencyValue}</span>

          </div>

          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="tokenValue" className={`${Classes.inputLabel} orbitron`}>Token Value</label><br />
            <input type="text" id="tokenValue" name="tokenValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Enter Token Value' autoComplete="off"
              value={tokenValue} onChange={(e) => { setTokenValue(e?.target?.value) }} />
            <span className="text-danger f-12 d-block text-left">{errors?.tokenValue}</span>

          </div>
          <div className={`text-center w-100 mt-4`}>
            <button className={`${Classes.whiteBtn} poppins`} onClick={() => { handleadd() }}>Save</button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminAddFeeModal
