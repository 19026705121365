import Usetoken from "../hooks/useToken";
import { isEmpty } from "../lib/isEmpty";
import Validator from "validator";

export const validstandardtoken = (data) => {
    console.log("validstandardtoken", data)
    try {
        var rd = new RegExp(/^\d+$/);
        var numbers = /^\d+((.)|(.\d{0,4})?)$/;

        let isValid = true;
        let errors = {};
        if (Validator.isEmpty(data?.name)) {
            isValid = false
            errors.name = "Name field is required !"
        }
        if (Validator.isEmpty(data?.symbol)) {
            isValid = false;
            errors.symbol = "Symbol field is required !"
        }
        if (Validator.isEmpty(data?.description)) {
            isValid = false
            errors.description = "Description field is required !"
        }
        if (isEmpty(data?.decimal)) {
            isValid = false;
            errors.decimal = "Decimal field is required!"
        }
        else if (!numbers.test(data?.decimal) && data?.decimal !== "") {
            isValid = false;
            errors.decimal = "Invalid Decimal field!"
        }
        // else if (!rd.test(parseFloat(data?.decimal))) {
        //     isValid = false;
        //     errors.decimal = "Invalid Decimal field "
        // }
        else if (isNaN(parseFloat(data?.decimal))) {
            isValid = false;
            errors.decimal = "Invalid Decimal field!"
        }
        if (Validator.isEmpty(data?.totalSupply)) {
            isValid = false;
            errors.totalSupply = "Total supply field is required!"
        }
        else if (isNaN(data?.totalSupply)) {
            isValid = false;
            errors.totalSupply = "Invalid Total supply field!"
        }
        else if (!numbers.test(data?.totalSupply) && data?.totalSupply !== "") {
            isValid = false;
            errors.totalSupply = "Invalid Total supply field!"
        }
        if (Validator.isEmpty(data?.image)) {
            isValid = false
            errors.image = "Image field is required !"
        }
        else if (!urlvalidation(data?.image)) {
            isValid = false;
            errors.image = "Invalid image field! "
        }
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("validstandardtoken", err)
    }
}

export const validliquiditytoken = (data) => {
    try {
        console.log("data", data);
        var rd = new RegExp(/^\d+$/);
        var numbers = /^\d+((.)|(.\d{0,4})?)$/;
        let isValid = true;
        let errors = {};
        if (Validator.isEmpty(data?.name)) {
            isValid = false
            errors.name = "Name field is required !"
        }
        if (Validator.isEmpty(data?.symbol)) {
            isValid = false;
            errors.symbol = "Symbol field is required !"
        }
        if (Validator.isEmpty(data?.decimal)) {
            isValid = false;
            errors.decimal = "Decimal field is required"
        }
        else if (!numbers.test(data?.decimal) && data?.decimal !== "") {
            isValid = false;
            errors.decimal = "Invalid Decimal field "
        }
        else if (isNaN(parseFloat(data?.decimal))) {
            isValid = false;
            errors.decimal = "Invalid Decimal field"
        }
        if (Validator.isEmpty(data?.totalsupply)) {
            isValid = false;
            errors.totalsupply = "Total supply field is required"
        }
        else if (!numbers.test(data?.totalsupply) && data?.totalsupply !== "") {
            isValid = false;
            errors.totalsupply = "Invalid Total supply field "
        }
        else if (isNaN(data?.totalsupply)) {
            isValid = false;
            errors.totalsupply = "Invalid Total supply field"
        }
        // if (!rd.test(parseFloat(data?.totalsupply))) {
        //     isValid = false;
        //     errors.totalsupply = "Invalid Total supply field"
        // }

        // if (!rd.test(parseFloat(data?.decimal))) {
        //     isValid = false;
        //     errors.decimal = "Decimal field is required"
        // }





        if (isNaN(parseFloat(data?.yieldfee))) {
            isValid = false;
            errors.yieldfee = "Invalid Transaction fee to generate yield .."
        }
        else {
            console.log("parseFloat(data?.yieldfee)", parseFloat(data?.yieldfee), parseFloat(data?.yieldfee) > 0 && parseFloat(data?.yieldfee) <= 100);
            if (!(parseFloat(data?.yieldfee) > 0 && parseFloat(data?.yieldfee) <= 100)) {
                isValid = false;
                errors.yieldfee = "Invalid Transaction fee to generate yield"
            }
        }


        if (isNaN(parseFloat(data?.liquidityfee))) {
            isValid = false;
            errors.liquidityfee = "Invalid Transaction fee to generate liquidity "
        }
        else if (!(parseFloat(data?.liquidityfee) > 0 && parseFloat(data?.liquidityfee) <= 100)) {
            isValid = false;
            errors.liquidityfee = "Transaction fee to generate liquidity must be 0-100"
        }

        if (isNaN(parseFloat(data?.maximumtransaction))) {
            isValid = false;
            errors.maximumtransaction = "Invalid Maximum transaction Amount field "
        }
        else if (!(parseFloat(data?.maximumtransaction) > 0 && parseFloat(data?.maximumtransaction) <= 100)) {
            isValid = false;
            errors.maximumtransaction = "Maximum Transaction  must be 0-100"
        }

        if (isNaN(parseFloat(data?.exchangethresold))) {
            isValid = false;
            errors.exchangethresold = "Invalid Exchange Thersold field "
        }
        else if (!(parseFloat(data?.exchangethresold) > 0 && parseFloat(data?.exchangethresold) <= 100)) {
            isValid = false;
            errors.exchangethresold = "Exchange Thresold  must be 0-100"
        }
        if (Validator.isEmpty(data?.yieldfee)) {
            isValid = false;
            errors.yieldfee = "Transaction fee to generate yield  field is required"
        }
        if (Validator.isEmpty(data?.liquidityfee)) {
            isValid = false;
            errors.liquidityfee = "Transaction fee to generate liquidity  field is required"
        }
        if (Validator.isEmpty(data?.maximumtransaction)) {
            isValid = false;
            errors.maximumtransaction = "Maximum transaction Amount field is required"
        }
        if (Validator.isEmpty(data?.exchangethresold)) {
            isValid = false;
            errors.exchangethresold = "Exchange Thersold field is required"
        }
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("validliquiditytoken", err)
    }
}


export const urlvalidation = (data) => {
    try {
        const regex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
        console.log("valid url ", regex.test(data));
        return regex.test(data);
    }
    catch (err) {
        console.log("urlvalidation", err)
    }
}

export const addwhitelistvalidation = async (val) => {

    console.log("addwhitelistvalidation", val);

    try {
        let isValid = true;
        let ob = {}
        console.log("val", val);
        if (Validator.isEmpty(val.user)) {
            isValid = false;
            ob.user = "Wallet Address field is required !";
        }
        // else {
        //     let isvalid = await IsValidAddress(val.user);
        //     console.log("isvalid", isvalid);
        //     if (!isvalid) {
        //         isValid = false
        //         ob.user = "Invalid wallet address !"
        //     }
        // }

        if (isNaN(parseFloat(val.bnbvalue)) || val.bnbvalue <= 0) {
            isValid = false
            ob.bnbvalue = "Invalid Currency value !"
        }
        console.log("length", ob);
        return {
            isValid: isEmpty(ob),
            errors: ob
        }
    }
    catch (err) {
        console.log("addwhitelistvalidation", err)
    }
}

export const removewhitelistvalidation = async (val) => {
    let isValid = true;
    // var errors ={} ;
    let ob = {}
    console.log("val", val);
    // data.map(async(val , i)=>{
    if (Validator.isEmpty(val.walletAddress)) {
        isValid = false;
        ob.user = "Wallet Address field is required !";
    }
    // else {
    //     let isvalid = await IsValidAddress(val.user);
    //     console.log("isvalid", isvalid);
    //     if (!isvalid) {
    //         isValid = false
    //         ob.user = "Invalid wallet address !"
    //     }
    // }

    // if (isNaN(val.bnbvalue) || validliquiditytoken.bnbvalue != 0) {
    //     isValid = false
    //     ob.bnbvalue = "Invalid BNB value !"
    // }
    // errors[i]=ob;
    // ob = {}
    // })
    // errors = Object.keys(errors)
    console.log("length", ob);
    return {
        isValid: isEmpty(ob),
        errors: ob
    }
}




export const auditkycvalidate = (data) => {
    try {
        let errors = {};
        let isValid = true;


        if (data?.kyc === "" && data?.audit === "" && data?.DOXX === "" && data?.SAFU === "") {
            if (isEmpty(data?.kyc)) {
                isValid = false;
                errors.kyc = "Kyc field is required";
            }
            if (isEmpty(data?.audit)) {
                isValid = false;
                errors.audit = "Audit field is required";
            }
            if (isEmpty(data?.DOXX)) {
                isValid = false;
                errors.DOXX = "DOXX field is required";
            }
            if (isEmpty(data?.SAFU)) {
                isValid = false;
                errors.SAFU = "SAFU field is required";
            }
        }

        if (data?.kyc) {
            if (isEmpty(data?.kyc)) {
                isValid = false;
                errors.kyc = "Kyc field is required";
            }
            if (!urlvalidation(data?.kyc)) {
                isValid = false;
                errors.kyc = "Invalid kyc url";
            }
            if (!((data?.kyc).includes('https'))) {
                isValid = false;
                errors.kyc = "Invalid kyc url";
            }

        }


        if (data?.audit) {
            if (isEmpty(data?.audit)) {
                isValid = false;
                errors.audit = "Audit field is required";
            }
            if (!urlvalidation(data?.audit)) {
                isValid = false;
                errors.audit = "Invalid audit url";
            }
            if (!((data?.audit).includes('https'))) {
                isValid = false;
                errors.audit = "Invalid audit url";
            }
        }

        if (data?.DOXX) {
            if (isEmpty(data?.DOXX)) {
                isValid = false;
                errors.DOXX = "DOXX field is required";
            }
            if (!urlvalidation(data?.DOXX)) {
                isValid = false;
                errors.DOXX = "Invalid DOXX url";
            }
            if (!((data?.DOXX).includes('https'))) {
                isValid = false;
                errors.DOXX = "Invalid DOXX url";
            }
        }

        if (data?.SAFU) {
            if (isEmpty(data?.SAFU)) {
                isValid = false;
                errors.SAFU = "SAFU field is required";
            }
            if (!urlvalidation(data?.SAFU)) {
                isValid = false;
                errors.SAFU = "Invalid SAFU url";
            }
            if (!((data?.SAFU).includes('https'))) {
                isValid = false;
                errors.SAFU = "Invalid SAFU url";
            }
        }

        if (isEmpty(data?.status)) {
            isValid = false;
            errors.status = "Status field is required !";
        }

        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("auditkycvalidate", err)
    }
}

export const validtokenlock = (data) => {
    console.log("data", data);
    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.address)) {
        isValid = false
        errors.address = "Token Address field is required !"
    }
    if (Validator.isEmpty(data?.amount)) {
        isValid = false
        errors.amount = "Amount field is required !"
    }


    if (data.vesting) {
        if (isEmpty(data?.cycle)) {
            isValid = false
            errors.cycle = "Cycle field is required !"
        }
        if (isEmpty(data?.cyclepercent)) {
            isValid = false
            errors.cyclepercent = "Cycle release percent  field is required !"
        }
        if (data?.cyclepercent < 0 && data?.cyclepercent >= 100) {
            isValid = false
            errors.cyclepercent = "Cycle release percent  field value must be 0 to 100 !"
        }
        if (isEmpty(data?.locktime)) {
            isValid = false
            errors.locktime = "Lock Until field is required !"
        }
    }
    else {
        if (isEmpty(data?.locktime)) {
            isValid = false
            errors.locktime = "Lock Until field is required !"
        }
    }
    if (data?.anotherOwner == true) {
        if (isEmpty(data?.anotherowneraddress)) {
            isValid = false;
            errors.anotherowneraddress = "Owner field is required !";
        }
    }

    console.log(errors, 'errors')
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}



export const searchsaleByToken = (saleData, searchSymbol) => {
    console.log("searchsaleByToken", saleData, searchSymbol);
    let search = new RegExp(searchSymbol, 'i');
    let filteredData = []
    saleData?.filter(value => {
        if ((value?.name)?.match(search)) {
            console.log(value.name, 'value.name')
            filteredData.push(value.tokenAddress)
        }
    }
    );
    console.log("searchsaleByToken23", filteredData);
    return filteredData

}


