import axios, { setAuthorization } from "../config/axios";
import { AUTH_TOKEN } from "./types";
import { setAuthToken } from "../lib/localStorage";

export const adminLogin = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/login`,
      data,
    });
    dispatch({
      type: AUTH_TOKEN,
      payload: respData.data.token,
    });
    setAuthToken(respData.data.token);
    setAuthorization(respData.data.token);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      token: respData.data.token,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const adminForgotPassword = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/forgot-password`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const verifyPasswordEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/verify-Forgot-Email`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const resetNewPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/reset-password
      `,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const changeAdminPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/change-password
      `,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const changeAdminEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/change-email
      `,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};



export const sentVerifyLink = async (data) => {
  console.log("profileSettings", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("token", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/sentVerifyLink`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const verifyChangeAdminEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/verifyNewEmail
      `,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const getAllCMSList = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/get-cms`,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const updateCMSList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/update-cms`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const getSiteSettings = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/getSiteSetting`,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const updateSiteSettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settings/updateSiteSetting`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const getKycList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/user-list`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const approveUserKyc = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/approve-kyc`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};
export const rejectUserKyc = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/reject-kyc`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};


export const addFee = async (data) => {
  console.log("addIpAddress", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("feeName", data?.feeName);
      formdata.append("currencyValue", data?.currencyValue);
      formdata.append("tokenValue", data?.tokenValue);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/addFee`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const editFee = async (data) => {
  console.log("addIpAddress", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("currencyValue", data?.currencyValue);
      formdata.append("tokenValue", data?.tokenValue);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/editFee`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const deleteFees = async (data) => {
  console.log("deleteIpAddress", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/deleteFee`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getFees = async (data) => {
  try {
    let respData = await axios.get(`/admin/getFee`)

    console.log("respdata", respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
      error: err
    }
  }
}

export const adminGetsalehook = async (data) => {
  try {
    console.log("audit_kyc_getsalehook", data);
    let respData = await axios.get(`/admin/getFee`,
      { params: { skip: data?.skip, limit: data?.limit } }
    )
    console.log("audit kyc respdata", respData);
    return respData
  }
  catch (err) {
    return {
      error: err
    }
  }
}

export const addAuditsalehook = async (data) => {
  console.log("addsalehook", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("status", data?.status);
      formdata.append("id", data?.id);
      formdata.append("audit", data?.audit);
      formdata.append("kyc", data?.kyc);
      formdata.append("DOXX", data?.DOXX);
      formdata.append("SAFU", data?.SAFU);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/addkycaudit`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}




// export const adminGetsalehook = async (data) => {
//   try {
//     console.log("audit_kyc_getsalehook", data);
//     let respData = await axios.get(`/admin/getFee`,
//       { params: { skip: data?.skip, limit: data?.limit} }
//     )
//     console.log("audit kyc respdata", respData);
//     return respData
//   }
//   catch (err) {
//     return {
//       error: err
//     }
//   }
// }




export const EditSalestatus = async (data) => {
  console.log("login data", (data));
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.presaleAddress);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/editsaleStatus`,

        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const editLaunchpadFee = async (data) => {
  console.log("editLaunchpadFee", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("id", data?.id);
      formdata.append("feeName", data?.feeName);
      formdata.append("FeeAmount", data?.FeeAmount);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/admin/editlaunchpadfee`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}



export const getLaunchpadFee = async (data) => {
  try {
    let respData = await axios.get(`/admin/getLaunchpadFee`)

    if (respData?.data?.status) {
      return respData?.data?.data

    } else {
      return false
    }


  }
  catch (err) {
    return {
      error: err
    }
  }
}



export const getAdminInfo = async (data) => {
  try {
    let respData = await axios.get(`/admin/getAdmin`);
    console.log("respData>>>", respData?.data?.status, respData?.data?.result);


    if (respData?.data?.status && respData?.data?.result.length > 0) {
      return respData?.data?.result

    } else {
      return false
    }


  }
  catch (err) {
    return {
      error: err
    }
  }
}

