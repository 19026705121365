import React, { useState, useEffect } from 'react'
import Classes from '../asset/styles/SalesCard.module.css'
import { Images } from '../data/Images'
// import Countdown from '../components/Countdown';
import Countdown from 'react-countdown';
import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Config from "../config"
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import Usesale from "../hooks/useSale";
import Usetoken from "../hooks/useToken";
import { isEmpty } from '../lib/isEmpty';
import { duration } from '../lib/dateTimeHelper';

const SalesCard = ({ saleData, saleDBData }) => {

  const saleHook = Usesale()
  const tokenHook = Usetoken()

  const [tokeninfo, setTokeninfo] = useState({})

  console.log("saleData", saleData?.account, saleDBData)
  const saleInfo = saleData?.account

  var percentage = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)
  var percentage_launch = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)

  // useEffect(() => {
  //   getTokenInfo()
  // }, [saleInfo?.splMint])

  // const getTokenInfo = async () => {
  //   console.log("tokeninfoooo", saleInfo?.splMint)
  //   if (!isEmpty(saleInfo?.splMint)) {
  //     // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress,  saleInfo?.splMint)
  //     let tokenInfo = await tokenHook.getTokenNameSymbol(saleInfo?.splMint)
  //     console.log("tokenInfo", tokenInfo)
  //     setTokeninfo(tokenInfo)
  //   }
  // }


  const renderer = ({ days, hours, minutes, seconds }) => {

    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{
          days < 10 ? `0${days}` : days}
          <span className='text-center'>Days</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{hours < 10 ? `0${hours}` : hours}
          <span className='text-center'>Hrs</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{minutes < 10 ? `0${minutes}` : minutes}
          <span className='text-center'>Mins</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{seconds < 10 ? `0${seconds}` : seconds}
          <span className='text-center'>Secs</span>
        </p>
      </div>
    )
  }
  return (

    <>
      <Col lg={6} xl={4} md={12} className={`mb-4 ${Classes.salesCardHolder}`}>

        {console.log("percentage", percentage, saleInfo?.currencyRaised, saleInfo?.softCap, Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleEnd)))}

        <div className={`${Classes.salesCard} py-3 px-4`}>
          <img src={saleDBData?.logo ? saleDBData?.logo : Images.salesImg} className={`${Classes.salesCard__img} img-fluid mt-2`} alt='' />
          <button className={`${Classes.salesCard__topBtn} w-100 p-3 mt-3`}><p className={`${Classes.salesCard__gradText} roboto mx-auto text-capitalize`}>{saleDBData?.name ? saleDBData?.name : 'Solmax'}</p></button>
          <ul className={`${Classes.salesCard__unorderList} mt-3`}>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>{saleInfo?.launchType}</p>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              {console.log("buyMint",saleInfo?.buyMint?.toString(),saleInfo?.buyMint?.toString() == Config?.SOL)}
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Soft Cap</p>
                <p className={`${Classes.listText} roboto`}>{saleInfo?.softCap / LAMPORTS_PER_SOL}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" : 
                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                   saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}
                   </p>
              </div>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Liquidity</p>
                <p className={`${Classes.listText} roboto`}>{(parseInt(saleInfo?.liquidityPercent))}%</p>
              </div>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Lockup Time</p>
                <p className={`${Classes.listText} roboto`}>{duration(parseInt(saleInfo?.lockingDays))} Days</p>
              </div>
            </li>
          </ul>


          <div className={`${Classes.card__progressHolder}`}>
            {console.log("currencyRaised_sale", saleInfo?.currencyRaised, saleInfo?.hardCap / LAMPORTS_PER_SOL, parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100))}

            {saleInfo?.launchType == "Launchpad" ?
              <>
                <div className={`d-flex justify-content-between align-items-center`}>
                  <p className={`${Classes.card__progressTitle} poppins`}>Progress</p>
                  <p className={`${Classes.card__progressValue} roboto`}>{saleInfo && percentage_launch > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)}%</p>
                </div>
                <div className={`${Classes.card__progressBorder} mt-2`}>
                  <div className={`${Classes.card__progressBar} position-relative`}>
                    <div className={`${Classes.card__filledProgress}`} style={{ width: `${saleInfo && percentage_launch > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)}%` }}>

                    </div>
                  </div>
                </div>
                <p className={`${Classes.card__progressValue} roboto mt-2`}>{saleInfo && (parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL).toFixed(4)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" : 
                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                   saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"} raised</p>

              </>

              :
              <>

                <div className={`d-flex justify-content-between align-items-center`}>
                  <p className={`${Classes.card__progressTitle} poppins`}>Progress</p>
                  <p className={`${Classes.card__progressValue} roboto`}>{saleInfo && percentage > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)}%</p>
                </div>
                <div className={`${Classes.card__progressBorder} mt-2`}>
                  <div className={`${Classes.card__progressBar} position-relative`}>
                    <div className={`${Classes.card__filledProgress}`} style={{ width: `${saleInfo && percentage > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)}%` }}></div>
                  </div>
                </div>

                <p className={`${Classes.card__progressValue} roboto mt-2`}>{saleInfo && (parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL).toFixed(4)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" : 
                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                   saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"} raised</p>

              </>
            }


            {saleInfo && saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ?
              <>
                <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale Starts In</p>
                <div className={`${Classes.card__countdownHolder} p-3 d-flex justify-content-center position-relative mt-2`}>

                  <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleStart))} renderer={renderer} />
                </div>
              </>
              :
              saleInfo && saleInfo.ready !== 2 && saleHook.isSaleLive(saleInfo.presaleStart, saleInfo.presaleEnd) ?
                <>
                  <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale ends in</p>
                  <div className={`${Classes.card__countdownHolder} p-3 d-flex justify-content-center position-relative mt-2`}>

                    <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleEnd))} renderer={renderer} />
                  </div>
                </>
                :
                // saleInfo && saleInfo?.ready == 2  || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ?
                <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale ended</p>
              // :<></>

            }

            <div className={`d-flex justify-content-center align-items-center mt-3 mb-3`}>

              <NavLink to={`/launchpad-detail/${saleData && (saleData?.publicKey)?.toString()}`}
                onClick={() => {
                  sessionStorage.setItem("saledata", JSON.stringify(saleData));
                  sessionStorage.setItem("saleDBdata", JSON.stringify(saleDBData))
                }}>
                <button className={`${Classes.card__blueBorderBtn} text-uppercase roboto`}>Detail</button>
              </NavLink>
            </div>

          </div>
        </div>
      </Col>

    </>
  )
}

export default SalesCard
