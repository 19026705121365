import React, { useEffect, useState } from "react";
import Classes from "../asset/styles/AdminKYCModal.module.css";
import { IoMdClose } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { updateSiteSettings } from "../actions/adminAction";
import toast from "react-hot-toast";
import { isEmpty } from "../lib/isEmpty";

const AdminSettingModal = ({
  show,
  handleClose,
  record,
  getSiteSettingData,
}) => {
  console.log(record, "recordrecord");

  const [formvalue, setFormvalue] = useState(record);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const handleSubmit = async () => {
    try {
      let data = {
        identifier: formvalue.identifier,
        value: formvalue.value,
        _id: formvalue._id,
      };
      let { status, message, result } = await updateSiteSettings(data);
      if (status == true) {
        toast.success("Updated Successfully");
        getSiteSettingData();
        onhandleClose();
      } else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  useEffect(() => {
    if (!isEmpty(record)) {
      setFormvalue(record);
    }
  }, [record]);

  const onhandleClose = (e) => {
    try {
      setFormvalue();
      handleClose();
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}>
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>
            Edit Setting
          </h5>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label
              for="settingName"
              className={`${Classes.inputLabel} orbitron`}>
              Setting Name
            </label>
            <br />
            <input
              type="text"
              id="settingName"
              name="identifier"
              onChange={handleChange}
              value={formvalue?.identifier}
              className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              placeholder=""
              autoComplete="off"
              readOnly
            />
          </div>
          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label
              for="settingValue"
              className={`${Classes.inputLabel} orbitron`}>
              Setting Value
            </label>
            <br />
            <input
              type="text"
              id="settingValue"
              name="value"
              onChange={handleChange}
              value={formvalue?.value}
              className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              placeholder=""
              autoComplete="off"
            />
          </div>
          <div
            className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-4`}>
            <button
              className={`${Classes.whiteBtn} poppins`}
              onClick={(e) => handleSubmit(e)}>
              Edit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminSettingModal;
