/** npm import */

import React, { useEffect, useState } from "react";

/** local files import */
import Classes from "../../../asset/styles/AdminPrivateSaleList.module.css";
import { Images } from "../../../data/Images";
import { commonData } from "../../../data/CommonData";
import LaunchingTable from "../../../components/LaunchingTable";
import Countdown from 'react-countdown';
import { Col, Container, Row } from "react-bootstrap";
import PrivateSalesCard from "../../../components/PrivateSalesCard";
import AdminLayout from '../../../layout/AdminLayout'
import Usesale from "../../../hooks/useSale";
import { useSelector } from "react-redux";
import { getallsalehook } from "../../../actions/userAction";

const AdminPrivateSaleList = () => {
  const [tab, setTab] = useState("sale");
  const saleHook = Usesale()

  const [saleData, setSaleData] = useState({})
  const [saleAdd, setSaleAdd] = useState("")
  const [saleDBData, setSaleDBData] = useState({})
  const [myDBData, setMyDBData] = useState({})
  const [interval, setInterval] = useState(1)
  const [loadInterval, setLoadInterval] = useState(3)
  const [loadData, setLoadData] = useState([])
  const [myLoadData, setMyLoadData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const walletdetail = useSelector((state) => state.wallet)
  const [myContribution, setMyContribution] = useState({})
  const [ownerAdd, setOwnerAdd] = useState("")
  const [premium, setPremium] = useState({})

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-start align-items-center`}>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{days < 10 ? `0${days}` : days}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{hours < 10 ? `0${hours}` : hours}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{minutes < 10 ? `0${minutes}` : minutes}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
      </div>
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getSaleInfo()
    getOwner()
  }, [ownerAdd])


  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }



  const getSaleInfo = async () => {
    const getSale = await saleHook?.UseTotalSaleInfo()
    console.log("getSale", getSale)
    setSaleData(getSale)

    const premiumfilter = getSale?.filter((val) => {
      console.log("premiumfilter_val", val?.account?.authority.toString(), val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false);
      return val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == true
    })
    console.log("premiumfilter", premiumfilter)
    setPremium(premiumfilter)

    let TotalData = [...getSale]
    let limit = [...TotalData].splice(0, loadInterval)
    setLoadData(limit)



    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)

  }


  const getMyContribution = async () => {

    const getSale = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_contribution", getSale)
    const Totalfilter = getSale?.filter((val) => { return val?.account?.authority.toString() == walletdetail?.walletaddress })
    console.log(Totalfilter, "getMyContribution")
    let filter = [...Totalfilter]
    setMyContribution(filter)
    let limit = [...filter].splice(0, loadInterval)
    setMyLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    let DBPush = []
    for (let i = 0; i < filter.length; i++) {
      const filterDb = getSalefromDb?.data?.data?.find((val) => (val?.saleAddress == filter[i]?.publicKey?.toString()))
      console.log(filterDb, "getMyContribution2", filter[i]?.publicKey?.toString())
      DBPush.push(filterDb)
    }

    setMyDBData(DBPush)

  }


  const loadMore = async (interval) => {
    console.log("Load_moreee", saleData, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...saleData];
    const limit = newly.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setLoadData([...loadData, ...limit])
  }




  const loadMyContribution = async (interval) => {
    console.log("loadMyContribution", myContribution, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...myContribution];
    const limit = newly.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setMyLoadData([...myLoadData, ...limit])
  }
  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <Row className={`mx-auto position-relative`}>
            <Col xs={12} className={`px-lg-0`}>
              <div className={`${Classes.catImgBg} position-relative`}>
                <img src={Images.clip} className={`img-fluid ${Classes.leftTopClip}`} alt="" />
                <img src={Images.clip} className={`img-fluid ${Classes.leftBottomClip}`} alt="" />
                <img src={Images.clip} className={`img-fluid ${Classes.rightTopClip}`} alt="" />
                <img src={Images.clip} className={`img-fluid ${Classes.rightBottomClip}`} alt="" />
                <Row className={``}>
                  <Col md={8} xl={9} className={``}>
                    <div className={`p-4`}>
                      <div className={`d-flex justify-content-between align-items-start gap-4 flex-wrap`}>
                        <div className={`${Classes.launchpad__nameHolder}`}>

                          <h1
                            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>neko meme</h1>
                        </div>

                        <div className={`d-flex justify-content-end align-items-center gap-3`}>
                          <img src={Images.globe} className={`img-fluid pointer`} />
                          <img src={Images.twitter} className={`img-fluid pointer`} />
                          <img src={Images.telegram} className={`img-fluid pointer`} />
                        </div>
                      </div>

                      <p className={`${Classes.launchpad__desc} ${Classes.responsiveWidText} m-0 text-uppercase mt-4 roboto`}>Meme of Neko is not only meme project on solana, we also provide NFT Staking. Staking NFT link : https://nft.mekosolana.com. Buy presale, Earn passive income by staking your Meko NFT in our platform.
                      </p>

                      <div className={`${Classes.flexHolder} pt-2`}>
                        <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>token name :</p>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>meme of neko</p>
                        </div>
                        <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>symbol :</p>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>neko</p>
                        </div>
                        <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>total supply :</p>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>100,000,000</p>
                        </div>
                        <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>status :</p>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>coming up</p>
                        </div>
                        <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>countdown :</p>
                          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>
                            {/* <CountdownSimple initialHours={initialHours} initialMinutes={initialMinutes} initialSeconds={initialSeconds} initialDays={initialDays} /> */}
                            <Countdown date={Date.now() + 2000000} renderer={renderer} />
                          </p>
                        </div>
                      </div>

                      <button className={`${Classes.launchpad__saleViewBtn} text-uppercase roboto mt-5`}>
                        sale view
                      </button>
                    </div>
                  </Col>
                  <Col md={4} xl={3} className={``}>
                    <div className={`d-flex justify-content-between flex-column flex-md-column flex-sm-row align-items-start`}>
                      <img src={Images.cat} className={`img-fluid ${Classes.cattyImg} flex-grow-1`} />


                      <ul className={`${Classes.salesCard__unorderList} flex-grow-1 w-100 mb-0`}>
                        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                          <div className={`d-flex justify-content-between align-items-center`}>
                            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                            <p className={`${Classes.listText} roboto`}>Premium Gem</p>
                          </div>
                        </li>
                        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                          <div className={`d-flex justify-content-between align-items-center`}>
                            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                            <p className={`${Classes.listText} roboto text-uppercase`}>KYC</p>
                          </div>
                        </li>
                        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                          <div className={`d-flex justify-content-between align-items-center`}>
                            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                            <p className={`${Classes.listText} roboto`}>Audit</p>
                          </div>
                        </li>
                        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                          <div className={`d-flex justify-content-between align-items-center`}>
                            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                            <p className={`${Classes.listText} roboto text-uppercase`}>safu</p>
                          </div>
                        </li>
                        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                          <div className={`d-flex justify-content-between align-items-center`}>
                            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                            <p className={`${Classes.listText} roboto`}>Doxx</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Container className={`custom_container mt-5`}>
            <div className={`${Classes.launchPad__section}`}>
              <h2
                className={`${Classes.launchpad__title} text-uppercase aboro_font`}
              >
                Token sale launchpad
              </h2>

              <Row className={`mt-3`}>
                <Col lg={5} className={``}>
                  <div
                    className={`${Classes.launchpad__dualBox} d-flex justify-content-center align-items-center`}
                  >
                    <p
                      className={`${Classes.dualBox_hint} inter_font text-uppercase w-75  m-0 mt-5`}
                    >
                      Gain early access to public and special token sales at a lower
                      price before they hit the market
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className={`mt-5`}>
                <Col md={5} lg={6} className={`mb-4 mb-md-0`}>
                  <div
                    className={`d-flex justify-content-start align-items-center`}
                  >
                    <p
                      className={`${tab === "sale"
                        ? Classes.tab__titleActive
                        : Classes.tab__title
                        } m-0 text-center px-3 me-2 pointer poppins`}
                      onClick={() => setTab("sale")}
                    >
                      All Sale
                    </p>
                    <p
                      className={`${tab === "contribution"
                        ? Classes.tab__titleActive
                        : Classes.tab__title
                        } m-0 text-center px-3 pointer poppins`}
                      onClick={() => setTab("contribution")}
                    >
                      My Contribution
                    </p>
                  </div>
                </Col>
                <Col md={7} lg={6} className={``}>
                  <div
                    className={`d-flex justify-content-end align-items-center w-100`}
                  >
                    <div className={`${Classes.searchBtn}`}>
                      <p className={`${Classes.searchHint} d-none d-sm-flex poppins`}>Search Token</p>
                      <p className={`${Classes.searchHint} d-sm-none`}><i class="fa-solid fa-magnifying-glass" /></p>

                    </div>
                    <input
                      type="text"
                      className={`${Classes.searchBar} flex-grow-1 poppins`}
                    />
                  </div>
                </Col>
              </Row>
              <Row className={`mt-5`}>
                {commonData?.salesCard?.map((item, index) =>

                  <PrivateSalesCard data={item} index={index} />
                )}


              </Row>
            </div>
          </Container>
        </div>
      </AdminLayout>
    </>
  )
}

export default AdminPrivateSaleList
