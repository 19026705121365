import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Walletbutton from '../components/walletbutton'

import Classes from '../asset/styles/ConnectWalletModal.module.css'
import { IoMdClose } from "react-icons/io";

import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { GlowWalletAdapter, PhantomWalletAdapter, SolflareWalletAdapter, MathWalletAdapter } from "@solana/wallet-adapter-wallets";
import "@solana/wallet-adapter-react-ui/styles.css";
import { useMemo } from "react";
import { clusterApiUrl, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { WalletMultiButton, WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../lib/isEmpty';
import { useWallet } from "@solana/wallet-adapter-react";
// import { SetWallet } from '../hooks/useStorage';
import HookFunction from "../hooks/useStorage"
import Usewallet from "../hooks/UseWallet";
import { WALLET_ADDRESS, WALLET_DATA } from '../constants';
import Config from '../config';
import { createuserhook } from '../actions/userAction';

const WalletpopModal = (props) => {
    const { show, handleClose } = props
    const storageHooks = HookFunction()
    const walletHook = Usewallet()
    let dispatch = useDispatch()


    const { connected, publicKey, wallet } = useWallet()
    console.log("accountInfo", Config.NETWORK, publicKey, wallet, wallet?.adapter?.name)

    const network = Config.NETWORK;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const walletdetail = useSelector((state) => state.wallet)
    console.log("walletdetail_ballance", walletdetail)

    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
    ],
        [network],
    );


    useEffect(() => {
        console.log("accountInfo1111111", publicKey, walletdetail.walletaddress)
        walletData()
    }, [connected, publicKey])


    const walletData = async () => {
        try {
            if (!isEmpty(publicKey)) {
                storageHooks.SetWallet(wallet?.adapter?.name)
                storageHooks.SetWalletAddress(publicKey)
                let bal = await walletHook.getSolBalance(publicKey)
                console.log("walletData_bal", bal, bal / LAMPORTS_PER_SOL)
                storageHooks.SetWalletBal(bal / LAMPORTS_PER_SOL)

                let walletData = {
                    walletName: wallet?.adapter?.name,
                    walletBal: bal / LAMPORTS_PER_SOL
                }

                let user = await createuserhook(publicKey.toString())
                console.log("walletButton_user", user)
                /*redux*/
                dispatch({
                    type: WALLET_ADDRESS,
                    payload: publicKey.toString()
                });
                dispatch({
                    type: WALLET_DATA,
                    payload: walletData
                });

            }
        } catch (err) {
            console.log(err, 'walletData')
        }

    }

    return (
        <>
            <Modal
                size='md'
                centered
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                className={`cmnModal connectWalletModal`}
            >
                <Modal.Body className={`${Classes.wallets} text-center`}>
                    {/* <div className={`${Classes.modalHeader} text-end`}>
                        <button className={`${Classes.modalCloser}`} onClick={() => { handleClose() }}><IoMdClose /></button>
                    </div> */}
                    <div onClick={() => { handleClose() }}>
                        <WalletMultiButton onClick={(e)=>{console.log("WalletMultiButton",e)}} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WalletpopModal