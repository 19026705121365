import { Images } from "./Images";

export const commonData = {
    salesCard: [
        {
            name: 'meko of neko',
            softCap: 10,
            liquidity: 55,
            lockUpTime: 100,
            progress: 40,
            lineaeth: 15,
            // initialDays : 1,
            // initialHours : 12,
            // initialMinutes : 55,
            // initialSeconds : 33,
            time: Date.now() + 200000000,
            premium: false
        },
        {
            name: 'meme of neko',
            softCap: 10,
            liquidity: 35,
            lockUpTime: 10,
            progress: 20,
            lineaeth: 15,
            // initialDays : 2,
            // initialHours : 8,
            // initialMinutes : 55,
            // initialSeconds : 33,
            time: Date.now() + 450000000,
            premium: false
        },
        {
            name: 'puppy',
            softCap: 10,
            liquidity: 95,
            lockUpTime: 140,
            progress: 90,
            lineaeth: 15,
            // initialDays : 12,
            // initialHours : 5,
            // initialMinutes : 55,
            // initialSeconds : 33,
            time: Date.now() + 750000000,
            premium: true
        },
        {
            name: 'cat',
            softCap: 10,
            liquidity: 45,
            lockUpTime: 80,
            progress: 60,
            lineaeth: 15,
            // initialDays : 6,
            // initialHours : 5,
            // initialMinutes : 55,
            // initialSeconds : 33,
            time: Date.now() + 35000000,
            premium: false
        },
        {
            name: 'Sun',
            softCap: 10,
            liquidity: 45,
            lockUpTime: 80,
            progress: 60,
            lineaeth: 15,
            // initialDays : 6,
            // initialHours : 5,
            // initialMinutes : 55,
            // initialSeconds : 33,
            time: Date.now() + 9900000000,
            premium: true
        },
    ],

    dashboardSwiper: [
        {
            title: 'premium launch',
            hint: 'Direct support from SOLMAX team with a full batch of all services.'
        },
        {
            title: 'standard',
            hint: 'mint standard tokens on solana.'
        },
        {
            title: 'customization',
            hint: 'create a toke sale for your own custom token easily.'
        },
        {
            title: 'Deflationary',
            hint: 'Generate deflationary tokens with tax and/or charity functions.'
        },
        {
            title: 'Launchpad',
            hint: 'Use the token you mint to create a launchpad with just a few clicks'
        },
        {
            title: 'Branding',
            hint: 'Adding logo, social links, description, listing on Solmax'
        },
        {
            title: 'Management',
            hint: 'The portal to help you easily update content for your launchpad.'
        },
        {
            title: 'Community',
            hint: 'Promote your launchpad to thousands of buyers on Solmax.'
        },
        {
            title: 'Locking',
            hint: 'Lock your liquidity to Solmax Swap after presale.'
        },
    ],
    createLaunchpadSteps: [
        {
            title: 'Verify Token',
            hint: 'Enter the token address and verify'
        },
        {
            title: 'Defi Launchpad Info',
            hint: 'Enter the launchpad information that you want to raise, that should be enter all details about your presale'
        },
        {
            title: 'Add Additional Info',
            hint: 'Let people know who you are'
        },
        {
            title: 'Finish',
            hint: 'Review your information'
        },
    ],
    launchpadInfoSteps: [
        {
            title: 'Waiting for pool start',
            hint: 'No one can purchase',
            key: 'upcoming'
        },
        {
            title: 'Pool Start',
            hint: 'Pool starts at 2024.05.07 13.00 UTC',
            key: 'start'
        },
        {
            title: 'Pool Ended',
            hint: 'Pool ends at 2024.05.10 13:00 (UTC)',
            key: 'end'
        }
    ],
    tokenLockTable: [
        {
            tokenImg: Images.solana,
            tokenName: 'Cat',
            tokenSubHint: 'links',
            amount: '1',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Moon',
            tokenSubHint: 'links',
            amount: '100',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Clever',
            tokenSubHint: 'links',
            amount: '1000',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Cat',
            tokenSubHint: 'links',
            amount: '1',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Moon',
            tokenSubHint: 'links',
            amount: '100',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Clever',
            tokenSubHint: 'links',
            amount: '1000',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Cat',
            tokenSubHint: 'links',
            amount: '1',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Moon',
            tokenSubHint: 'links',
            amount: '100',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Clever',
            tokenSubHint: 'links',
            amount: '1000',
            amountType: 'SOL',

        },

        {
            tokenImg: Images.solana,
            tokenName: 'Cat',
            tokenSubHint: 'links',
            amount: '1',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Moon',
            tokenSubHint: 'links',
            amount: '100',
            amountType: 'SOL',

        },
        {
            tokenImg: Images.solana,
            tokenName: 'Clever',
            tokenSubHint: 'links',
            amount: '1000',
            amountType: 'SOL',

        },
    ],
    myWalletTable: [
        {
            name: 'Cat',
            saleType: 'Private',
            saleAddress: '0xbC99587BB91a5B467f933916077E8961682d1937',
        },
        {
            name: 'Cat',
            saleType: 'Private',
            saleAddress: '0xbC99587BB91a5B467f933916077E8961682d1937',
        },
        {
            name: 'Cat',
            saleType: 'Private',
            saleAddress: '0xbC99587BB91a5B467f933916077E8961682d1937',
        },
        {
            name: 'Cat',
            saleType: 'Private',
            saleAddress: '0xbC99587BB91a5B467f933916077E8961682d1937',
        },
        {
            name: 'Cat',
            saleType: 'Private',
            saleAddress: '0xbC99587BB91a5B467f933916077E8961682d1937',
        },
    ],
    adminLaunchpadTable: [
        {
            name: 'Cat1',
            img: Images.coinCat,
            saleType: 'Privatesale',
            hardcap: 1,
            softCap: 1,
            badge: '-',
            status: '0.0001/50',
            Links: [
                {
                    twitter: '',
                    website: '',
                    telegram: ''
                }
            ],
            startsIn: '04/07/24 12:00:00',
            endsIn: '06/07/24 12:00:00',
            edit: false
        },
        {
            name: 'Cat2',
            img: Images.coinCat,
            saleType: 'Privatesale',
            hardcap: 1,
            softCap: 1,
            badge: '-',
            status: '0.0001/50',
            Links: [
                {
                    twitter: '',
                    website: '',
                    telegram: ''
                }
            ],
            startsIn: '04/07/24 12:00:00',
            endsIn: '06/07/24 12:00:00',
            edit: false
        },
        {
            name: 'Cat3',
            img: Images.coinCat,
            saleType: 'Privatesale',
            hardcap: 1,
            softCap: 1,
            badge: '-',
            status: '0.0001/50',
            Links: [
                {
                    twitter: '',
                    website: '',
                    telegram: ''
                }
            ],
            startsIn: '04/07/24 12:00:00',
            endsIn: '06/07/24 12:00:00',
            edit: false
        }
    ],
    adminKYCTable: [
        {
            walletAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'No KYC',
        },
        {
            walletAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'No KYC',
        },
        {
            walletAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'No KYC',
        },
        {
            walletAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'No KYC',
        },

    ],
    adminSettingsTable: [
        {
            settingName: 'Introduction',
            settingValue: 'https://www.google.com/',
        },
        {
            settingName: 'Introduction',
            settingValue: 'https://www.google.com/',
        },
        {
            settingName: 'Introduction',
            settingValue: 'https://www.google.com/',
        },

    ],
    adminFeeTable: [
        {
            feeName: 'BNBRaised',
            currencyValue: 5,
            tokenvalue: 5
        },
        {
            feeName: 'BNBRaised',
            currencyValue: 5,
            tokenvalue: 5
        },
        {
            feeName: 'BNBRaised',
            currencyValue: 5,
            tokenvalue: 5
        },
        {
            feeName: 'BNBRaised',
            currencyValue: 5,
            tokenvalue: 5
        },
    ],
    adminAuditTable: [
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'Standard',
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'Standard',
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'Standard',
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            status: 'Standard',
        },
    ],
    adminCMSTable: [
        {
            identifier: 'Standards',
            title: 'Standard',
            content: 'Mint standard tokens on Solana.'
        },
        {
            identifier: 'Standards',
            title: 'Standard',
            content: 'Mint standard tokens on Solana.'
        },
        {
            identifier: 'Standards',
            title: 'Standard',
            content: 'Mint standard tokens on Solana.'
        },
        {
            identifier: 'Standards',
            title: 'Standard',
            content: 'Mint standard tokens on Solana.'
        },
    ],
    adminCreatedLaunchpadTable: [
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            symbol: 'ADP',
            type: 'Private'
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            symbol: 'Standard',
            type: 'Fair Launch'
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            symbol: 'Standard',
            type: 'Launchpad'
        },
        {
            saleAddress: '0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e',
            symbol: 'Standard',
            type: 'Private'
        },
    ],
}