import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import Classes from '../../asset/styles/LaunchpadDetail.module.css'
import { Images } from '../../data/Images'
import Countdown from 'react-countdown';
import { commonData } from '../../data/CommonData';
import { Col, Row } from 'react-bootstrap';
import Chart from 'react-apexcharts'
import AddUserWhitelist from '../../Modals/AddUserWhitelist';
import SampleCSV from '../../Modals/SampleCSV';
import RemoveWhtieListUser from '../../Modals/RemoveWhtieListUser';
import { CiGlobe } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../lib/isEmpty';
import ConnectWalletModal from '../../Modals/ConnectWalletModal';
import Walletbutton from '../../components/walletbutton';
import Config from "../../config"
import toast from 'react-hot-toast';
import Usetoken from "../../hooks/useToken";
import Usesale from "../../hooks/useSale";
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useParams } from "react-router-dom";
import { EditWhiteListBuyAmount, EditWhiteListStatus, getallsalehook, GetWhiteListSale, GetWhiteListUser } from "../../actions/userAction";
import { duration } from '../../lib/dateTimeHelper';

const PremiumLaunchDetail = () => {

  const saleHook = Usesale()
  const params = useParams()
  const tokenHook = Usetoken()


  const [steps, setSteps] = useState(1)
  const [saleType, setSaleType] = useState('public')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [wallet, setWallet] = useState(false)
  const [saleInfo, setSaleInfo] = useState({})
  const [saleDBInfo, setSaleDBInfo] = useState({})
  const [userData, setUserData] = useState({})
  const [max, setMax] = useState(0)
  const [isMax, setIsMax] = useState(false)
  const [amount, setAmount] = useState(0)
  const [saleAdd, setSaleAdd] = useState('')
  const [tokeninfo, setTokeninfo] = useState({})
  const [whitelistUserBuy, setwhitelistUserBuy] = useState(0)
  const [isbuy, setIsbuy] = useState(false)
  const [isclaim, setIsclaiming] = useState(false)
  const [isfinalize, setIsfinalize] = useState(false)
  const [userinfo, setUserinfo] = useState({})
  const [deposit, setDeposit] = useState(0)
  const [isdeposit, setIsdeposit] = useState(false)
  const [errors, setErrors] = useState({})
  const [buy, setBuy] = useState(false)
  const [status, setStatus] = useState(true);
  const [remainingbuyAmount, setremainingbuyAmount] = useState(0);



  // add whitelist modals states
  const [addUserWhiteList, setAddUserWhiteList] = useState(false)
  const handleShowAddWhiteList = () => setAddUserWhiteList(true)
  const handleCloseAddWhiteList = () => setAddUserWhiteList(false)

  // remove whitelist modals states
  const [removeUserWhiteList, setRemoveUserWhiteList] = useState(false)
  const handleShowRemoveWhiteList = () => setRemoveUserWhiteList(true)
  const handleCloseRemoveWhiteList = () => setRemoveUserWhiteList(false)

  // csv modal states
  const [showCSV, setShowCSV] = useState(false)
  const handleShowCSV = () => setShowCSV(true)
  const handleCloseCSV = () => setShowCSV(false)
  const [whitelistamount, setWhiteListamount] = useState(0)
  const [whitelistuser, setWhiteListuser] = useState("")
  const [walletAddress, setwalletAddress] = useState(sessionStorage.getItem("walletAddress"))


  const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_ballance", walletdetail)
  var percentage = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)
  var percentage_launch = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)
  useEffect(() => {
    setwalletAddress(sessionStorage.getItem("walletAddress"))

    window.scrollTo(0, 0);
    getSaleData()
    getwhiteListdata()

  }, [status, walletdetail?.walletaddress])

  const getSaleData = async () => {

    let datas = await saleHook?.UseSaleInfo(params?.saleAddress)
    console.log("getSaleDBData1_LaunchDetail", datas, datas?.splMint.toString())
    setSaleAdd(params?.saleAddress)
    setSaleInfo(datas)


    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    const data = getSalefromDb?.data?.data
    let DBData = {}
    if (data?.length > 0) {
      DBData = data?.find((val) => (val?.saleAddress == params?.saleAddress))
      console.log("DBData", DBData)
    }

    setSaleDBInfo(DBData)



    // let data = sessionStorage.getItem("saledata")
    // let Obj = JSON.parse(data)
    // console.log("getSaleData_LaunchDetail", Obj, Obj?.account?.minimumBuy, Obj?.account?.maximumBuy)
    // setSaleAdd(Obj?.publicKey)
    // setSaleInfo(Obj?.account)


    // let DBdata = sessionStorage.getItem("saleDBdata")
    // let DBObj = JSON.parse(DBdata)
    // console.log("getSaleDBData_LaunchDetail", DBObj)
    // setSaleDBInfo(DBObj)
  }





  const editwhitelistStatus = async (data) => {
    let info = {
      presaleAddress: saleAdd,
      status: data
    }

    if (data) {
      setSaleType("whiteList")

    } else {
      setSaleType("public")

    }

    // let saleType = data ? "Private Sale" : "Launchpad";
    let editsaleType = await saleHook.UseChangeSaleAccess(data, saleInfo?.launchType, saleInfo?.splMint);

    console.log("editsaleType>>>>", editsaleType);


    if (editsaleType?.status) {

      let result = await EditWhiteListStatus(info);

      if (result?.data?.status) {
        setStatus(!status)

        if (data) {
          toast.success("Private sale is Enabled")
        } else {
          toast.success("Private sale is Disabled")
        }
      }
    }
    // window.location.reload()
    // console.log("editwhitelistStatus", result?.data?.status);

  }


  const maxBuy = async () => {
    setMax(saleInfo && (parseInt(saleInfo?.maximumBuy) / LAMPORTS_PER_SOL))
    setIsMax(true)
    setAmount(saleInfo && (parseInt(saleInfo?.maximumBuy) / LAMPORTS_PER_SOL))
  }

  const OnchangeBuy = (value) => {
    var errors = {}
    if (value > (parseInt(saleInfo?.maximumBuy) / LAMPORTS_PER_SOL)) {
      errors.amount = "Amount should not be greater than max amount"
      setBuy(true)
    }
    else if (value < (parseInt(saleInfo?.minimumBuy) / LAMPORTS_PER_SOL)) {
      errors.amount = "Amount should not be lesser than min amount"
      setBuy(true)
    }
    else {
      errors.amount = ""
      setBuy(false)
    }
    setErrors(errors)
    return errors
  }




  const Claim = async () => {
    setIsclaiming(true)
    var result = await saleHook.UseClaimToken(saleInfo?.splMint, saleAdd)
    console.log("finalizeSale_result", result)
    setIsclaiming(false)
    getUserData()
    if (result?.status == true) {
      toast.success('Sale claimed Successfully')
    }
    else if (result?.status == false && saleInfo?.isVested == true) {
      if (!isEmpty(result.error)) {
        toast.error(result.error)
      }
      else {
        toast.error('Error while claiming token...!')
      }

    }
    else if (result?.status == false) {
      toast.error('Error while claiming token...!')
    }

    // result?.status == true ?
    //   toast.success('Sale claimed Successfully')
    //   :
    //   toast.error('Sale not claimed')
  }


  const finalizeSale = async () => {
    if (saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale") {
      setIsfinalize(true)
      var result = await saleHook.UseClosePresale(saleInfo?.splMint)
      console.log("finalizeSale_result", result)
      setIsfinalize(false)
      getSaleData()
      result?.status == true ?
        toast.success('Sale finalized Successfully')
        :
        toast.error('Error while finalizing the sale...!')
    }
    else {
      setIsfinalize(true)
      var result = await saleHook.UseClosePresaleFairLaunch(saleInfo?.splMint)
      console.log("finalizeSale_result_fair", result)
      setIsfinalize(false)
      getSaleData()
      result?.status == true ?
        toast.success('Sale finalized Successfully')
        :
        toast.error('Error while finalizing the sale...!')
    }

  }

  // const calculateToken= async(value)=>{
  //   console.log("maxEthLimit", saleInfo?.maximumBuy)
  //   const tokenValue = await calculateBuy(saleAdd, value);
  //   console.log("tokenValue", tokenValue)
  //   this.setState({ claim: (tokenValue) })
  //   this.setState({ isMax: false })
  // }

  const getwhiteListdata = async () => {

    let data = await GetWhiteListUser(walletdetail?.walletaddress, params?.saleAddress);

    let saleinfo = await GetWhiteListSale(params?.saleAddress);
    console.log("getwhiteListdata", saleinfo, saleinfo[0], saleinfo[0]?.isWhiteListed);
    if (saleinfo?.length > 0) {
      setSaleType(saleinfo[0]?.isWhiteListed ? "whiteList" : "public")

      if (!isEmpty(data) && !isEmpty(data[0])) {
        setWhiteListamount(data[0] && Number(data[0]?.whiteListData?.bnbvalue) || 0);
        setWhiteListuser(data[0] && data[0]?.whiteListData?.user || "");
        setwhitelistUserBuy(data[0] && data[0]?.whiteListData?.buyAmount || 0)
        let remainingamount = parseFloat(data[0]?.whiteListData?.bnbvalue) - parseFloat(data[0]?.whiteListData?.buyAmount || 0);
        console.log("remainingamount", remainingamount);
        setremainingbuyAmount(parseFloat(remainingamount)?.toFixed(2));
      }

    }


  }

  const buyToken = async () => {

    setIsbuy(true)
    if (saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale") {

      if (saleInfo?.isWhitelist == true && saleInfo?.useWithToken == false) {
        console.log("checking..........")

        if (walletAddress != whitelistuser) {
          toast.error("Your are not whiteListed user")
          setIsbuy(false)

          return false
        }
        if (parseFloat(whitelistamount) == parseFloat(whitelistUserBuy)) {


          toast.error("Your are bought all your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        console.log("whitelistamount", whitelistamount, amount);


        if (parseFloat(amount) > parseFloat(whitelistamount)) {
          toast.error("Your buy amount exceed the your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        if (parseFloat(amount) > parseFloat(remainingbuyAmount)) {
          toast.error(`your remaining allocated whitelist amount is ${remainingbuyAmount}`)
          setIsbuy(false)
          return false
        }

        let quantity = amount * (saleInfo?.ppt / LAMPORTS_PER_SOL)
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookToken((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')

        if (result?.status) {
          let buyamountdata = {
            saleAddress: saleAdd,
            walletAddress: walletdetail?.walletaddress,
            buyAmount: Number(amount)
          }

          let editBuyAmount = await EditWhiteListBuyAmount(buyamountdata)
          setStatus(!status)

        }
      }

      else if (saleInfo?.isWhitelist == true && saleInfo?.useWithToken == true) {
        console.log("checking..........")

        if (walletAddress != whitelistuser) {
          toast.error("Your are not whiteListed user")
          setIsbuy(false)

          return false
        }
        if (parseFloat(whitelistamount) == parseFloat(whitelistUserBuy)) {


          toast.error("Your are bought all your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        console.log("whitelistamount", whitelistamount, amount);


        if (parseFloat(amount) > parseFloat(whitelistamount)) {
          toast.error("Your buy amount exceed the your allocated whitelist amount")
          setIsbuy(false)
          return false
        } if (parseFloat(amount) > parseFloat(remainingbuyAmount)) {
          toast.error(`your remaining allocated whitelist amount is ${remainingbuyAmount}`)
          setIsbuy(false)
          return false
        }


        let quantity = amount
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookTokenUseWithToken((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleInfo?.buyMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')

        if (result?.status) {
          let buyamountdata = {
            saleAddress: saleAdd,
            walletAddress: walletdetail?.walletaddress,
            buyAmount: Number(amount)
          }

          let editBuyAmount = await EditWhiteListBuyAmount(buyamountdata)
          setStatus(!status)

        }
      }

      else if (saleInfo?.isWhitelist == false && saleInfo?.useWithToken == false) {
        let quantity = amount * (saleInfo?.ppt / LAMPORTS_PER_SOL)
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookToken((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')
      }

      else if (saleInfo?.isWhitelist == false && saleInfo?.useWithToken == true) {

        let quantity = amount
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookTokenUseWithToken((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleInfo?.buyMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')
      }
    } else if (saleInfo?.launchType == "FairLaunch") {

      if (saleInfo?.isWhitelist == true && saleInfo?.useWithToken == false) {
        if (walletAddress != whitelistuser) {
          toast.error("Your are not whiteListed user")
          setIsbuy(false)

          return false
        }
        if (parseFloat(whitelistamount) == parseFloat(whitelistUserBuy)) {


          toast.error("Your are bought all your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        console.log("whitelistamount", whitelistamount, amount);


        if (parseFloat(amount) > parseFloat(whitelistamount)) {
          toast.error("Your buy amount exceed the your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        if (parseFloat(amount) > parseFloat(remainingbuyAmount)) {
          toast.error(`your remaining allocated whitelist amount is ${remainingbuyAmount}`)
          setIsbuy(false)
          return false
        }

        let quantity = amount
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookTokenFairLaunch((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')

        if (result?.status) {
          let buyamountdata = {
            saleAddress: saleAdd,
            walletAddress: walletdetail?.walletaddress,
            buyAmount: Number(amount)
          }

          let editBuyAmount = await EditWhiteListBuyAmount(buyamountdata)
          setStatus(!status)

          console.log("editBuyAmount>>>>>>>>>>>", editBuyAmount)
        }

      }

      else if (saleInfo?.isWhitelist == true && saleInfo?.useWithToken == true) {

        if (walletAddress != whitelistuser) {
          toast.error("Your are not whiteListed user")
          setIsbuy(false)

          return false
        }
        if (parseFloat(whitelistamount) == parseFloat(whitelistUserBuy)) {


          toast.error("Your are bought all your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        console.log("whitelistamount", whitelistamount, amount);


        if (parseFloat(amount) > parseFloat(whitelistamount)) {
          toast.error("Your buy amount exceed the your allocated whitelist amount")
          setIsbuy(false)
          return false
        }
        if (parseFloat(amount) > parseFloat(remainingbuyAmount)) {
          toast.error(`your remaining allocated whitelist amount is ${remainingbuyAmount}`)
          setIsbuy(false)
          return false
        }

        let quantity = amount
        console.log("quantity", quantity, (quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)
        var result = await saleHook.UseBookTokenUseWithToken((quantity * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.splMint, saleInfo?.buyMint, saleAdd)
        console.log("result", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')


          :
          toast.error('Error while booking token...!')

        if (result?.status) {
          let buyamountdata = {
            saleAddress: saleAdd,
            walletAddress: walletdetail?.walletaddress,
            buyAmount: Number(amount)
          }

          let editBuyAmount = await EditWhiteListBuyAmount(buyamountdata)
          setStatus(!status)

          console.log("editBuyAmount>>>>>>>>>>>", editBuyAmount)
        }

      }

      else if (saleInfo?.isWhitelist == false && saleInfo?.useWithToken == false) {
        var result = await saleHook.UseBookTokenFairLaunch(amount * LAMPORTS_PER_SOL, saleInfo?.splMint, saleAdd)
        console.log("buyToken_result_fair", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')
          :
          toast.error('Error while booking token...!')

      }

      else if (saleInfo?.isWhitelist == false && saleInfo?.useWithToken == true) {
        var result = await saleHook.UseBookTokenUseWithToken(amount * LAMPORTS_PER_SOL, saleInfo?.splMint, saleInfo?.buyMint, saleAdd)
        console.log("buyToken_result_fair", result)
        getSaleData()
        getUserData()
        setAmount(0)
        setIsbuy(false)
        result?.status == true ?
          toast.success('Token booked Successfully')
          :
          toast.error('Error while booking token...!')

      }
    }
  }



  // const Claim = async()=>{
  //   await claimToken(this.state.saleAddress, getAccount());
  //   await this.refreshData()

  // }

  useEffect(() => {
    getTokenInfo()
  }, [saleInfo?.splMint])

  const getTokenInfo = async () => {
    console.log("tokeninfoooo", saleInfo?.splMint)
    if (!isEmpty(saleInfo?.splMint)) {
      // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress,  saleInfo?.splMint)
      let tokenInfo = await tokenHook.getTokenNameSymbol(saleInfo?.splMint)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
    }
  }



  useEffect(() => {
    if (walletdetail?.walletaddress) {
      getUserData()
    }
  }, [params?.saleAddress, walletdetail?.walletaddress])

  const getUserData = async () => {
    console.log('getUserData', walletdetail?.walletaddress)
    let userData = await saleHook.UseUserBookings(params?.saleAddress, walletdetail?.walletaddress)
    console.log("userData", userData, userData?.tokensBooked / LAMPORTS_PER_SOL)
    setUserinfo(userData)

  }


  const calculateDepositTokens = () => {
    if (saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale") {
      let topancakeTokens = 0;
      let pancakeTokens = 0;
      const presalePrice = 1 / (saleInfo?.ppt / LAMPORTS_PER_SOL);
      if (saleInfo?.isListing) {
        const pancakePrice = 1 / saleInfo?.listingRate;
        topancakeTokens = (saleInfo?.hardCap / LAMPORTS_PER_SOL) * saleInfo?.liquidityPercent / 100;
        pancakeTokens = topancakeTokens / pancakePrice;
        console.log("pancake tokens : ", pancakeTokens)
      }
      const netTokens = (saleInfo?.hardCap / LAMPORTS_PER_SOL) / presalePrice;
      console.log("netTokens", netTokens)
      console.log("depositAmount", pancakeTokens, netTokens, pancakeTokens + netTokens)
      setDeposit(pancakeTokens + netTokens);
    }
    else {
      var DepositToken = tokeninfo?.tokenBalance
      console.log("DepositToken", DepositToken)
      setDeposit(DepositToken);
    }

  }


  const depositToken = async () => {

    setIsdeposit(true)
    const resp = await saleHook.UseDepositFunds(saleInfo?.splMint?.toString(), deposit)
    console.log("depositFn", resp)
    setIsdeposit(false)
    getSaleData()
    resp?.status == true ?
      toast.success('Tokens deposited Successfully')
      :
      toast.error('Error while depositing token...!')
    if (resp?.status == true) {
      setSteps(7)
    }
    else {
      setSteps(6)
    }
  }

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{days < 10 ? `0${days}` : days}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{hours < 10 ? `0${hours}` : hours}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{minutes < 10 ? `0${minutes}` : minutes}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
      </div>
    )
  }
  // chart options
  const [chatValue, setChatValue] = useState([60, 30, 10])
  const state = {

    series: [
      // ((saleInfo?.hardCap / LAMPORTS_PER_SOL) * saleInfo?.ppt / LAMPORTS_PER_SOL),
      isEmpty(saleInfo?.soldTokens / LAMPORTS_PER_SOL) ? 0 : saleInfo?.soldTokens / LAMPORTS_PER_SOL,
      ((saleInfo?.hardCap / LAMPORTS_PER_SOL) * saleInfo?.ppt / LAMPORTS_PER_SOL) - (saleInfo?.soldTokens / LAMPORTS_PER_SOL)
    ],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ["Sold Tokens", "Unsold Tokens"],
      colors: ["#1e1e1e", "#1A23FF"],
      stroke: {
        width: 0
      },
      legend: {
        show: false
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 400
            },
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: 350
            },
          }
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 300
            },
          }
        },
      ]
    },


  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  let ClaimAmount = amount * (saleInfo?.ppt / LAMPORTS_PER_SOL)
  console.log("quantity_render", amount, ClaimAmount, (ClaimAmount * LAMPORTS_PER_SOL).toFixed(0), saleInfo?.ppt / LAMPORTS_PER_SOL)

  return (
    <>
      <Layout>

        {!saleDBInfo?.isActive ?


          <div className='fulllist'>

            <h5 className='roboto text-white'>The sale is now currently not avaiable</h5>

          </div>

          :

          <section className="custom_section position-relative">
            <img
              src={Images.lighting}
              className={`${Classes.absLighting} img-fluid`}
              alt=""
            />
            <Row className={`mx-auto ${Classes.dash__emptyRow}`}>
              <Col
                lg={{ span: 9, offset: 2 }}
                xs={12}
                className={`px-lg-0 ${Classes.dash__emptyBorder}`}
              >

                {saleInfo && saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ?
                  <>
                    <div
                      className={`${Classes.countdownHolder} mt-4 position-relative`}
                    >
                      <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleStart))} renderer={renderer} />
                      <div
                        className={`${Classes.statusShower} d-flex justify-content-center align-items-center gap-1`}
                      >
                        <p
                          className={`${Classes.saleStatus} text-uppercase aboro_font`}
                        >
                          Coming up
                        </p>
                        <img
                          src={Images.goldIndi}
                          className={`${Classes.indicateImg} img-fluid mb-1`}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                  :
                  saleInfo && saleInfo.ready !== 2 && saleHook.isSaleLive(saleInfo.presaleStart, saleInfo.presaleEnd) ?
                    <>
                      <div
                        className={`${Classes.countdownHolder} mt-4 position-relative`}
                      >
                        <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleEnd))} renderer={renderer} />
                        <div
                          className={`${Classes.statusShower} d-flex justify-content-center align-items-center gap-1`}
                        >
                          <p
                            className={`${Classes.saleStatus} text-uppercase aboro_font`}
                          >
                            Ends in
                          </p>
                          <img
                            src={Images.goldIndi}
                            className={`${Classes.indicateImg} img-fluid mb-1`}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                    :
                    saleInfo.ready == 2 || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ?
                      <>
                        <div
                          className={`${Classes.countdownHolder} mt-4 position-relative`}
                        >
                          <div
                            className={`${Classes.statusShower} d-flex justify-content-center align-items-center gap-1`}
                          >
                            <p
                              className={`${Classes.saleStatus} text-uppercase aboro_font`}
                            >
                              Closed
                            </p>
                            <img
                              src={Images.goldIndi}
                              className={`${Classes.indicateImg} img-fluid mb-1`}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                      : <></>
                }


                <div className={`${Classes.catImgBg} position-relative mt-5`}>
                  <img
                    src={Images.clip}
                    className={`img-fluid ${Classes.leftTopClip}`}
                    alt=""
                  />
                  <img
                    src={Images.clip}
                    className={`img-fluid ${Classes.leftBottomClip}`}
                    alt=""
                  />
                  <img
                    src={Images.clip}
                    className={`img-fluid ${Classes.rightTopClip}`}
                    alt=""
                  />
                  <img
                    src={Images.clip}
                    className={`img-fluid ${Classes.rightBottomClip}`}
                    alt=""
                  />
                  <Row className={``}>
                    <Col lg={9} md={8} xs={12} className={``}>
                      <div className={`p-4`}>
                        <div className={`${Classes.launchpad__nameHolder}`}>
                          <h1
                            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}
                          >
                            {tokeninfo?.name ? tokeninfo?.name : 'Meme of Neko'}
                          </h1>
                        </div>

                        <p
                          className={`${Classes.launchpad__desc} ${Classes.responsiveWidText} m-0 text-uppercase mt-3 roboto`}
                        >
                          {/* A promising meme on solana chain */}
                          {saleDBInfo?.description}
                        </p>
                        <div
                          className={`d-flex justify-content-start align-items-center gap-3 mt-3`}
                        >

                          <NavLink to={saleDBInfo?.website} target="_blank">
                            <button
                              className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                            >
                              <CiGlobe className={`${Classes.globeImg}`} />
                            </button>
                          </NavLink>

                          <NavLink to={saleDBInfo?.twitter} target="_blank">
                            <button
                              className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                            >
                              <FaXTwitter />
                            </button>
                          </NavLink>

                          <NavLink to={saleDBInfo?.telegram} target="_blank">

                            <button
                              className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                            >
                              <FaTelegramPlane />
                            </button>
                          </NavLink>

                          {saleInfo?.authority?.toString() == walletdetail?.walletaddress &&
                            <NavLink to={`/edit-launchpad/${saleAdd}`} state={saleDBInfo}>
                              <button
                                className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                              >
                                <MdEdit />
                              </button>
                            </NavLink>
                          }
                        </div>

                        {saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
                          <div className={`${Classes.card__progressHolder} mt-4`}>
                            <div
                              className={`${Classes.card__progressBar} position-relative`}>
                              {console.log("currencyRaised", saleInfo?.currencyRaised, parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100))}

                              <div
                                className={`${Classes.card__filledProgress}`}
                                style={{ width: `${saleInfo && percentage_launch > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)}%` }}
                              ></div>
                            </div>

                            <div
                              className={`d-flex justify-content-between align-items-center`}
                            >
                              <p
                                className={`${Classes.card__progressValue} roboto`}
                              >
                                {saleInfo && (parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                    saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}
                              </p>
                              <p
                                className={`${Classes.card__progressFullValue} text-white roboto mt-2`}
                              >
                                {(parseInt(saleInfo?.hardCap) / LAMPORTS_PER_SOL)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                    saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}
                              </p>
                            </div>

                          </div>

                          :
                          <div className={`${Classes.card__progressHolder} mt-4`}>
                            <div
                              className={`${Classes.card__progressBar} position-relative`}
                            >
                              <div
                                className={`${Classes.card__filledProgress}`}
                                style={{ width: `${saleInfo && percentage > 100 ? 100 : parseInt((saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / (saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)}%` }}
                              ></div>
                            </div>

                            <div
                              className={`d-flex justify-content-between align-items-center`}
                            >
                              <p
                                className={`${Classes.card__progressValue} roboto`}
                              >
                                {saleInfo && (parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                    saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}
                              </p>
                              <p
                                className={`${Classes.card__progressFullValue} text-white roboto mt-2`}
                              >
                                {(parseInt(saleInfo?.softCap) / LAMPORTS_PER_SOL)}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                    saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}
                              </p>
                            </div>

                          </div>
                        }


                        <div className={`${Classes.singleInpHolder} mt-4`}>
                          <label
                            for="cost"
                            className={`${Classes.inputLabel} roboto`}
                          >
                            Amount (Min: {saleInfo && saleInfo?.minimumBuy / LAMPORTS_PER_SOL} {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                              saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"} & Max: {saleInfo && saleInfo?.maximumBuy / LAMPORTS_PER_SOL}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                  saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                    saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"})
                          </label>
                          <br />
                          <div
                            className={`${Classes.infoInpHolder} position-relative mt-3 p-2 d-flex justify-content-between align-items-center`}
                          >
                            <input
                              type="number"
                              id="cost"
                              name="cost"
                              className={`${Classes.inputBox} inter_font  w-100`}
                              autoComplete="off"
                              value={amount}
                              onChange={(e) => {
                                var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                                if (!numbers.test(e.target.value) && e.target.value !== "") {
                                  return false
                                }
                                setAmount(e.target.value);
                                OnchangeBuy(e.target.value)
                              }}
                            />
                            <button
                              className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1`}
                              onClick={async () => {
                                await maxBuy()
                                console.log("buyyy", saleInfo && (parseInt(saleInfo?.maximumBuy) / LAMPORTS_PER_SOL))
                              }}
                            >
                              Max
                            </button>
                          </div>
                          <span className={`cmnErrorHint`}>{errors?.amount && errors?.amount}</span>
                          <br />

                          {/* {saleInfo && saleHook?.isSaleLive(saleInfo?.presaleStart, saleInfo?.presaleEnd) || saleHook.isUpcoming(saleInfo?.presaleStart, saleInfo?.presaleEnd) ?
                          <button onClick={() => { buyToken() }}
                            disabled={saleInfo && saleInfo?.isWhitelisted ? userData && !(userData?.userWhitelistedAmount > 0) : false}
                            className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1`}>
                            {saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy") : "Buy"} with {this.state.buyToken} </button> :
                          ((!saleHook?.isSaleLive(saleInfo?.presaleStart, saleInfo?.presaleEnd) ?
                            <button disabled={true} className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1`}> Sale InActive </button> : */}



                          {saleInfo?.launchType == "Private Sale" && walletAddress == whitelistuser ?
                            <>
                              <label
                                for="cost"
                                className={`${Classes.inputLabel} ${Classes.fsmall} mt-2 roboto`}
                              >
                                your allocated whitelist amount: {whitelistamount} SOL
                              </label>
                              <br />
                              <label
                                for="cost"
                                className={`${Classes.inputLabel} ${Classes.fsmall} mt-2 roboto`}
                              >
                                your remaining allocated whitelist amount: {remainingbuyAmount} SOL
                              </label>
                            </>

                            : ""} <br />
                          {saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
                            <label
                              for="cost"
                              className={`${Classes.inputLabel} ${Classes.fsmall} mt-2 roboto`}
                            >
                              You will get : {ClaimAmount} {tokeninfo?.symbol}
                            </label> :
                            saleInfo?.launchType == "FairLaunch" && isEmpty(saleInfo?.splStock) ?
                              <label
                                for="cost"
                                className={`${Classes.inputLabel} ${Classes.fsmall} mt-2 roboto`}
                              >
                                You will get : {ClaimAmount} {tokeninfo?.symbol}
                              </label> :
                              ""}
                          <br />

                          <br />

                          <button onClick={() => { buyToken() }}
                            className={`${Classes.gradientBtnSmall} ${Classes.f17} border-0 outline-0 mt-3 mb-0 px-3 py-1`}
                            disabled={saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ||
                              saleInfo?.ready == 2 ||
                              saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) || buy == true}>
                            {isbuy ? 'Buying...' : 'Buy'}
                          </button>

                          {saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
                            <button onClick={() => { Claim() }} disabled={isEmpty(userinfo?.tokensBooked) || saleInfo?.ready !== 2}
                              className={`${Classes.gradientBtnSmall} ${Classes.f17} ms-2 border-0 outline-0 mt-3 mb-0 px-3 py-1`} >

                              {isclaim && saleInfo?.isVested == false ? 'Claiming...' :
                                isclaim && saleInfo?.isVested == true ? 'Claiming Vest...' :
                                  !isclaim && saleInfo?.isVested == true ? 'Vesting Claim'
                                    : 'Claim'}
                            </button>
                            :
                            <button onClick={() => { Claim() }}
                              disabled={isEmpty(userinfo) || saleInfo?.ready !== 2 || (saleInfo.isVested == false && !isEmpty(userinfo?.lastClaimed))}
                              className={`${Classes.gradientBtnSmall} ${Classes.f17} ms-2 border-0 outline-0 mt-3 mb-0 px-3 py-1`} >
                              {isclaim && saleInfo?.isVested == false ? 'Claiming...' :
                                isclaim && saleInfo?.isVested == true ? 'Claiming Vest...' :
                                  !isclaim && saleInfo?.isVested == true ? 'Vesting Claim'
                                    : 'Claim'}
                            </button>
                          }

                          {/* ))
                        } */}

                        </div>

                        {/* <button
                        className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-5`}
                      >
                        <i
                          class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`}
                        />
                        <i
                          class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`}
                        />
                        Connect Wallet
                      </button> */}
                        {/* 
                      {isEmpty(walletdetail.walletaddress) ?
                        <button
                          className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-5`}
                          onClick={handleShow}
                        >
                          <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                          <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                          Connect Wallet
                        </button>
                        : <button
                          className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-5`} disabled
                        // onClick={handleShow} 
                        >
                          <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                          <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                          {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                        </button>
                      } */}

                        <div className="mt-3">
                          <Walletbutton />
                        </div>


                      </div>
                    </Col>
                    <Col lg={3} md={4} xs={12} className={``}>

                      <div
                        className={`d-flex justify-content-between flex-column flex-md-column align-items-start`}
                      >

                        <img
                          src={saleDBInfo?.banner ? saleDBInfo?.banner : Images.cat}
                          className={`img-fluid ${Classes.cattyImg} flex-grow-1`}
                        />

                        <ul
                          className={`${Classes.salesCard__unorderList} ${Classes.launchlist} flex-grow-1 w-100 mb-0`}
                        >
                          <li
                            className={`${Classes.salesCard__lister} py-2 px-3`}
                          >
                            <div
                              className={`d-flex justify-content-between align-items-center`}
                            >
                              <img
                                src={Images.triangle}
                                className={`img-fluid ${Classes.rotatedArrow}`}
                              />
                              <p className={`${Classes.listText} roboto`}>
                                Premium Gem
                              </p>
                            </div>
                          </li>
                          {!isEmpty(saleDBInfo?.kyc) &&
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}
                            >
                              <div
                                className={`d-flex justify-content-between align-items-center`}
                              >
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                {/* <p
                              className={`${Classes.listText} roboto text-uppercase`}
                            >
                              KYC
                            </p> */}
                                <NavLink className="linkset" to={saleDBInfo?.kyc} target="_blank"> KYC
                                </NavLink>

                              </div>
                            </li>
                          }
                          {saleDBInfo?.status === "Active" ? <>
                            <ul className={`${Classes.salesCard__unorderList} flex-grow-1 w-100 mb-0`}>

                              {!isEmpty(saleDBInfo?.kyc) &&

                                <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                                  <div className={`d-flex justify-content-between align-items-center`}>
                                    <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                                    <NavLink className="linkset" to={saleDBInfo?.kyc}  > KYC
                                    </NavLink>                            </div>
                                </li>
                              }
                              {!isEmpty(saleDBInfo?.audit) &&

                                <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                                  <div className={`d-flex justify-content-between align-items-center`}>
                                    <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                                    <NavLink className="linkset" to={saleDBInfo?.audit}  > Audit
                                    </NavLink>                            </div>
                                </li>
                              }
                              {!isEmpty(saleDBInfo?.SAFU) &&

                                <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                                  <div className={`d-flex justify-content-between align-items-center`}>
                                    <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                                    {/* <p className={`${Classes.listText} roboto text-uppercase`}>safu</p> */}
                                    <NavLink className="linkset" to={saleDBInfo?.SAFU}  > Safu
                                    </NavLink>
                                  </div>
                                </li>
                              }
                              {!isEmpty(saleDBInfo?.DOXX) &&

                                <li className={`${Classes.salesCard__lister} py-2 px-3`}>
                                  <div className={`d-flex justify-content-between align-items-center`}>
                                    <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
                                    <NavLink className="linkset" to={saleDBInfo?.DOXX}> Doxx
                                    </NavLink>
                                    {/* <p className={`${Classes.listText} roboto`}>Doxx</p> */}
                                  </div>
                                </li>
                              }
                            </ul></> : <></>}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* pool status */}
                <Row className={``}>
                  <Col lg={4} className={``}>
                    <div className={`${Classes.poolStatusHolder} mt-5 pt-4`}>
                      {commonData?.launchpadInfoSteps.map((item, index) => {
                        let upcoming = saleInfo && saleHook.isUpcoming(parseInt(saleInfo?.presaleStart))
                        let start = saleInfo && saleInfo?.ready !== 2 && saleHook.isSaleLive(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd))
                        let stop = saleInfo && saleInfo?.ready == 2 || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd))
                        let status = false
                        if (item.key == 'upcoming') {
                          status = upcoming || start || stop ? true : false

                        } else if (item.key == 'start') {
                          status = start || stop ? true : false
                          item.hint = `Pool starts at ${saleInfo && new Date(parseInt(saleInfo?.presaleStart)).toLocaleDateString() + "  "
                            + new Date(parseInt(saleInfo?.presaleStart)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleStart)).getMinutes() + ":"
                            + new Date(parseInt(saleInfo?.presaleStart)).getSeconds()}`

                        } else if (item.key == 'end') {
                          status = stop ? true : false
                          item.hint = stop ? 'Pool Ended' : `Pool ends at ${saleInfo && new Date(parseInt(saleInfo?.presaleEnd)).toLocaleDateString() + "  "
                            + new Date(parseInt(saleInfo?.presaleEnd)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleEnd)).getMinutes() + ":"
                            + new Date(parseInt(saleInfo?.presaleEnd)).getSeconds()}`
                        }
                        return (
                          <div
                            className={`${Classes.singleStep} d-flex justify-content-start align-items-start gap-3 mb-1`}
                          >
                            <div
                              className={`${Classes.stepIndicator} d-flex flex-column justify-content-start align-items-center gap-1`}
                            >
                              <div
                                className={`${status
                                  ? Classes.stepRoundActive
                                  : Classes.stepRound
                                  } rounded-5`}
                              ></div>
                              <div
                                className={`${status
                                  ? Classes.stepLinerActive
                                  : Classes.stepLiner
                                  }`}
                              ></div>
                            </div>

                            <div
                              className={`${Classes.stepsHintHolder} flex-grow-1`}
                            >
                              <h5
                                className={`${status
                                  ? Classes.stepTitleActive
                                  : Classes.stepTitle
                                  } m-0 roboto`}
                              >
                                {item.title}
                              </h5>
                              <p
                                className={`${status
                                  ? Classes.stepHintActive
                                  : Classes.stepHint
                                  } ${Classes.stepsWidthAlign} m-0 roboto`}
                              >
                                {item.hint}
                              </p>
                            </div>
                          </div>

                        )
                      })}
                    </div>
                    {console.log("checktime", saleHook.isSaleLive(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)), saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)))}

                    <div
                      className={`${Classes.statusFlexHolder} ps-2 ps-sm-4 mt-5`}
                    >
                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                      >
                        <p className={`${Classes.flexLabel} roboto`}>Status</p>
                        <p className={`${Classes.stepHint} roboto`}>  {saleInfo && saleInfo?.ready !== 2 && saleHook.isSaleLive(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ? 'Live' : saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ? 'Upcoming' :
                          saleInfo.ready == 2 || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ? 'closed' : ''}</p>
                      </div>
                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                      >
                        <p className={`${Classes.flexLabel} roboto`}>Sale Type</p>
                        <p className={`${Classes.stepHint} roboto`}>{saleInfo?.isWhitelist == false ? 'Public' : 'Private'}</p>
                      </div>
                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                      >
                        <p className={`${Classes.flexLabel} roboto`}>
                          Currently Raised
                        </p>
                        <p className={`${Classes.stepHint} roboto`}>
                          {saleInfo && (parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL)}

                          {/* {saleInfo?.launchType == "Launchpad" ?
                          saleInfo && ((parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL) / (parseInt(saleInfo?.hardCap) / LAMPORTS_PER_SOL)).toFixed(5)
                          :
                          saleInfo && ((parseInt(saleInfo?.currencyRaised) / LAMPORTS_PER_SOL) / (parseInt(saleInfo?.softCap) / LAMPORTS_PER_SOL)).toFixed(5)
                        } */}
                        </p>
                      </div>


                      {saleInfo?.ready == 0 && saleInfo?.launchType == "FairLaunch" && parseInt(saleInfo?.presaleStart) < Date.now() ?
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto`}>
                            Your purchased
                          </p>
                          <p className={`${Classes.stepHint} roboto`}>
                            {userinfo && !isEmpty(userinfo?.tokensBooked) ? (parseInt(userinfo?.tokensBooked) / LAMPORTS_PER_SOL) : 0} {tokeninfo?.symbol}

                          </p>
                        </div> :
                        saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
                          <div
                            className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                          >
                            <p className={`${Classes.flexLabel} roboto`}>
                              Your purchased
                            </p>
                            <p className={`${Classes.stepHint} roboto`}>
                              {userinfo && !isEmpty(userinfo?.tokensBooked) ? (parseInt(userinfo?.tokensBooked) / LAMPORTS_PER_SOL) : 0} {tokeninfo?.symbol}

                            </p>
                          </div> : ""
                      }

                      {saleInfo?.ready == 0 && saleInfo?.launchType == "FairLaunch" && parseInt(saleInfo?.presaleStart) < Date.now() ?
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto`}>
                            Your investment
                          </p>
                          <p className={`${Classes.stepHint} roboto`}>
                            {userinfo && !isEmpty(userinfo?.userInvested) ? (parseInt(userinfo?.userInvested) / LAMPORTS_PER_SOL) : 0}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                              saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}

                          </p>
                        </div> :
                        saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
                          <div
                            className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}
                          >
                            <p className={`${Classes.flexLabel} roboto`}>
                              Your investment
                            </p>
                            <p className={`${Classes.stepHint} roboto`}>
                              {userinfo && !isEmpty(userinfo?.userInvested) ? (parseInt(userinfo?.userInvested) / LAMPORTS_PER_SOL) : 0}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                                saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                                  saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}

                            </p>
                          </div>
                          : ""
                      }



                    </div>
                  </Col>
                </Row>
              </Col>

              {/* end of pool status */}
              <Col lg={2} xs={0} className={`d-none d-lg-flex`}></Col>
            </Row>

            <div className={`${Classes.customFluidContainer}`}>
              <div className={`${Classes.shapeSection}`}>
                <Row className={`mx-atuo h-100 ${Classes.borderBotMaker}`}>
                  <Col sm={6} className={``}>
                    <div
                      className={`${Classes.detailPadAligner} p-3 p-sm-5 d-flex justify-content-center d-sm-block h-100`}
                    >
                      <div className={`${Classes.launchpad__nameHolder}`}>
                        <h1
                          className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}
                        >
                          information
                        </h1>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} className={``}>
                    <div
                      className={`${Classes.detailsOlHolder} d-flex justify-content-center align-items-center  py-5 h-100 position-relative`}
                    >
                      <img
                        src={Images.Flash}
                        className={`${Classes.flashImg} img-fluid`}
                        alt=""
                      />
                      <ol className={`${Classes.detailOl} ps-0`}>
                        <li className={`${Classes.detailList} aboro_font`}>
                          <p
                            className={`${Classes.detailListText} aboro_font text-uppercase ps-2 mb-4`}
                          >
                            project info
                          </p>
                        </li>
                        <li className={`${Classes.detailList} aboro_font`}>
                          <p
                            className={`${Classes.detailListText} aboro_font text-uppercase ps-2 mb-4`}
                          >
                            tokenomics
                          </p>
                        </li>
                        <li className={`${Classes.detailList} aboro_font`}>
                          <p
                            className={`${Classes.detailListText} aboro_font text-uppercase ps-2 `}
                          >
                            pool info
                          </p>
                        </li>
                      </ol>
                    </div>
                  </Col>
                </Row>

                <div className={`${Classes.siteInfoHolder} px-2 px-sm-4 px-md-5`}>
                  {/* <img
                  src={saleDBInfo?.logo ? saleDBInfo?.logo : Images.redCat}
                  className={`${Classes.redCatImg} img-fluid`}
                  alt=""
                /> */}

                  <ul className={`${Classes.detail__infoList} ps-0 mt-5`}>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      Meme of Neko is not only meme project on LineaETHana, we
                      also provide NFT Staking
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F310; Staking NFT link : https://nft.mekoLineaETHana.com
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F4B0; Buy presale, Earn passive income by staking your
                      Meko NFT in our platform &#x1F4B0;
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F4E2; Don't miss opportunity, just presale buyer minimum
                      2 LINEAETH will get free Meko NFT
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      Note : just 500 limited NFT for presale buyers
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      Example :
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      if you buy 4 LineaETH, you will get 2 NFT
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x2705; Mint Authority Revoked
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F976; Freeze Authority Revoked
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F525; LP Burn
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F004; Partnership with big influencer on Weibo & Btok
                    </li>
                    <li
                      className={`${Classes.detail__infoSingleList} roboto mb-2`}
                    >
                      &#x1F4A5; CEX List
                    </li>
                  </ul>

                  <iframe
                    className={`${Classes.iframer} my-5`}
                    src={saleDBInfo?.youtube}
                    // "https://www.youtube.com/embed/pCFIt7jLl8w?si=1g6bAFsf65UK6rWS"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  />
                </div>
                <div className={`${Classes.tokenomicsHolder}`}>
                  <Row
                    className={`${Classes.tokenomicsBoxBorder} mx-auto flex-column-reverse flex-lg-row px-0 h-100`}
                  >
                    <Col lg={8} className={`${Classes.tokenomicsBotBorder} px-0`}>
                      <div
                        className={`${Classes.statusFlexHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-4 h-100 position-relative`}
                      >
                        <img
                          src={Images.endFlash}
                          className={`${Classes.chartFlashLeftt} img-fluid`}
                          alt=""
                        />
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto mb-2`}>
                            Address
                          </p>
                          <p
                            className={`${Classes.stepHint} ${Classes.tokenAddress} roboto`}
                          >
                            {saleInfo && saleInfo?.splMint?.toString()}
                          </p>
                        </div>
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto  mb-2`}>
                            Name
                          </p>
                          <p className={`${Classes.stepHint} roboto`}>
                            {tokeninfo?.name ? tokeninfo?.name : 'Meme of Neko'}
                          </p>
                        </div>
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto  mb-2`}>
                            Symbol
                          </p>
                          <p className={`${Classes.stepHint} roboto`}>
                            {tokeninfo?.symbol ? tokeninfo?.symbol : 'MEKO'}
                          </p>
                        </div>
                        <div
                          className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto`}>
                            Decimals
                          </p>
                          <p className={`${Classes.stepHint} roboto  mb-2`}>
                            {tokeninfo?.decimal}

                          </p>
                        </div>
                        <div
                          className={`${Classes.statusSingleFlex}  d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}
                        >
                          <p className={`${Classes.flexLabel} roboto  mb-2`}>
                            Total Supply
                          </p>
                          <p className={`${Classes.stepHint} roboto`}>
                            {tokeninfo?.tokenBalance}
                          </p>
                        </div>
                      </div>
                    </Col>
                    {(saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == 'Private Sale') &&
                      <Col lg={4} className={`h-100 d-flex align-items-end px-0`}>
                        <div
                          className={`${Classes.tokenLeftBorder} h-100 d-flex justify-content-start justify-content-lg-center w-100 align-items-end mb-4 mb-sm-5 mb-lg-0`}
                        >
                          <div className={`${Classes.launchpad__nameHolder} `}>
                            <h1
                              className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}
                            >
                              tokenomics
                            </h1>
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>
                  {(saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == 'Private Sale') &&
                    <div
                      className={`${Classes.chartHolder} py-5 position-relative`}
                    >
                      <img
                        src={Images.endFlash}
                        className={`${Classes.chartFlashRight} img-fluid`}
                        alt=""
                      />
                      <Row className="justify-content-center">
                        <Col lg={10}>
                          <Row className={`h-100`}>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center d-sm-block"
                            >
                              <div
                                className={`${Classes.chartPieHolder} position-relative mb-4 mb-sm-0`}
                              >
                                <img
                                  src={Images.coinCat}
                                  className={`${Classes.detailCoinCat} img-fluid`}
                                  alt=""
                                />
                                {console.log("chart", saleInfo?.hardCap / LAMPORTS_PER_SOL, saleInfo?.ppt / LAMPORTS_PER_SOL, saleInfo?.ppt, (saleInfo?.hardCap / LAMPORTS_PER_SOL) * (saleInfo?.ppt), (saleInfo?.hardCap / LAMPORTS_PER_SOL) * (saleInfo?.ppt) - (1.7))}
                                {!isEmpty(saleInfo) ? <Chart
                                  options={state.options}
                                  // series={state.series}
                                  series={
                                    [
                                      // ((saleInfo?.splStock / LAMPORTS_PER_SOL) + (saleInfo?.currencyRaised / LAMPORTS_PER_SOL) * (saleInfo?.ppt / LAMPORTS_PER_SOL)),
                                      // isEmpty(saleInfo?.soldTokens / LAMPORTS_PER_SOL) ? 0 : saleInfo?.soldTokens / LAMPORTS_PER_SOL,
                                      // ((saleInfo?.splStock / LAMPORTS_PER_SOL) + (saleInfo?.currencyRaised / LAMPORTS_PER_SOL) * (saleInfo?.ppt / LAMPORTS_PER_SOL)) - (saleInfo?.soldTokens / LAMPORTS_PER_SOL)
                                      isEmpty(saleInfo?.soldTokens / LAMPORTS_PER_SOL) ? 0 : saleInfo?.soldTokens / LAMPORTS_PER_SOL,
                                      ((saleInfo?.hardCap / LAMPORTS_PER_SOL) * saleInfo?.ppt / LAMPORTS_PER_SOL) - (saleInfo?.soldTokens / LAMPORTS_PER_SOL)

                                    ]}
                                  type="pie"
                                  width="100%"
                                  height={500}
                                /> : ""}
                              </div>
                            </Col>


                            <Col sm={6}>

                              <div
                                className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}
                              >
                                <p
                                  className={`${Classes.singleLegendLabel} m-0 orbitron`}
                                >
                                  Total Tokens   -  {((saleInfo?.hardCap / LAMPORTS_PER_SOL) * saleInfo?.ppt / LAMPORTS_PER_SOL)}
                                </p>
                              </div>
                              <div
                                className={`${Classes.chartLegendsHolder} h-100 d-flex align-items-center justify-content-center`}
                              >
                                <div>
                                  {/* <div
                                  className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}
                                >                               
                                  <div className={`${Classes.color_rec}`} style={{ backgroundColor: "#011789" }}></div>
                                  <p
                                    className={`${Classes.singleLegendLabel} m-0 orbitron`}
                                  >
                                    Total Tokens
                                  </p>
                                </div> */}

                                  <div
                                    className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}
                                  >
                                    {/* <button
                                  className={`${Classes.singleSecLegendBtn} border-0 outline-0 orbitron px-3 px-md-4 px-lg-5 py-2`}
                                >
                                  30%
                                </button> */}
                                    <div className={`${Classes.color_rec}`} style={{ backgroundColor: "#1e1e1e" }}></div>
                                    <p
                                      className={`${Classes.singleLegendLabel} m-0 orbitron`}
                                    >
                                      Sold Tokens
                                    </p>
                                  </div>

                                  <div
                                    className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}
                                  >
                                    {/* <button
                                  className={`${Classes.singleWhiteLegendBtn} border-0 outline-0 orbitron px-3 px-md-4 px-lg-5 py-2`}
                                >
                                  10%
                                </button> */}
                                    <div className={`${Classes.color_rec}`} style={{ backgroundColor: "#1A23FF" }}></div>
                                    <p
                                      className={`${Classes.singleLegendLabel} m-0 orbitron`}
                                    >
                                      Unsold Tokens
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  }

                  <div
                    className={`${Classes.poolInfoHolder} px-2 px-sm-3 px-md-5 py-5`}
                  >
                    <div className={`${Classes.launchpad__nameHolder}`}>
                      <h1
                        className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}
                      >
                        pool info
                      </h1>
                    </div>
                    <div className={`${Classes.poolFlexHolder} mt-5`}>
                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Address
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {saleAdd}
                        </p>
                      </div>

                      {/* <div
                      className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                    >
                      <p className={`${Classes.poolLabel} roboto mb-2`}>
                        Tokens For Presale
                      </p>
                      <p className={`${Classes.poolLabel} roboto`}>
                        4,715,392,388 MEKO
                      </p>
                    </div>

                    <div
                      className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                    >
                      <p className={`${Classes.poolLabel} roboto mb-2`}>
                        Tokens For Liquidity
                      </p>
                      <p className={`${Classes.poolLabel} roboto`}>
                        2,284,607,611.986 MEKO
                      </p>
                    </div> */}

                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          SoftCap
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>{saleInfo?.softCap / LAMPORTS_PER_SOL}  {saleInfo?.buyMint?.toString() == Config?.SOL ? "SOL" :
                          saleInfo?.buyMint?.toString() == Config?.USDT ? "USDT" :
                            saleInfo?.buyMint?.toString() == Config?.USDC ? "USDC" : "DAI"}</p>
                      </div>

                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Start Time
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {saleInfo && new Date(parseInt(saleInfo?.presaleStart)).toLocaleDateString() + "  "
                            + new Date(parseInt(saleInfo?.presaleStart)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleStart)).getMinutes() + ":"
                            + new Date(parseInt(saleInfo?.presaleStart)).getSeconds()}
                        </p>
                      </div>

                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          End Time
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {saleInfo && new Date(parseInt(saleInfo?.presaleEnd)).toLocaleDateString() + "  "
                            + new Date(parseInt(saleInfo?.presaleEnd)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleEnd)).getMinutes() + ":"
                            + new Date(parseInt(saleInfo?.presaleEnd)).getSeconds()}
                        </p>
                      </div>

                      {/* <div
                      className={`${Classes.statusSingleFlex} mb-4 d-flex  flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                    >
                      <p className={`${Classes.poolLabel} roboto mb-2`}>
                        Listing On
                      </p>
                      <p className={`${Classes.poolLabel} roboto`}>
                        Raydium AMM V4
                      </p>
                    </div> */}

                      <div
                        className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Liquidity Percent
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>{parseInt(saleInfo?.liquidityPercent)}%</p>
                      </div>

                      <div
                        className={`${Classes.statusSingleFlex} d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Liquidity Lockup Time
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {duration(parseInt(saleInfo?.lockingDays))} days
                          {/* after pool ends */}
                        </p>
                      </div>
                      <div
                        className={`${Classes.statusSingleFlex} mb-4  d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Vesting days
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {duration(parseInt(saleInfo?.vestingDays))} days
                        </p>
                      </div>
                      <div
                        className={`${Classes.statusSingleFlex} mb-4  d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}
                      >
                        <p className={`${Classes.poolLabel} roboto mb-2`}>
                          Vesting percentage
                        </p>
                        <p className={`${Classes.poolLabel} roboto`}>
                          {parseInt(saleInfo?.vestingPeriod)} %
                          {/* after pool ends */}
                        </p>
                      </div>
                    </div>
                  </div>
                  {console.log("checkowner", saleInfo?.authority?.toString() == walletdetail?.walletaddress)}

                  {saleInfo?.authority?.toString() == walletdetail?.walletaddress &&

                    <div className={`${Classes.ownerZoneHolder} px-2 px-sm-3 px-md-5 py-5`}>
                      <div className={`${Classes.launchpad__nameHolder}`}>
                        <h1
                          className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>Owner zone</h1>
                      </div>

                      <div className={`${Classes.radioHolder} mt-3`}>
                        {console.log("saleType>>>>>>>>>>>>>>>", saleInfo?.isWhitelist, saleType)
                        }
                        <label for="tokenAddress" className={`${Classes.inputLabel} roboto mb-3`}>Sale Type</label>
                        <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`} onClick={() => { setSaleType('public'); editwhitelistStatus(false) }}>
                          <i className={`fa-solid fa-square ${saleType == 'public' ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                          {/* <p className={`${Classes.radioHint}`}>{saleInfo?.isWhitelist == false ? 'Public' : 'Private'}</p> */}
                          <p className={`${Classes.radioHint}`}>Public</p>

                        </button>

                        <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`} onClick={() => { setSaleType('whiteList'); editwhitelistStatus(true) }}>
                          <i className={`fa-solid fa-square ${saleType == 'whiteList' ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                          <p className={`${Classes.radioHint}`}>Whitelist</p>
                        </button>
                      </div>
                      {console.log("saleType", saleType)}

                      <div className={`${Classes.saleTypeBtnsHolder} mt-4`}>
                        <Row className={`justify-content-center`}>
                          {!saleInfo?.isWhitelist && saleType == 'public' &&
                            <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => { setSaleType('whiteList'); editwhitelistStatus(true) }}>
                                <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Enable Whitelist</p></button>
                            </Col>
                          }
                          {saleInfo?.isWhitelist && saleType == 'whiteList' &&
                            <>
                              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                                <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => handleShowAddWhiteList()}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Add User to Whitelist</p></button>
                              </Col>
                              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                                <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => handleShowRemoveWhiteList()}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Remove User from Whitelist</p></button>
                              </Col>
                              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                                {/* <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => setSaleType('public')}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Disable Whitelist</p></button> */}
                                <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => { setSaleType('public'); editwhitelistStatus(false) }}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Disable Whitelist</p></button>

                              </Col>
                            </>
                          }
                        </Row>

                        <Row className={`justify-content-center`}>
                          <label for="tokenAddress" className={`${Classes.inputLabel} roboto mb-4 mt-5`}>Wallet Options</label>
                          {/* <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                        <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Stop Sale</p></button>
                      </Col> */}
                          <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                            <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}
                              onClick={() => {
                                finalizeSale()
                              }}
                              disabled={saleInfo?.ready == 2 || saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd))}>
                              <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                                {isfinalize ? 'Finalizing...' : 'Finalize'}
                              </p></button>
                          </Col>
                          {/* <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                        <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Get Unsold 1 SOL</p></button>
                      </Col>
                      <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
                        <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Withdraw 1 SOL</p></button>
                      </Col> */}
                        </Row>


                        {saleInfo &&
                          (saleInfo?.ready !== 2 && saleInfo?.isDeposit == false && !saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd))) ?

                          // isEmpty(saleInfo?.splStock)  && saleHook.isSaleLive(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ||  isEmpty(saleInfo?.splStock)  && saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ?
                          <Row className={`justify-content-center mt-3`}>
                            <label
                              for="tokenAddress"
                              className={`${Classes.inputLabel} roboto text-center mb-4 mt-5`}
                            >
                              Deposit Tokens
                            </label>
                            <Col lg={3} md={4} sm={6} xxl={2} className={`mb-3 mb-md-0`}>
                              <button
                                className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}
                                onClick={() => {
                                  calculateDepositTokens()
                                }}
                              >
                                <p
                                  className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
                                >
                                  Calculate
                                </p>
                              </button>
                            </Col>
                            {!isEmpty(deposit) ?
                              <Col lg={3} md={4} sm={6} xxl={2} className={`mb-3 mb-md-0`}>
                                <button
                                  className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}
                                  onClick={() => {
                                    depositToken()
                                  }}
                                >
                                  <p
                                    className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
                                  >
                                    {isdeposit ? 'Depositing...' : 'Deposit'}
                                  </p>
                                </button>
                              </Col> : ""}
                            {!isEmpty(deposit) ?
                              <Col lg={12} className={`mb-3 mb-md-0 mt-3`}>
                                <p className={`text-white text-center`}>You Have to Deposit {deposit} token</p>
                              </Col>
                              : ""}
                          </Row>
                          : ""}






                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
        }
      </Layout>


      {/* modals */}
      <AddUserWhitelist show={addUserWhiteList} handleClose={handleCloseAddWhiteList} presaleAddress={saleAdd} handleShowCSV={handleShowCSV} />
      <SampleCSV show={showCSV} handleClose={handleCloseCSV} handleShowAddWhiteList={handleShowAddWhiteList} />
      <RemoveWhtieListUser show={removeUserWhiteList} handleClose={handleCloseRemoveWhiteList} />
      <ConnectWalletModal show={show} handleClose={handleClose} wallet={wallet} presaleAddress={saleAdd} setWallet={setWallet} />

      {/* end of modals */}
    </>
  )
}

export default PremiumLaunchDetail
